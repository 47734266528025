export const i18n = {
  activeSessions: "activeSessions",
  loadingActiveSessions: "loadingActiveSessions",
  loadingSessions: "loadingSessions",
  noSessions: "noSessions",
  userName: "userName",
  ongoingSession: "ongoingSession",
  hours: "hours",
  unknownDate: "unknownDate",
  displayName: "displayName",
  chargerFilters: "chargerFilters",
  noChargersFound: "noChargersFound",
  filterDateStartAsc: "filterDateStartAsc",
  filterDateStartDesc: "filterDateStartDesc",
  filterAlphabetAsc: "filterAlphabetAsc",
  filterAlphabetDesc: "filterAlphabetDesc",
  loadingFiltering: "loadingFiltering",
  mySessions: "mySessions",
  allSessions: "allSessions",
  resetFilters: "resetFilters",
  lastKnownState: "lastKnownState",
  available: "available",
  charging: "charging",
  blocked: "blocked",
  inoperative: "inoperative",
  outOfOrder: "outOfOrder",
  planned: "planned",
  removed: "removed",
  reserved: "reserved",
  unknown: "unknown",
  unknownOffline: "unknownOffline",
  unrecognized: "unrecognized",
  evSource: "evSource",
  time: "time",
  startSessionTime: "startSessionTime",
  endSessionTime: "endSessionTime",
  sessionCreationTime: "sessionCreationTime",
  sessionStatus: "sessionStatus",
  error: "error",
  parking: "parking",
  pending: "pending",
  requested: "requested",
  started: "started",
  starting: "starting",
  stopping: "stopping",
  finished: "finished",
  paid: "paid",
  cdrAvailable: "cdrAvailable",
  fromDateTime: "fromDateTime",
  toDateTime: "toDateTime",
  newerThan: "newerThan",
  olderThan: "olderThan",
  withinRange: "withinRange",
  defa: "defa",
  greenflux: "greenflux",
  schneider: "schneider",
  easee: "easee",
  zaptec: "zaptec",
  noResults: "noResults",
  noChargerFound: "noChargerFound",
  name: "name",
  status: "status",
  information: "information",
  copy: "copy",
  space: "space",
  lastStatusUpdate: "lastStatusUpdate",
  cpo: "cpo",
  connectorId: "connectorId",
  cpoId: "cpoId",
  id: "id",
  lastSyncronized: "lastSyncronized",
  lastPowerDelivery: "lastPowerDelivery",
  neverSynchronized: "neverSynchronized",
  notAvailable: "notAvailable",
  sendAnEmail: "sendAnEmail",
  loadingChargers: "loadingChargers",
  searchChargerPlaceholder: "searchChargerPlaceholder",
  sortBy: "sortBy",
  dateDescending: "dateDescending",
  dateAscending: "dateAscending",
  alphAscending: "alphAscending",
  alphDescending: "alphDescending",
  capacity: "capacity",
}; // TODO: maybe organize / split these to be more searchable

export type Translations = typeof i18n;
