import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import { filteredChargersSelector } from "../../atoms/chargerAtom";
import { H2 } from "../generic/typography";
import { ChargerTile } from "./chargerTile";
import { useIntl } from "react-intl";
import { i18n } from "../../i18n/langType";

export function ChargerTileGrid() {
  const intl = useIntl();
  const filteredChargers = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filteredChargersSelector);
  const displayChargers = filteredChargers?.map((tile) => (
    <ChargerTile charger={tile} key={`chargerTile_${tile.ExternalId}_${tile.Id}`} />
  ));
  if (displayChargers?.length === 0) {
    return <H2 className="p-4">{intl.formatMessage({ id: i18n.noResults })}</H2>;
  }
  return (
    <div
      key="ChargerTileGrid"
      className="flex flex-col gap-4 p-6 overflow-auto h-full w-full"
    >
      {displayChargers}
    </div>
  );
}
