import { Suspense } from "react";
import { Outlet } from "react-router-dom";

interface ChargersPageProps {}
export function ChargersPage(props: ChargersPageProps) {
  return (
    <div className="w-full h-full bg-diselDust-50 flex flex-col">
      <Suspense fallback={<div>Loading ...</div>}>
        <Outlet />
      </Suspense>
    </div>
  );
}
