import { useState } from "react";
import { RecoilState, useRecoilState } from "recoil";
import { FilterDictionary, SortingSettings } from "../../types/filterTypes";
import { FilterPills } from "./filterComponents/filterPills";
import { IconType, GenericIcon } from "./genericIcon";
import { SearchIcon } from "./icons";
import { SortMenu } from "./sortMenu";

interface FilterHeaderSectionProps {
  placeholder: string;
  toggleSidePanel: Function;
  sidePanelOpen: boolean;
  filterAtom: RecoilState<FilterDictionary>;
  sortSettingAtom: RecoilState<SortingSettings>;
  searchQueryAtom: RecoilState<string>;
}

export function SearchHeader(props: FilterHeaderSectionProps) {
  const [sortOpen, setSortOpen] = useState(false);

  function sortMenuToggleOpen() {
    setSortOpen((sortOpen) => !sortOpen);
  }

  function clickAwayCloseMenuFunction() {
    setSortOpen(false); //Needed because click listener will always fire, even if object is already closed
  }

  //TODO, switch between chargerFilterPills and SessionFilterPills
  return (
    <div className="flex flex-col">
      <div className="gap-4 flex flex-row items-center py-10 px-10">
        <div className="relative flex flex-row gap-4">
          <SortMenu
            open={sortOpen}
            sortSettingsAtom={props.sortSettingAtom}
            clickAwayCloseMenuFunction={clickAwayCloseMenuFunction}
          />
          <FilterButton
            filterIcon={IconType.Sort}
            sortMenuToggle={sortMenuToggleOpen}
            isOpen={sortOpen}
          />
          <FilterButton
            filterIcon={IconType.Filter}
            toggleSidePanel={props.toggleSidePanel}
            isOpen={props.sidePanelOpen}
          />
        </div>
        <PageSearchBar
          searchQueryAtom={props.searchQueryAtom}
          placeholder={props.placeholder}
        />
      </div>
      <FilterPills filterAtom={props.filterAtom} className="px-10 pb-8" />
    </div>
  );
}

interface FilterButtonProps {
  filterIcon: IconType;
  sortMenuToggle?: Function;
  toggleSidePanel?: Function;
  isOpen?: boolean;
}

export function FilterButton(props: FilterButtonProps) {
  const open = props.isOpen;

  function toggleOpen() {
    if (props.toggleSidePanel) {
      props.toggleSidePanel();
    }
    if (props.sortMenuToggle) {
      props.sortMenuToggle();
    }
  }
  let iconClassName = "h-6 w-6";
  let divFullClassName =
    "group h-20 w-20 flex justify-center items-center rounded-full border hover:border-diselDust-300 active:border-diselDust-600 ";
  if (!open) {
    divFullClassName =
      divFullClassName +
      " border-diselDust-150 bg-diselDust-100 focused:border-diselDust-900 ";
  } else {
    divFullClassName =
      divFullClassName + " border-black bg-black focused:border-diselDust-800 ";
    iconClassName = iconClassName + " invert group-active:fill-diselDust-600";
  }
  return (
    <div className={divFullClassName} onClick={toggleOpen}>
      <GenericIcon className={iconClassName} iconName={props.filterIcon} />
    </div>
  );
}

interface PageSearchBarProps {
  searchQueryAtom: RecoilState<string>;
  placeholder: string;
}
export function PageSearchBar({
  searchQueryAtom,
  placeholder,
}: PageSearchBarProps) {
  const [searchQueryValue, setSearchQueryValue] =
    useRecoilState(searchQueryAtom);
  const [currentSearchQuery, setCurrentSearchQuery] =
    useState(searchQueryValue);

  function onChangeSearchQuery(event: any) {
    setCurrentSearchQuery(event.target.value);
  }

  function handleKeyOnInput(event: any) {
    if (event.key == "Enter") {
      setSearchQuery();
    }
  }

  function setSearchQuery() {
    setSearchQueryValue(currentSearchQuery);
  }

  return (
    <div className="h-20 w-full rounded-full bg-white flex flex-row items-center justify-start hover:border active:border hover:border-diselDust-150 active:border-diselDust-500 group focus-within:border focus-within:border-diselDust-150">
      <div onClick={setSearchQuery}>
        <SearchIcon className="mx-6 h-11 w-11" />
      </div>
      <input
        placeholder={placeholder}
        className="text-H4 w-10/12 font-helveticaLight font-light placeholder:font-helveticaLight placeholder:font-light placeholder:opacity-50 outline-none hover:placeholder:opacity-100 focused:placeholder:opacity-50"
        value={currentSearchQuery}
        onChange={onChangeSearchQuery}
        onKeyUp={handleKeyOnInput}
      />
    </div>
  );
}
