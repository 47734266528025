import { Link, useLocation, useParams } from "react-router-dom";
import { Body1, Body2, H4 } from "../generic/typography";
import { ArrowIcon } from "../generic/icons";

export function NavSidebar() {
  return (
    <div className="flex flex-col bg-slate-500 h-full min-w-60 w-60">
      <SideBarItem displayName="Chargers" linkTo="chargers" />
      <SideBarItem displayName="Sessions" linkTo="sessions" />
    </div>
  );
}

interface SideBarItemProps {
  displayName: string;
  linkTo: string;
  subItems?: SideBarItemProps[];
  parentPath?: string;
  disabled?: boolean;
}

function SideBarItem({
  displayName,
  linkTo,
  subItems,
  disabled,
}: SideBarItemProps) {
  //TODO: Refactor the rendering of conditions? I feel it is poorly organized
  const currPath = useLocation();
  const active = currPath.pathname.includes(linkTo || "");
  const subItemArray = subItems || [];
  const params = useParams();
  let profileId; //TODO: Profile id will be home for sessionId, or ticketId, or other in the future, but right now just chargerId if it exists
  if (params.chargerId) {
    profileId = params.chargerId;
  }
  return (
    <SideBarChargerLayout
      linkTo={linkTo}
      displayName={displayName}
      profileId={profileId}
      active={active}
      additionalItems={subItemArray}
      disabled={disabled}
    />
  );
}

interface SideBarSubItemListProps {
  items: SideBarItemProps[];
  parentPath?: string;
}

function SideBarSubItemList({ items, parentPath }: SideBarSubItemListProps) {
  const list = items.map((item) => (
    <SideBarSubItem
      displayName={item.displayName}
      linkTo={item.linkTo}
      parentPath={parentPath}
    />
  ));
  return <div className="flex flex-col text-gray-600">{list}</div>;
}

function SideBarSubItem({ displayName, linkTo, parentPath }: SideBarItemProps) {
  if (linkTo) {
    return <Link to={`${parentPath}/${linkTo}`}>{displayName}</Link>;
  }
  return <Body2>{displayName}</Body2>;
}

interface SideBarChargerLayoutProps {
  linkTo: string;
  displayName: string;
  profileId?: string;
  active: boolean;
  additionalItems: SideBarItemProps[];
  disabled?: boolean;
}
function SideBarChargerLayout({
  linkTo,
  displayName,
  profileId,
  active,
  additionalItems,
  disabled,
}: SideBarChargerLayoutProps) {
  if (!active) {
    if (disabled) {
      //temporary for demo purposes
      return (
        <div
          className="text-subH4 p-6 min-h-1/6 bg-white text-diselDust-400 cursor-default"
          // to={`/${linkTo}`}
        >
          <Body1>{displayName}</Body1>
        </div>
      );
    }
    return (
      <Link
        className="text-subH4 p-6 min-h-1/6 bg-white text-black"
        to={`/${linkTo}`}
      >
        <Body1>{displayName}</Body1>
      </Link>
    );
  }

  if (profileId) {
    return (
      <div className="h-1/2 text-white flex flex-col bg-black group">
        <Link
          className="bg-diselDust-700 group-hover:bg-diselDust-800 group-active:bg-diselDust-850 h-full"
          to={`/${linkTo}`}
        >
          <div className="p-6 h-1/4 grow">
            <H4>{displayName}</H4>
          </div>
          <div className="flex flex-col h-3/4 justify-between bg-black group-hover:bg-diselDust-850 group-active:bg-diselDust-850">
            <div className="flex flex-col p-7 gap-4">
              <Body1 className="truncate">{`${profileId}`}</Body1>
              <Body2 className="text-diselDust-600">{`Profile`}</Body2>
              {/* <SideBarSubItemList items={additionalItems} parentPath={linkTo} /> */}
            </div>
            <div className="flex justify-end p-6">
              <ArrowIcon className={"invert h-8 w-8"} />
            </div>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-1/2 bg-black text-white flex flex-col justify-between p-6">
      <H4>{displayName}</H4>
      <div>
        <Link className="flex justify-end" to={`/${linkTo}`}>
          <ArrowIcon className={"invert h-8 w-8"} />
        </Link>
      </div>
    </div>
  );
}
