import { RecoilState, useRecoilState, useResetRecoilState } from "recoil";
import {
  FilterCategory,
  FilterOption,
  FilterDictionary,
} from "../../../types/filterTypes";
import { CancelIcon } from "../icons";
import { Body2 } from "../typography";
import { useIntl } from "react-intl";
import { i18n } from "../../../i18n/langType";

interface FilterPillsProps {
  className?: string;
  filterAtom: RecoilState<FilterDictionary>;
}

export function FilterPills({ filterAtom, className }: FilterPillsProps) {
  const [filters, setFilters] = useRecoilState(filterAtom);
  const resetFilters = useResetRecoilState(filterAtom);
  const intl = useIntl();

  const selectedFilterOptions = getAllSelectedFilterOptions(filters);

  function deselectFilter(filterOption: FilterOption) {
    let path = filterOption.path;
    let newCategory: FilterCategory;
    const [categoryPath, filterPath, subFilterPath] = path;
    const oldCategory = filters[categoryPath];
    const oldFilter = oldCategory?.options[filterPath];

    newCategory = {
      ...filters[categoryPath],
      options: {
        ...oldCategory.options,
      },
    };

    if (subFilterPath) {
      const oldSubFilter = oldFilter?.subFilters?.[subFilterPath];

      if (!oldSubFilter) {
        return;
      }
      newCategory = {
        ...filters[categoryPath],
        options: {
          ...oldCategory.options,
          [filterPath]: {
            ...oldFilter,
            subFilters: {
              ...oldFilter.subFilters,
              [subFilterPath]: { ...oldSubFilter, selected: false },
            },
          },
        },
      };
    } else if (categoryPath && filterPath) {
      newCategory = {
        ...filters[categoryPath],
        options: {
          ...oldCategory.options,
          [filterPath]: {
            ...oldFilter,
            selected: false,
          },
        },
      };
    } else {
      console.error("Error removing filter");
      return;
    }
    setFilters({ ...filters, [categoryPath]: newCategory });
  }
  if (selectedFilterOptions.length === 0) {
    return null;
  }
  return (
    <div className={"flex flex-row justify-between " + className}>
      <div className="grid grid-flow-col gap-4">
        {selectedFilterOptions.map((option) => (
          <FilterPill
            filterOption={option}
            removeFilter={deselectFilter}
            key={`filterPill_${option.path[0]}_${option.path[1]}_${option.path[2]}`}
          />
        ))}
      </div>
      <div className="flex flex-col justify-center items-center">
        <div
          className="hover:bg-diselDust-100 active:bg-diselDust-150 focus:bg-diselDust-100 px-2 pt-1 pb-1.5"
          onClick={resetFilters}
        >
          <Body2 className="underline">{intl.formatMessage({id:i18n.resetFilters})}</Body2>
        </div>
      </div>
    </div>
  );
}

interface FilterPillProps {
  filterOption: FilterOption;
  removeFilter: (path: FilterOption) => void;
}
function FilterPill(props: FilterPillProps) {
  const intl = useIntl();
  const displayText = intl.formatMessage({id:props.filterOption.displayName});
  function deselectThisFilter() {
    props.removeFilter(props.filterOption);
  }

  return (
    <div className="bg-diselDust-150 flex flex-row justify-between rounded-full pl-4 py-2 pr-2 gap-2 group hover:border-diselDust-300 active:border-diselDust-600">
      <div className="text-diselDust-600 text-normal">{displayText}</div>
      <div onClick={deselectThisFilter}>
        <CancelIcon className="h-6 w-6 hover:fill-diselDust-700 focus:fill-diselDust-700" />
      </div>
    </div>
  );
}

function getAllSelectedFilterOptions(
  filters: FilterDictionary
): FilterOption[] {
  const categories = Object.values(filters);
  const selectedOptions = categories
    .flatMap((category) => Object.values(category.options))
    .filter((option) => option.selected);

  const firstLevelOptions = selectedOptions
    .filter((option) => !option.subFilters)
    .map((option) => option);

  const subFilterOptions = selectedOptions
    .filter((option) => option.subFilters)
    .flatMap((option) => Object.values(option.subFilters || {}))
    .filter((subOption) => subOption.selected)
    .map((subOption) => subOption);

  return [...firstLevelOptions, ...subFilterOptions];
}
