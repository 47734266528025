import "../App.css";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface LoginPageProps {
  loginSetter: Dispatch<SetStateAction<boolean>>;
}

export default function LoginPage({ loginSetter }: LoginPageProps) {
  const navigate = useNavigate();
  async function login() {
    loginSetter(true);
    navigate("/");
  }

  return (
    <div
      className="flex flex-col gap-2 h-full w-full border p-8 bg-gray-100 items-center justify-center"
      id="main-div"
    >
      <div className="justify-center items-center">
        <button
          className="text-lg border-black border rounded-lg px-4 bg-black text-white"
          id="loginButton"
          onClick={login}
        >
          Go to Login Page
        </button>
      </div>
    </div>
  );
}
