/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";
import { protoMetadata as protoMetadata1, Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "EvMng.Services.ManagementApi.Abstractions.Chargers";

export enum ChargerCommand {
  UNKNOWN = 0,
  RESTART = 1,
  RESET = 2,
  START = 3,
  STOP = 4,
  UNLOCK = 5,
  UNRECOGNIZED = -1,
}

export function chargerCommandFromJSON(object: any): ChargerCommand {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return ChargerCommand.UNKNOWN;
    case 1:
    case "RESTART":
      return ChargerCommand.RESTART;
    case 2:
    case "RESET":
      return ChargerCommand.RESET;
    case 3:
    case "START":
      return ChargerCommand.START;
    case 4:
    case "STOP":
      return ChargerCommand.STOP;
    case 5:
    case "UNLOCK":
      return ChargerCommand.UNLOCK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChargerCommand.UNRECOGNIZED;
  }
}

export function chargerCommandToJSON(object: ChargerCommand): string {
  switch (object) {
    case ChargerCommand.UNKNOWN:
      return "UNKNOWN";
    case ChargerCommand.RESTART:
      return "RESTART";
    case ChargerCommand.RESET:
      return "RESET";
    case ChargerCommand.START:
      return "START";
    case ChargerCommand.STOP:
      return "STOP";
    case ChargerCommand.UNLOCK:
      return "UNLOCK";
    case ChargerCommand.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ConnectorType {
  CHADEMO = 0,
  DOMESTIC_A = 1,
  DOMESTIC_B = 2,
  DOMESTIC_C = 3,
  DOMESTIC_D = 4,
  DOMESTIC_E = 5,
  DOMESTIC_F = 6,
  DOMESTIC_G = 7,
  DOMESTIC_H = 8,
  DOMESTIC_I = 9,
  DOMESTIC_J = 10,
  DOMESTIC_K = 11,
  DOMESTIC_L = 12,
  IEC_60309_2_single_16 = 13,
  IEC_60309_2_three_16 = 14,
  IEC_60309_2_three_32 = 15,
  IEC_60309_2_three_64 = 16,
  IEC_62196_T1 = 17,
  IEC_62196_T1_COMBO = 18,
  IEC_62196_T2 = 19,
  IEC_62196_T2_COMBO = 20,
  IEC_62196_T3A = 21,
  IEC_62196_T3C = 22,
  TESLA_R = 23,
  TESLA_S = 24,
  PANTOGRAPH_BOTTOM_UP = 25,
  PANTOGRAPH_TOP_DOWN = 26,
  ConnectorType_UNKNOWN = 27,
  UNRECOGNIZED = -1,
}

export function connectorTypeFromJSON(object: any): ConnectorType {
  switch (object) {
    case 0:
    case "CHADEMO":
      return ConnectorType.CHADEMO;
    case 1:
    case "DOMESTIC_A":
      return ConnectorType.DOMESTIC_A;
    case 2:
    case "DOMESTIC_B":
      return ConnectorType.DOMESTIC_B;
    case 3:
    case "DOMESTIC_C":
      return ConnectorType.DOMESTIC_C;
    case 4:
    case "DOMESTIC_D":
      return ConnectorType.DOMESTIC_D;
    case 5:
    case "DOMESTIC_E":
      return ConnectorType.DOMESTIC_E;
    case 6:
    case "DOMESTIC_F":
      return ConnectorType.DOMESTIC_F;
    case 7:
    case "DOMESTIC_G":
      return ConnectorType.DOMESTIC_G;
    case 8:
    case "DOMESTIC_H":
      return ConnectorType.DOMESTIC_H;
    case 9:
    case "DOMESTIC_I":
      return ConnectorType.DOMESTIC_I;
    case 10:
    case "DOMESTIC_J":
      return ConnectorType.DOMESTIC_J;
    case 11:
    case "DOMESTIC_K":
      return ConnectorType.DOMESTIC_K;
    case 12:
    case "DOMESTIC_L":
      return ConnectorType.DOMESTIC_L;
    case 13:
    case "IEC_60309_2_single_16":
      return ConnectorType.IEC_60309_2_single_16;
    case 14:
    case "IEC_60309_2_three_16":
      return ConnectorType.IEC_60309_2_three_16;
    case 15:
    case "IEC_60309_2_three_32":
      return ConnectorType.IEC_60309_2_three_32;
    case 16:
    case "IEC_60309_2_three_64":
      return ConnectorType.IEC_60309_2_three_64;
    case 17:
    case "IEC_62196_T1":
      return ConnectorType.IEC_62196_T1;
    case 18:
    case "IEC_62196_T1_COMBO":
      return ConnectorType.IEC_62196_T1_COMBO;
    case 19:
    case "IEC_62196_T2":
      return ConnectorType.IEC_62196_T2;
    case 20:
    case "IEC_62196_T2_COMBO":
      return ConnectorType.IEC_62196_T2_COMBO;
    case 21:
    case "IEC_62196_T3A":
      return ConnectorType.IEC_62196_T3A;
    case 22:
    case "IEC_62196_T3C":
      return ConnectorType.IEC_62196_T3C;
    case 23:
    case "TESLA_R":
      return ConnectorType.TESLA_R;
    case 24:
    case "TESLA_S":
      return ConnectorType.TESLA_S;
    case 25:
    case "PANTOGRAPH_BOTTOM_UP":
      return ConnectorType.PANTOGRAPH_BOTTOM_UP;
    case 26:
    case "PANTOGRAPH_TOP_DOWN":
      return ConnectorType.PANTOGRAPH_TOP_DOWN;
    case 27:
    case "ConnectorType_UNKNOWN":
      return ConnectorType.ConnectorType_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConnectorType.UNRECOGNIZED;
  }
}

export function connectorTypeToJSON(object: ConnectorType): string {
  switch (object) {
    case ConnectorType.CHADEMO:
      return "CHADEMO";
    case ConnectorType.DOMESTIC_A:
      return "DOMESTIC_A";
    case ConnectorType.DOMESTIC_B:
      return "DOMESTIC_B";
    case ConnectorType.DOMESTIC_C:
      return "DOMESTIC_C";
    case ConnectorType.DOMESTIC_D:
      return "DOMESTIC_D";
    case ConnectorType.DOMESTIC_E:
      return "DOMESTIC_E";
    case ConnectorType.DOMESTIC_F:
      return "DOMESTIC_F";
    case ConnectorType.DOMESTIC_G:
      return "DOMESTIC_G";
    case ConnectorType.DOMESTIC_H:
      return "DOMESTIC_H";
    case ConnectorType.DOMESTIC_I:
      return "DOMESTIC_I";
    case ConnectorType.DOMESTIC_J:
      return "DOMESTIC_J";
    case ConnectorType.DOMESTIC_K:
      return "DOMESTIC_K";
    case ConnectorType.DOMESTIC_L:
      return "DOMESTIC_L";
    case ConnectorType.IEC_60309_2_single_16:
      return "IEC_60309_2_single_16";
    case ConnectorType.IEC_60309_2_three_16:
      return "IEC_60309_2_three_16";
    case ConnectorType.IEC_60309_2_three_32:
      return "IEC_60309_2_three_32";
    case ConnectorType.IEC_60309_2_three_64:
      return "IEC_60309_2_three_64";
    case ConnectorType.IEC_62196_T1:
      return "IEC_62196_T1";
    case ConnectorType.IEC_62196_T1_COMBO:
      return "IEC_62196_T1_COMBO";
    case ConnectorType.IEC_62196_T2:
      return "IEC_62196_T2";
    case ConnectorType.IEC_62196_T2_COMBO:
      return "IEC_62196_T2_COMBO";
    case ConnectorType.IEC_62196_T3A:
      return "IEC_62196_T3A";
    case ConnectorType.IEC_62196_T3C:
      return "IEC_62196_T3C";
    case ConnectorType.TESLA_R:
      return "TESLA_R";
    case ConnectorType.TESLA_S:
      return "TESLA_S";
    case ConnectorType.PANTOGRAPH_BOTTOM_UP:
      return "PANTOGRAPH_BOTTOM_UP";
    case ConnectorType.PANTOGRAPH_TOP_DOWN:
      return "PANTOGRAPH_TOP_DOWN";
    case ConnectorType.ConnectorType_UNKNOWN:
      return "ConnectorType_UNKNOWN";
    case ConnectorType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EvSource {
  DEFA = 0,
  ZAPTEC = 1,
  EASEE = 2,
  SCHNEIDER = 3,
  GREENFLUX = 4,
  INTERNAL = 5,
  TEST = 6,
  UNRECOGNIZED = -1,
}

export function evSourceFromJSON(object: any): EvSource {
  switch (object) {
    case 0:
    case "DEFA":
      return EvSource.DEFA;
    case 1:
    case "ZAPTEC":
      return EvSource.ZAPTEC;
    case 2:
    case "EASEE":
      return EvSource.EASEE;
    case 3:
    case "SCHNEIDER":
      return EvSource.SCHNEIDER;
    case 4:
    case "GREENFLUX":
      return EvSource.GREENFLUX;
    case 5:
    case "INTERNAL":
      return EvSource.INTERNAL;
    case 6:
    case "TEST":
      return EvSource.TEST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EvSource.UNRECOGNIZED;
  }
}

export function evSourceToJSON(object: EvSource): string {
  switch (object) {
    case EvSource.DEFA:
      return "DEFA";
    case EvSource.ZAPTEC:
      return "ZAPTEC";
    case EvSource.EASEE:
      return "EASEE";
    case EvSource.SCHNEIDER:
      return "SCHNEIDER";
    case EvSource.GREENFLUX:
      return "GREENFLUX";
    case EvSource.INTERNAL:
      return "INTERNAL";
    case EvSource.TEST:
      return "TEST";
    case EvSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PowerType {
  AC_1_PHASE = 0,
  AC_3_PHASE = 1,
  DC = 2,
  UNRECOGNIZED = -1,
}

export function powerTypeFromJSON(object: any): PowerType {
  switch (object) {
    case 0:
    case "AC_1_PHASE":
      return PowerType.AC_1_PHASE;
    case 1:
    case "AC_3_PHASE":
      return PowerType.AC_3_PHASE;
    case 2:
    case "DC":
      return PowerType.DC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PowerType.UNRECOGNIZED;
  }
}

export function powerTypeToJSON(object: PowerType): string {
  switch (object) {
    case PowerType.AC_1_PHASE:
      return "AC_1_PHASE";
    case PowerType.AC_3_PHASE:
      return "AC_3_PHASE";
    case PowerType.DC:
      return "DC";
    case PowerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchLiteralOperator {
  UnknownSearchLiteralOperator = 0,
  Equal = 1,
  NotEqual = 2,
  LessThanOrEqual = 3,
  LessThan = 4,
  GreaterThanOrEqual = 5,
  GreaterThan = 6,
  Contains = 7,
  StringContains = 8,
  StartsWith = 9,
  EndsWith = 10,
  UNRECOGNIZED = -1,
}

export function searchLiteralOperatorFromJSON(object: any): SearchLiteralOperator {
  switch (object) {
    case 0:
    case "UnknownSearchLiteralOperator":
      return SearchLiteralOperator.UnknownSearchLiteralOperator;
    case 1:
    case "Equal":
      return SearchLiteralOperator.Equal;
    case 2:
    case "NotEqual":
      return SearchLiteralOperator.NotEqual;
    case 3:
    case "LessThanOrEqual":
      return SearchLiteralOperator.LessThanOrEqual;
    case 4:
    case "LessThan":
      return SearchLiteralOperator.LessThan;
    case 5:
    case "GreaterThanOrEqual":
      return SearchLiteralOperator.GreaterThanOrEqual;
    case 6:
    case "GreaterThan":
      return SearchLiteralOperator.GreaterThan;
    case 7:
    case "Contains":
      return SearchLiteralOperator.Contains;
    case 8:
    case "StringContains":
      return SearchLiteralOperator.StringContains;
    case 9:
    case "StartsWith":
      return SearchLiteralOperator.StartsWith;
    case 10:
    case "EndsWith":
      return SearchLiteralOperator.EndsWith;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchLiteralOperator.UNRECOGNIZED;
  }
}

export function searchLiteralOperatorToJSON(object: SearchLiteralOperator): string {
  switch (object) {
    case SearchLiteralOperator.UnknownSearchLiteralOperator:
      return "UnknownSearchLiteralOperator";
    case SearchLiteralOperator.Equal:
      return "Equal";
    case SearchLiteralOperator.NotEqual:
      return "NotEqual";
    case SearchLiteralOperator.LessThanOrEqual:
      return "LessThanOrEqual";
    case SearchLiteralOperator.LessThan:
      return "LessThan";
    case SearchLiteralOperator.GreaterThanOrEqual:
      return "GreaterThanOrEqual";
    case SearchLiteralOperator.GreaterThan:
      return "GreaterThan";
    case SearchLiteralOperator.Contains:
      return "Contains";
    case SearchLiteralOperator.StringContains:
      return "StringContains";
    case SearchLiteralOperator.StartsWith:
      return "StartsWith";
    case SearchLiteralOperator.EndsWith:
      return "EndsWith";
    case SearchLiteralOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchNAryOperator {
  UnknownSearchNAryOperator = 0,
  And = 1,
  Or = 2,
  UNRECOGNIZED = -1,
}

export function searchNAryOperatorFromJSON(object: any): SearchNAryOperator {
  switch (object) {
    case 0:
    case "UnknownSearchNAryOperator":
      return SearchNAryOperator.UnknownSearchNAryOperator;
    case 1:
    case "And":
      return SearchNAryOperator.And;
    case 2:
    case "Or":
      return SearchNAryOperator.Or;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchNAryOperator.UNRECOGNIZED;
  }
}

export function searchNAryOperatorToJSON(object: SearchNAryOperator): string {
  switch (object) {
    case SearchNAryOperator.UnknownSearchNAryOperator:
      return "UnknownSearchNAryOperator";
    case SearchNAryOperator.And:
      return "And";
    case SearchNAryOperator.Or:
      return "Or";
    case SearchNAryOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchOrderDirection {
  UnknownSearchOrderDirection = 0,
  ASC = 1,
  DESC = 2,
  UNRECOGNIZED = -1,
}

export function searchOrderDirectionFromJSON(object: any): SearchOrderDirection {
  switch (object) {
    case 0:
    case "UnknownSearchOrderDirection":
      return SearchOrderDirection.UnknownSearchOrderDirection;
    case 1:
    case "ASC":
      return SearchOrderDirection.ASC;
    case 2:
    case "DESC":
      return SearchOrderDirection.DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchOrderDirection.UNRECOGNIZED;
  }
}

export function searchOrderDirectionToJSON(object: SearchOrderDirection): string {
  switch (object) {
    case SearchOrderDirection.UnknownSearchOrderDirection:
      return "UnknownSearchOrderDirection";
    case SearchOrderDirection.ASC:
      return "ASC";
    case SearchOrderDirection.DESC:
      return "DESC";
    case SearchOrderDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchType {
  UnknownSearchType = 0,
  Where = 1,
  Any = 2,
  All = 3,
  UNRECOGNIZED = -1,
}

export function searchTypeFromJSON(object: any): SearchType {
  switch (object) {
    case 0:
    case "UnknownSearchType":
      return SearchType.UnknownSearchType;
    case 1:
    case "Where":
      return SearchType.Where;
    case 2:
    case "Any":
      return SearchType.Any;
    case 3:
    case "All":
      return SearchType.All;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchType.UNRECOGNIZED;
  }
}

export function searchTypeToJSON(object: SearchType): string {
  switch (object) {
    case SearchType.UnknownSearchType:
      return "UnknownSearchType";
    case SearchType.Where:
      return "Where";
    case SearchType.Any:
      return "Any";
    case SearchType.All:
      return "All";
    case SearchType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchUnaryOperator {
  UnknownSearchUnaryOperator = 0,
  Not = 1,
  UNRECOGNIZED = -1,
}

export function searchUnaryOperatorFromJSON(object: any): SearchUnaryOperator {
  switch (object) {
    case 0:
    case "UnknownSearchUnaryOperator":
      return SearchUnaryOperator.UnknownSearchUnaryOperator;
    case 1:
    case "Not":
      return SearchUnaryOperator.Not;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchUnaryOperator.UNRECOGNIZED;
  }
}

export function searchUnaryOperatorToJSON(object: SearchUnaryOperator): string {
  switch (object) {
    case SearchUnaryOperator.UnknownSearchUnaryOperator:
      return "UnknownSearchUnaryOperator";
    case SearchUnaryOperator.Not:
      return "Not";
    case SearchUnaryOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Status {
  Status_UNKNOWN = 0,
  AVAILABLE = 1,
  BLOCKED = 2,
  CHARGING = 3,
  INOPERATIVE = 4,
  OUTOFORDER = 5,
  PLANNED = 6,
  REMOVED = 7,
  RESERVED = 8,
  UNRECOGNIZED = -1,
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "Status_UNKNOWN":
      return Status.Status_UNKNOWN;
    case 1:
    case "AVAILABLE":
      return Status.AVAILABLE;
    case 2:
    case "BLOCKED":
      return Status.BLOCKED;
    case 3:
    case "CHARGING":
      return Status.CHARGING;
    case 4:
    case "INOPERATIVE":
      return Status.INOPERATIVE;
    case 5:
    case "OUTOFORDER":
      return Status.OUTOFORDER;
    case 6:
    case "PLANNED":
      return Status.PLANNED;
    case 7:
    case "REMOVED":
      return Status.REMOVED;
    case 8:
    case "RESERVED":
      return Status.RESERVED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.Status_UNKNOWN:
      return "Status_UNKNOWN";
    case Status.AVAILABLE:
      return "AVAILABLE";
    case Status.BLOCKED:
      return "BLOCKED";
    case Status.CHARGING:
      return "CHARGING";
    case Status.INOPERATIVE:
      return "INOPERATIVE";
    case Status.OUTOFORDER:
      return "OUTOFORDER";
    case Status.PLANNED:
      return "PLANNED";
    case Status.REMOVED:
      return "REMOVED";
    case Status.RESERVED:
      return "RESERVED";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Unit {
  Kwh = 0,
  UNRECOGNIZED = -1,
}

export function unitFromJSON(object: any): Unit {
  switch (object) {
    case 0:
    case "Kwh":
      return Unit.Kwh;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Unit.UNRECOGNIZED;
  }
}

export function unitToJSON(object: Unit): string {
  switch (object) {
    case Unit.Kwh:
      return "Kwh";
    case Unit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ValuePosition {
  UnknownPosition = 0,
  Left = 1,
  Right = 2,
  UNRECOGNIZED = -1,
}

export function valuePositionFromJSON(object: any): ValuePosition {
  switch (object) {
    case 0:
    case "UnknownPosition":
      return ValuePosition.UnknownPosition;
    case 1:
    case "Left":
      return ValuePosition.Left;
    case 2:
    case "Right":
      return ValuePosition.Right;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ValuePosition.UNRECOGNIZED;
  }
}

export function valuePositionToJSON(object: ValuePosition): string {
  switch (object) {
    case ValuePosition.UnknownPosition:
      return "UnknownPosition";
    case ValuePosition.Left:
      return "Left";
    case ValuePosition.Right:
      return "Right";
    case ValuePosition.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AuditInformation {
  CreatedBy: ObjectIdEntity | undefined;
  CreatedOn: Date | undefined;
  ModifiedBy: ObjectIdEntity | undefined;
  ModifiedOn: Date | undefined;
}

export interface AvailableChargerRequest {
  SpaceId: string;
}

export interface Charger {
  Id: string;
  ExternalId: string;
  ExternalConnectorId: string;
  Status: Status;
  ExternalSource: EvSource;
  ExternalLocationId: string;
  DisplayName: string;
  ImageUrls: string[];
  LastStatusUpdate: Date | undefined;
  SpaceName: string;
  SpaceId: string;
  Audit: AuditInformation | undefined;
  LastSync: Date | undefined;
  LastPowerDelivery: string;
  PriceInfo: EvPriceInfo | undefined;
  IsAvailable: boolean;
  OcpiData: OcpiProperties | undefined;
}

export interface ChargerControlRequest {
  ChargerId: string;
  ChargerCommand: ChargerCommand;
}

export interface ChargerCreateDto {
  ExternalId: string;
  ExternalConnectorId: string;
  SpaceId: string;
  SpaceName: string;
  DisplayName: string;
}

export interface ChargerCreateRequest {
  Source: EvSource;
  ExternalId: string;
  ExternalConnectorId: string;
  Status: Status;
}

export interface ChargerRequest {
  Source: EvSource;
  ExternalId: string;
  ExternalConnectorId: string;
}

export interface ChargerSpace {
  AmountOfAvailableChargers: number;
}

export interface ChargerSpaces {
  SpaceIdToChargerSpace: { [key: string]: ChargerSpace };
}

export interface ChargerSpaces_SpaceIdToChargerSpaceEntry {
  key: string;
  value: ChargerSpace | undefined;
}

export interface ChargerSyncRequest {
  ChargerId: string;
}

export interface ChargersCreateRequest {
  ChargerCreateDtos: ChargerCreateDto[];
}

export interface ChargersDeleteRequest {
}

export interface ChargersRequest {
  Version: string;
  Ids: string[];
  OnlyPublished: boolean;
}

export interface ChargersSyncRequest {
}

export interface Currency {
}

export interface EvPriceInfo {
  NetPricePerUnit: string;
  GrossPricePerUnit: string;
  Unit: Unit;
  TaxPercentagePerUnit: string;
  Currency: Currency | undefined;
  CountryCode: string;
}

export interface EvResponseBoolean {
  timestamp: string;
  data: boolean;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseCharger {
  timestamp: string;
  data: Charger | undefined;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseChargerSpaces {
  timestamp: string;
  data: ChargerSpaces | undefined;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseIListCharger {
  timestamp: string;
  data: Charger[];
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseInt32 {
  timestamp: string;
  data: number;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseString {
  timestamp: string;
  data: string;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface GeoBoxSearch {
  LowerLeft: GeoSpace | undefined;
  UpperRight: GeoSpace | undefined;
}

export interface GeoCircleSearch {
  Center: GeoSpace | undefined;
  Radius: number;
}

export interface GeoMultiPolygon {
  Polygons: GeoSpace[];
}

export interface GeoPoint {
  Longitude: number;
  Latitude: number;
}

export interface GeoPolygon {
  Coordinates: GeoSpace[];
}

export interface GeoSearch {
  Path: string;
  GeoSpaceIntersect: GeoSpaceIntersect | undefined;
  GeoCircleSearch: GeoCircleSearch | undefined;
  GeoBoxSearch: GeoBoxSearch | undefined;
}

export interface GeoSpace {
  GeoPoint: GeoPoint | undefined;
  GeoPolygon: GeoPolygon | undefined;
  GeoMultiPolygon: GeoMultiPolygon | undefined;
}

export interface GeoSpaceIntersect {
  Geometry: GeoSpace | undefined;
}

export interface GetChargerSpaceDataRequest {
  SpaceIds: string[];
}

export interface InnerSearchRequest {
  Path: string;
  Filter: SearchFilter | undefined;
  SearchType: SearchType;
}

export interface Locale {
  Culture: string;
}

export interface ObjectIdEntity {
}

export interface OcpiProperties {
  PowerType: PowerType;
  Power: string;
  ConnectorType: ConnectorType;
}

export interface SearchFilter {
  SearchNAry: SearchNAry | undefined;
  SearchUnary: SearchUnary | undefined;
  SearchLiteral: SearchLiteral | undefined;
  InnerSearchRequest: InnerSearchRequest | undefined;
  GeoSearch: GeoSearch | undefined;
}

export interface SearchLiteral {
  Operator: SearchLiteralOperator;
  Path: string;
  Value: SearchValue | undefined;
  ValuePosition: ValuePosition;
  IsIgnoreCase: boolean;
}

export interface SearchNAry {
  Operator: SearchNAryOperator;
  Clauses: SearchFilter[];
}

export interface SearchOrderField {
  Path: string;
  Direction: SearchOrderDirection;
}

export interface SearchRequest {
  Filter: SearchFilter | undefined;
  Take: number;
  OrderBy: SearchOrderField[];
  Locale: Locale | undefined;
}

export interface SearchResponseCharger {
  Items: Charger[];
}

export interface SearchUnary {
  Operator: SearchUnaryOperator;
  Clause: SearchFilter | undefined;
}

export interface SearchValue {
  Value: Uint8Array;
}

function createBaseAuditInformation(): AuditInformation {
  return { CreatedBy: undefined, CreatedOn: undefined, ModifiedBy: undefined, ModifiedOn: undefined };
}

export const AuditInformation = {
  encode(message: AuditInformation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.CreatedBy !== undefined) {
      ObjectIdEntity.encode(message.CreatedBy, writer.uint32(10).fork()).ldelim();
    }
    if (message.CreatedOn !== undefined) {
      Timestamp.encode(toTimestamp(message.CreatedOn), writer.uint32(18).fork()).ldelim();
    }
    if (message.ModifiedBy !== undefined) {
      ObjectIdEntity.encode(message.ModifiedBy, writer.uint32(26).fork()).ldelim();
    }
    if (message.ModifiedOn !== undefined) {
      Timestamp.encode(toTimestamp(message.ModifiedOn), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuditInformation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuditInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CreatedBy = ObjectIdEntity.decode(reader, reader.uint32());
          break;
        case 2:
          message.CreatedOn = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.ModifiedBy = ObjectIdEntity.decode(reader, reader.uint32());
          break;
        case 4:
          message.ModifiedOn = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuditInformation {
    return {
      CreatedBy: isSet(object.CreatedBy) ? ObjectIdEntity.fromJSON(object.CreatedBy) : undefined,
      CreatedOn: isSet(object.CreatedOn) ? fromJsonTimestamp(object.CreatedOn) : undefined,
      ModifiedBy: isSet(object.ModifiedBy) ? ObjectIdEntity.fromJSON(object.ModifiedBy) : undefined,
      ModifiedOn: isSet(object.ModifiedOn) ? fromJsonTimestamp(object.ModifiedOn) : undefined,
    };
  },

  toJSON(message: AuditInformation): unknown {
    const obj: any = {};
    message.CreatedBy !== undefined &&
      (obj.CreatedBy = message.CreatedBy ? ObjectIdEntity.toJSON(message.CreatedBy) : undefined);
    message.CreatedOn !== undefined && (obj.CreatedOn = message.CreatedOn.toISOString());
    message.ModifiedBy !== undefined &&
      (obj.ModifiedBy = message.ModifiedBy ? ObjectIdEntity.toJSON(message.ModifiedBy) : undefined);
    message.ModifiedOn !== undefined && (obj.ModifiedOn = message.ModifiedOn.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AuditInformation>, I>>(object: I): AuditInformation {
    const message = createBaseAuditInformation();
    message.CreatedBy = (object.CreatedBy !== undefined && object.CreatedBy !== null)
      ? ObjectIdEntity.fromPartial(object.CreatedBy)
      : undefined;
    message.CreatedOn = object.CreatedOn ?? undefined;
    message.ModifiedBy = (object.ModifiedBy !== undefined && object.ModifiedBy !== null)
      ? ObjectIdEntity.fromPartial(object.ModifiedBy)
      : undefined;
    message.ModifiedOn = object.ModifiedOn ?? undefined;
    return message;
  },
};

function createBaseAvailableChargerRequest(): AvailableChargerRequest {
  return { SpaceId: "" };
}

export const AvailableChargerRequest = {
  encode(message: AvailableChargerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.SpaceId !== "") {
      writer.uint32(10).string(message.SpaceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableChargerRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableChargerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SpaceId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AvailableChargerRequest {
    return { SpaceId: isSet(object.SpaceId) ? String(object.SpaceId) : "" };
  },

  toJSON(message: AvailableChargerRequest): unknown {
    const obj: any = {};
    message.SpaceId !== undefined && (obj.SpaceId = message.SpaceId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AvailableChargerRequest>, I>>(object: I): AvailableChargerRequest {
    const message = createBaseAvailableChargerRequest();
    message.SpaceId = object.SpaceId ?? "";
    return message;
  },
};

function createBaseCharger(): Charger {
  return {
    Id: "",
    ExternalId: "",
    ExternalConnectorId: "",
    Status: 0,
    ExternalSource: 0,
    ExternalLocationId: "",
    DisplayName: "",
    ImageUrls: [],
    LastStatusUpdate: undefined,
    SpaceName: "",
    SpaceId: "",
    Audit: undefined,
    LastSync: undefined,
    LastPowerDelivery: "",
    PriceInfo: undefined,
    IsAvailable: false,
    OcpiData: undefined,
  };
}

export const Charger = {
  encode(message: Charger, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Id !== "") {
      writer.uint32(10).string(message.Id);
    }
    if (message.ExternalId !== "") {
      writer.uint32(18).string(message.ExternalId);
    }
    if (message.ExternalConnectorId !== "") {
      writer.uint32(26).string(message.ExternalConnectorId);
    }
    if (message.Status !== 0) {
      writer.uint32(32).int32(message.Status);
    }
    if (message.ExternalSource !== 0) {
      writer.uint32(40).int32(message.ExternalSource);
    }
    if (message.ExternalLocationId !== "") {
      writer.uint32(50).string(message.ExternalLocationId);
    }
    if (message.DisplayName !== "") {
      writer.uint32(58).string(message.DisplayName);
    }
    for (const v of message.ImageUrls) {
      writer.uint32(66).string(v!);
    }
    if (message.LastStatusUpdate !== undefined) {
      Timestamp.encode(toTimestamp(message.LastStatusUpdate), writer.uint32(74).fork()).ldelim();
    }
    if (message.SpaceName !== "") {
      writer.uint32(82).string(message.SpaceName);
    }
    if (message.SpaceId !== "") {
      writer.uint32(90).string(message.SpaceId);
    }
    if (message.Audit !== undefined) {
      AuditInformation.encode(message.Audit, writer.uint32(98).fork()).ldelim();
    }
    if (message.LastSync !== undefined) {
      Timestamp.encode(toTimestamp(message.LastSync), writer.uint32(106).fork()).ldelim();
    }
    if (message.LastPowerDelivery !== "") {
      writer.uint32(114).string(message.LastPowerDelivery);
    }
    if (message.PriceInfo !== undefined) {
      EvPriceInfo.encode(message.PriceInfo, writer.uint32(122).fork()).ldelim();
    }
    if (message.IsAvailable === true) {
      writer.uint32(128).bool(message.IsAvailable);
    }
    if (message.OcpiData !== undefined) {
      OcpiProperties.encode(message.OcpiData, writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Charger {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCharger();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Id = reader.string();
          break;
        case 2:
          message.ExternalId = reader.string();
          break;
        case 3:
          message.ExternalConnectorId = reader.string();
          break;
        case 4:
          message.Status = reader.int32() as any;
          break;
        case 5:
          message.ExternalSource = reader.int32() as any;
          break;
        case 6:
          message.ExternalLocationId = reader.string();
          break;
        case 7:
          message.DisplayName = reader.string();
          break;
        case 8:
          message.ImageUrls.push(reader.string());
          break;
        case 9:
          message.LastStatusUpdate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 10:
          message.SpaceName = reader.string();
          break;
        case 11:
          message.SpaceId = reader.string();
          break;
        case 12:
          message.Audit = AuditInformation.decode(reader, reader.uint32());
          break;
        case 13:
          message.LastSync = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 14:
          message.LastPowerDelivery = reader.string();
          break;
        case 15:
          message.PriceInfo = EvPriceInfo.decode(reader, reader.uint32());
          break;
        case 16:
          message.IsAvailable = reader.bool();
          break;
        case 17:
          message.OcpiData = OcpiProperties.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Charger {
    return {
      Id: isSet(object.Id) ? String(object.Id) : "",
      ExternalId: isSet(object.ExternalId) ? String(object.ExternalId) : "",
      ExternalConnectorId: isSet(object.ExternalConnectorId) ? String(object.ExternalConnectorId) : "",
      Status: isSet(object.Status) ? statusFromJSON(object.Status) : 0,
      ExternalSource: isSet(object.ExternalSource) ? evSourceFromJSON(object.ExternalSource) : 0,
      ExternalLocationId: isSet(object.ExternalLocationId) ? String(object.ExternalLocationId) : "",
      DisplayName: isSet(object.DisplayName) ? String(object.DisplayName) : "",
      ImageUrls: Array.isArray(object?.ImageUrls) ? object.ImageUrls.map((e: any) => String(e)) : [],
      LastStatusUpdate: isSet(object.LastStatusUpdate) ? fromJsonTimestamp(object.LastStatusUpdate) : undefined,
      SpaceName: isSet(object.SpaceName) ? String(object.SpaceName) : "",
      SpaceId: isSet(object.SpaceId) ? String(object.SpaceId) : "",
      Audit: isSet(object.Audit) ? AuditInformation.fromJSON(object.Audit) : undefined,
      LastSync: isSet(object.LastSync) ? fromJsonTimestamp(object.LastSync) : undefined,
      LastPowerDelivery: isSet(object.LastPowerDelivery) ? String(object.LastPowerDelivery) : "",
      PriceInfo: isSet(object.PriceInfo) ? EvPriceInfo.fromJSON(object.PriceInfo) : undefined,
      IsAvailable: isSet(object.IsAvailable) ? Boolean(object.IsAvailable) : false,
      OcpiData: isSet(object.OcpiData) ? OcpiProperties.fromJSON(object.OcpiData) : undefined,
    };
  },

  toJSON(message: Charger): unknown {
    const obj: any = {};
    message.Id !== undefined && (obj.Id = message.Id);
    message.ExternalId !== undefined && (obj.ExternalId = message.ExternalId);
    message.ExternalConnectorId !== undefined && (obj.ExternalConnectorId = message.ExternalConnectorId);
    message.Status !== undefined && (obj.Status = statusToJSON(message.Status));
    message.ExternalSource !== undefined && (obj.ExternalSource = evSourceToJSON(message.ExternalSource));
    message.ExternalLocationId !== undefined && (obj.ExternalLocationId = message.ExternalLocationId);
    message.DisplayName !== undefined && (obj.DisplayName = message.DisplayName);
    if (message.ImageUrls) {
      obj.ImageUrls = message.ImageUrls.map((e) => e);
    } else {
      obj.ImageUrls = [];
    }
    message.LastStatusUpdate !== undefined && (obj.LastStatusUpdate = message.LastStatusUpdate.toISOString());
    message.SpaceName !== undefined && (obj.SpaceName = message.SpaceName);
    message.SpaceId !== undefined && (obj.SpaceId = message.SpaceId);
    message.Audit !== undefined && (obj.Audit = message.Audit ? AuditInformation.toJSON(message.Audit) : undefined);
    message.LastSync !== undefined && (obj.LastSync = message.LastSync.toISOString());
    message.LastPowerDelivery !== undefined && (obj.LastPowerDelivery = message.LastPowerDelivery);
    message.PriceInfo !== undefined &&
      (obj.PriceInfo = message.PriceInfo ? EvPriceInfo.toJSON(message.PriceInfo) : undefined);
    message.IsAvailable !== undefined && (obj.IsAvailable = message.IsAvailable);
    message.OcpiData !== undefined &&
      (obj.OcpiData = message.OcpiData ? OcpiProperties.toJSON(message.OcpiData) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Charger>, I>>(object: I): Charger {
    const message = createBaseCharger();
    message.Id = object.Id ?? "";
    message.ExternalId = object.ExternalId ?? "";
    message.ExternalConnectorId = object.ExternalConnectorId ?? "";
    message.Status = object.Status ?? 0;
    message.ExternalSource = object.ExternalSource ?? 0;
    message.ExternalLocationId = object.ExternalLocationId ?? "";
    message.DisplayName = object.DisplayName ?? "";
    message.ImageUrls = object.ImageUrls?.map((e) => e) || [];
    message.LastStatusUpdate = object.LastStatusUpdate ?? undefined;
    message.SpaceName = object.SpaceName ?? "";
    message.SpaceId = object.SpaceId ?? "";
    message.Audit = (object.Audit !== undefined && object.Audit !== null)
      ? AuditInformation.fromPartial(object.Audit)
      : undefined;
    message.LastSync = object.LastSync ?? undefined;
    message.LastPowerDelivery = object.LastPowerDelivery ?? "";
    message.PriceInfo = (object.PriceInfo !== undefined && object.PriceInfo !== null)
      ? EvPriceInfo.fromPartial(object.PriceInfo)
      : undefined;
    message.IsAvailable = object.IsAvailable ?? false;
    message.OcpiData = (object.OcpiData !== undefined && object.OcpiData !== null)
      ? OcpiProperties.fromPartial(object.OcpiData)
      : undefined;
    return message;
  },
};

function createBaseChargerControlRequest(): ChargerControlRequest {
  return { ChargerId: "", ChargerCommand: 0 };
}

export const ChargerControlRequest = {
  encode(message: ChargerControlRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargerId !== "") {
      writer.uint32(10).string(message.ChargerId);
    }
    if (message.ChargerCommand !== 0) {
      writer.uint32(16).int32(message.ChargerCommand);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerControlRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerControlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargerId = reader.string();
          break;
        case 2:
          message.ChargerCommand = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerControlRequest {
    return {
      ChargerId: isSet(object.ChargerId) ? String(object.ChargerId) : "",
      ChargerCommand: isSet(object.ChargerCommand) ? chargerCommandFromJSON(object.ChargerCommand) : 0,
    };
  },

  toJSON(message: ChargerControlRequest): unknown {
    const obj: any = {};
    message.ChargerId !== undefined && (obj.ChargerId = message.ChargerId);
    message.ChargerCommand !== undefined && (obj.ChargerCommand = chargerCommandToJSON(message.ChargerCommand));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerControlRequest>, I>>(object: I): ChargerControlRequest {
    const message = createBaseChargerControlRequest();
    message.ChargerId = object.ChargerId ?? "";
    message.ChargerCommand = object.ChargerCommand ?? 0;
    return message;
  },
};

function createBaseChargerCreateDto(): ChargerCreateDto {
  return { ExternalId: "", ExternalConnectorId: "", SpaceId: "", SpaceName: "", DisplayName: "" };
}

export const ChargerCreateDto = {
  encode(message: ChargerCreateDto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ExternalId !== "") {
      writer.uint32(10).string(message.ExternalId);
    }
    if (message.ExternalConnectorId !== "") {
      writer.uint32(18).string(message.ExternalConnectorId);
    }
    if (message.SpaceId !== "") {
      writer.uint32(26).string(message.SpaceId);
    }
    if (message.SpaceName !== "") {
      writer.uint32(34).string(message.SpaceName);
    }
    if (message.DisplayName !== "") {
      writer.uint32(42).string(message.DisplayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerCreateDto {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerCreateDto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ExternalId = reader.string();
          break;
        case 2:
          message.ExternalConnectorId = reader.string();
          break;
        case 3:
          message.SpaceId = reader.string();
          break;
        case 4:
          message.SpaceName = reader.string();
          break;
        case 5:
          message.DisplayName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerCreateDto {
    return {
      ExternalId: isSet(object.ExternalId) ? String(object.ExternalId) : "",
      ExternalConnectorId: isSet(object.ExternalConnectorId) ? String(object.ExternalConnectorId) : "",
      SpaceId: isSet(object.SpaceId) ? String(object.SpaceId) : "",
      SpaceName: isSet(object.SpaceName) ? String(object.SpaceName) : "",
      DisplayName: isSet(object.DisplayName) ? String(object.DisplayName) : "",
    };
  },

  toJSON(message: ChargerCreateDto): unknown {
    const obj: any = {};
    message.ExternalId !== undefined && (obj.ExternalId = message.ExternalId);
    message.ExternalConnectorId !== undefined && (obj.ExternalConnectorId = message.ExternalConnectorId);
    message.SpaceId !== undefined && (obj.SpaceId = message.SpaceId);
    message.SpaceName !== undefined && (obj.SpaceName = message.SpaceName);
    message.DisplayName !== undefined && (obj.DisplayName = message.DisplayName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerCreateDto>, I>>(object: I): ChargerCreateDto {
    const message = createBaseChargerCreateDto();
    message.ExternalId = object.ExternalId ?? "";
    message.ExternalConnectorId = object.ExternalConnectorId ?? "";
    message.SpaceId = object.SpaceId ?? "";
    message.SpaceName = object.SpaceName ?? "";
    message.DisplayName = object.DisplayName ?? "";
    return message;
  },
};

function createBaseChargerCreateRequest(): ChargerCreateRequest {
  return { Source: 0, ExternalId: "", ExternalConnectorId: "", Status: 0 };
}

export const ChargerCreateRequest = {
  encode(message: ChargerCreateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Source !== 0) {
      writer.uint32(8).int32(message.Source);
    }
    if (message.ExternalId !== "") {
      writer.uint32(18).string(message.ExternalId);
    }
    if (message.ExternalConnectorId !== "") {
      writer.uint32(26).string(message.ExternalConnectorId);
    }
    if (message.Status !== 0) {
      writer.uint32(32).int32(message.Status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerCreateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Source = reader.int32() as any;
          break;
        case 2:
          message.ExternalId = reader.string();
          break;
        case 3:
          message.ExternalConnectorId = reader.string();
          break;
        case 4:
          message.Status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerCreateRequest {
    return {
      Source: isSet(object.Source) ? evSourceFromJSON(object.Source) : 0,
      ExternalId: isSet(object.ExternalId) ? String(object.ExternalId) : "",
      ExternalConnectorId: isSet(object.ExternalConnectorId) ? String(object.ExternalConnectorId) : "",
      Status: isSet(object.Status) ? statusFromJSON(object.Status) : 0,
    };
  },

  toJSON(message: ChargerCreateRequest): unknown {
    const obj: any = {};
    message.Source !== undefined && (obj.Source = evSourceToJSON(message.Source));
    message.ExternalId !== undefined && (obj.ExternalId = message.ExternalId);
    message.ExternalConnectorId !== undefined && (obj.ExternalConnectorId = message.ExternalConnectorId);
    message.Status !== undefined && (obj.Status = statusToJSON(message.Status));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerCreateRequest>, I>>(object: I): ChargerCreateRequest {
    const message = createBaseChargerCreateRequest();
    message.Source = object.Source ?? 0;
    message.ExternalId = object.ExternalId ?? "";
    message.ExternalConnectorId = object.ExternalConnectorId ?? "";
    message.Status = object.Status ?? 0;
    return message;
  },
};

function createBaseChargerRequest(): ChargerRequest {
  return { Source: 0, ExternalId: "", ExternalConnectorId: "" };
}

export const ChargerRequest = {
  encode(message: ChargerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Source !== 0) {
      writer.uint32(8).int32(message.Source);
    }
    if (message.ExternalId !== "") {
      writer.uint32(18).string(message.ExternalId);
    }
    if (message.ExternalConnectorId !== "") {
      writer.uint32(26).string(message.ExternalConnectorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Source = reader.int32() as any;
          break;
        case 2:
          message.ExternalId = reader.string();
          break;
        case 3:
          message.ExternalConnectorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerRequest {
    return {
      Source: isSet(object.Source) ? evSourceFromJSON(object.Source) : 0,
      ExternalId: isSet(object.ExternalId) ? String(object.ExternalId) : "",
      ExternalConnectorId: isSet(object.ExternalConnectorId) ? String(object.ExternalConnectorId) : "",
    };
  },

  toJSON(message: ChargerRequest): unknown {
    const obj: any = {};
    message.Source !== undefined && (obj.Source = evSourceToJSON(message.Source));
    message.ExternalId !== undefined && (obj.ExternalId = message.ExternalId);
    message.ExternalConnectorId !== undefined && (obj.ExternalConnectorId = message.ExternalConnectorId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerRequest>, I>>(object: I): ChargerRequest {
    const message = createBaseChargerRequest();
    message.Source = object.Source ?? 0;
    message.ExternalId = object.ExternalId ?? "";
    message.ExternalConnectorId = object.ExternalConnectorId ?? "";
    return message;
  },
};

function createBaseChargerSpace(): ChargerSpace {
  return { AmountOfAvailableChargers: 0 };
}

export const ChargerSpace = {
  encode(message: ChargerSpace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.AmountOfAvailableChargers !== 0) {
      writer.uint32(8).int32(message.AmountOfAvailableChargers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerSpace {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerSpace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AmountOfAvailableChargers = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerSpace {
    return {
      AmountOfAvailableChargers: isSet(object.AmountOfAvailableChargers) ? Number(object.AmountOfAvailableChargers) : 0,
    };
  },

  toJSON(message: ChargerSpace): unknown {
    const obj: any = {};
    message.AmountOfAvailableChargers !== undefined &&
      (obj.AmountOfAvailableChargers = Math.round(message.AmountOfAvailableChargers));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerSpace>, I>>(object: I): ChargerSpace {
    const message = createBaseChargerSpace();
    message.AmountOfAvailableChargers = object.AmountOfAvailableChargers ?? 0;
    return message;
  },
};

function createBaseChargerSpaces(): ChargerSpaces {
  return { SpaceIdToChargerSpace: {} };
}

export const ChargerSpaces = {
  encode(message: ChargerSpaces, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.SpaceIdToChargerSpace).forEach(([key, value]) => {
      ChargerSpaces_SpaceIdToChargerSpaceEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerSpaces {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerSpaces();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = ChargerSpaces_SpaceIdToChargerSpaceEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.SpaceIdToChargerSpace[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerSpaces {
    return {
      SpaceIdToChargerSpace: isObject(object.SpaceIdToChargerSpace)
        ? Object.entries(object.SpaceIdToChargerSpace).reduce<{ [key: string]: ChargerSpace }>((acc, [key, value]) => {
          acc[key] = ChargerSpace.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ChargerSpaces): unknown {
    const obj: any = {};
    obj.SpaceIdToChargerSpace = {};
    if (message.SpaceIdToChargerSpace) {
      Object.entries(message.SpaceIdToChargerSpace).forEach(([k, v]) => {
        obj.SpaceIdToChargerSpace[k] = ChargerSpace.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerSpaces>, I>>(object: I): ChargerSpaces {
    const message = createBaseChargerSpaces();
    message.SpaceIdToChargerSpace = Object.entries(object.SpaceIdToChargerSpace ?? {}).reduce<
      { [key: string]: ChargerSpace }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ChargerSpace.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseChargerSpaces_SpaceIdToChargerSpaceEntry(): ChargerSpaces_SpaceIdToChargerSpaceEntry {
  return { key: "", value: undefined };
}

export const ChargerSpaces_SpaceIdToChargerSpaceEntry = {
  encode(message: ChargerSpaces_SpaceIdToChargerSpaceEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ChargerSpace.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerSpaces_SpaceIdToChargerSpaceEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerSpaces_SpaceIdToChargerSpaceEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = ChargerSpace.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerSpaces_SpaceIdToChargerSpaceEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ChargerSpace.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ChargerSpaces_SpaceIdToChargerSpaceEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ChargerSpace.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerSpaces_SpaceIdToChargerSpaceEntry>, I>>(
    object: I,
  ): ChargerSpaces_SpaceIdToChargerSpaceEntry {
    const message = createBaseChargerSpaces_SpaceIdToChargerSpaceEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ChargerSpace.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseChargerSyncRequest(): ChargerSyncRequest {
  return { ChargerId: "" };
}

export const ChargerSyncRequest = {
  encode(message: ChargerSyncRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargerId !== "") {
      writer.uint32(10).string(message.ChargerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargerSyncRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargerSyncRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargerId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargerSyncRequest {
    return { ChargerId: isSet(object.ChargerId) ? String(object.ChargerId) : "" };
  },

  toJSON(message: ChargerSyncRequest): unknown {
    const obj: any = {};
    message.ChargerId !== undefined && (obj.ChargerId = message.ChargerId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargerSyncRequest>, I>>(object: I): ChargerSyncRequest {
    const message = createBaseChargerSyncRequest();
    message.ChargerId = object.ChargerId ?? "";
    return message;
  },
};

function createBaseChargersCreateRequest(): ChargersCreateRequest {
  return { ChargerCreateDtos: [] };
}

export const ChargersCreateRequest = {
  encode(message: ChargersCreateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ChargerCreateDtos) {
      ChargerCreateDto.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargersCreateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargersCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargerCreateDtos.push(ChargerCreateDto.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargersCreateRequest {
    return {
      ChargerCreateDtos: Array.isArray(object?.ChargerCreateDtos)
        ? object.ChargerCreateDtos.map((e: any) => ChargerCreateDto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ChargersCreateRequest): unknown {
    const obj: any = {};
    if (message.ChargerCreateDtos) {
      obj.ChargerCreateDtos = message.ChargerCreateDtos.map((e) => e ? ChargerCreateDto.toJSON(e) : undefined);
    } else {
      obj.ChargerCreateDtos = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargersCreateRequest>, I>>(object: I): ChargersCreateRequest {
    const message = createBaseChargersCreateRequest();
    message.ChargerCreateDtos = object.ChargerCreateDtos?.map((e) => ChargerCreateDto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChargersDeleteRequest(): ChargersDeleteRequest {
  return {};
}

export const ChargersDeleteRequest = {
  encode(_: ChargersDeleteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargersDeleteRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargersDeleteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ChargersDeleteRequest {
    return {};
  },

  toJSON(_: ChargersDeleteRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargersDeleteRequest>, I>>(_: I): ChargersDeleteRequest {
    const message = createBaseChargersDeleteRequest();
    return message;
  },
};

function createBaseChargersRequest(): ChargersRequest {
  return { Version: "", Ids: [], OnlyPublished: false };
}

export const ChargersRequest = {
  encode(message: ChargersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Version !== "") {
      writer.uint32(10).string(message.Version);
    }
    for (const v of message.Ids) {
      writer.uint32(18).string(v!);
    }
    if (message.OnlyPublished === true) {
      writer.uint32(24).bool(message.OnlyPublished);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Version = reader.string();
          break;
        case 2:
          message.Ids.push(reader.string());
          break;
        case 3:
          message.OnlyPublished = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargersRequest {
    return {
      Version: isSet(object.Version) ? String(object.Version) : "",
      Ids: Array.isArray(object?.Ids) ? object.Ids.map((e: any) => String(e)) : [],
      OnlyPublished: isSet(object.OnlyPublished) ? Boolean(object.OnlyPublished) : false,
    };
  },

  toJSON(message: ChargersRequest): unknown {
    const obj: any = {};
    message.Version !== undefined && (obj.Version = message.Version);
    if (message.Ids) {
      obj.Ids = message.Ids.map((e) => e);
    } else {
      obj.Ids = [];
    }
    message.OnlyPublished !== undefined && (obj.OnlyPublished = message.OnlyPublished);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargersRequest>, I>>(object: I): ChargersRequest {
    const message = createBaseChargersRequest();
    message.Version = object.Version ?? "";
    message.Ids = object.Ids?.map((e) => e) || [];
    message.OnlyPublished = object.OnlyPublished ?? false;
    return message;
  },
};

function createBaseChargersSyncRequest(): ChargersSyncRequest {
  return {};
}

export const ChargersSyncRequest = {
  encode(_: ChargersSyncRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargersSyncRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargersSyncRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ChargersSyncRequest {
    return {};
  },

  toJSON(_: ChargersSyncRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargersSyncRequest>, I>>(_: I): ChargersSyncRequest {
    const message = createBaseChargersSyncRequest();
    return message;
  },
};

function createBaseCurrency(): Currency {
  return {};
}

export const Currency = {
  encode(_: Currency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Currency {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Currency {
    return {};
  },

  toJSON(_: Currency): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Currency>, I>>(_: I): Currency {
    const message = createBaseCurrency();
    return message;
  },
};

function createBaseEvPriceInfo(): EvPriceInfo {
  return {
    NetPricePerUnit: "",
    GrossPricePerUnit: "",
    Unit: 0,
    TaxPercentagePerUnit: "",
    Currency: undefined,
    CountryCode: "",
  };
}

export const EvPriceInfo = {
  encode(message: EvPriceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.NetPricePerUnit !== "") {
      writer.uint32(10).string(message.NetPricePerUnit);
    }
    if (message.GrossPricePerUnit !== "") {
      writer.uint32(18).string(message.GrossPricePerUnit);
    }
    if (message.Unit !== 0) {
      writer.uint32(24).int32(message.Unit);
    }
    if (message.TaxPercentagePerUnit !== "") {
      writer.uint32(34).string(message.TaxPercentagePerUnit);
    }
    if (message.Currency !== undefined) {
      Currency.encode(message.Currency, writer.uint32(42).fork()).ldelim();
    }
    if (message.CountryCode !== "") {
      writer.uint32(50).string(message.CountryCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvPriceInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvPriceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.NetPricePerUnit = reader.string();
          break;
        case 2:
          message.GrossPricePerUnit = reader.string();
          break;
        case 3:
          message.Unit = reader.int32() as any;
          break;
        case 4:
          message.TaxPercentagePerUnit = reader.string();
          break;
        case 5:
          message.Currency = Currency.decode(reader, reader.uint32());
          break;
        case 6:
          message.CountryCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvPriceInfo {
    return {
      NetPricePerUnit: isSet(object.NetPricePerUnit) ? String(object.NetPricePerUnit) : "",
      GrossPricePerUnit: isSet(object.GrossPricePerUnit) ? String(object.GrossPricePerUnit) : "",
      Unit: isSet(object.Unit) ? unitFromJSON(object.Unit) : 0,
      TaxPercentagePerUnit: isSet(object.TaxPercentagePerUnit) ? String(object.TaxPercentagePerUnit) : "",
      Currency: isSet(object.Currency) ? Currency.fromJSON(object.Currency) : undefined,
      CountryCode: isSet(object.CountryCode) ? String(object.CountryCode) : "",
    };
  },

  toJSON(message: EvPriceInfo): unknown {
    const obj: any = {};
    message.NetPricePerUnit !== undefined && (obj.NetPricePerUnit = message.NetPricePerUnit);
    message.GrossPricePerUnit !== undefined && (obj.GrossPricePerUnit = message.GrossPricePerUnit);
    message.Unit !== undefined && (obj.Unit = unitToJSON(message.Unit));
    message.TaxPercentagePerUnit !== undefined && (obj.TaxPercentagePerUnit = message.TaxPercentagePerUnit);
    message.Currency !== undefined && (obj.Currency = message.Currency ? Currency.toJSON(message.Currency) : undefined);
    message.CountryCode !== undefined && (obj.CountryCode = message.CountryCode);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvPriceInfo>, I>>(object: I): EvPriceInfo {
    const message = createBaseEvPriceInfo();
    message.NetPricePerUnit = object.NetPricePerUnit ?? "";
    message.GrossPricePerUnit = object.GrossPricePerUnit ?? "";
    message.Unit = object.Unit ?? 0;
    message.TaxPercentagePerUnit = object.TaxPercentagePerUnit ?? "";
    message.Currency = (object.Currency !== undefined && object.Currency !== null)
      ? Currency.fromPartial(object.Currency)
      : undefined;
    message.CountryCode = object.CountryCode ?? "";
    return message;
  },
};

function createBaseEvResponseBoolean(): EvResponseBoolean {
  return { timestamp: "", data: false, statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseBoolean = {
  encode(message: EvResponseBoolean, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data === true) {
      writer.uint32(16).bool(message.data);
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseBoolean {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseBoolean();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = reader.bool();
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseBoolean {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? Boolean(object.data) : false,
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseBoolean): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = message.data);
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseBoolean>, I>>(object: I): EvResponseBoolean {
    const message = createBaseEvResponseBoolean();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data ?? false;
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseCharger(): EvResponseCharger {
  return { timestamp: "", data: undefined, statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseCharger = {
  encode(message: EvResponseCharger, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data !== undefined) {
      Charger.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseCharger {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseCharger();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = Charger.decode(reader, reader.uint32());
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseCharger {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? Charger.fromJSON(object.data) : undefined,
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseCharger): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = message.data ? Charger.toJSON(message.data) : undefined);
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseCharger>, I>>(object: I): EvResponseCharger {
    const message = createBaseEvResponseCharger();
    message.timestamp = object.timestamp ?? "";
    message.data = (object.data !== undefined && object.data !== null) ? Charger.fromPartial(object.data) : undefined;
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseChargerSpaces(): EvResponseChargerSpaces {
  return { timestamp: "", data: undefined, statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseChargerSpaces = {
  encode(message: EvResponseChargerSpaces, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data !== undefined) {
      ChargerSpaces.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseChargerSpaces {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseChargerSpaces();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = ChargerSpaces.decode(reader, reader.uint32());
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseChargerSpaces {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? ChargerSpaces.fromJSON(object.data) : undefined,
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseChargerSpaces): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = message.data ? ChargerSpaces.toJSON(message.data) : undefined);
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseChargerSpaces>, I>>(object: I): EvResponseChargerSpaces {
    const message = createBaseEvResponseChargerSpaces();
    message.timestamp = object.timestamp ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? ChargerSpaces.fromPartial(object.data)
      : undefined;
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseIListCharger(): EvResponseIListCharger {
  return { timestamp: "", data: [], statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseIListCharger = {
  encode(message: EvResponseIListCharger, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    for (const v of message.data) {
      Charger.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseIListCharger {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseIListCharger();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data.push(Charger.decode(reader, reader.uint32()));
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseIListCharger {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: Array.isArray(object?.data) ? object.data.map((e: any) => Charger.fromJSON(e)) : [],
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseIListCharger): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    if (message.data) {
      obj.data = message.data.map((e) => e ? Charger.toJSON(e) : undefined);
    } else {
      obj.data = [];
    }
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseIListCharger>, I>>(object: I): EvResponseIListCharger {
    const message = createBaseEvResponseIListCharger();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data?.map((e) => Charger.fromPartial(e)) || [];
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseInt32(): EvResponseInt32 {
  return { timestamp: "", data: 0, statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseInt32 = {
  encode(message: EvResponseInt32, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data !== 0) {
      writer.uint32(16).int32(message.data);
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseInt32 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseInt32();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = reader.int32();
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseInt32 {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? Number(object.data) : 0,
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseInt32): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = Math.round(message.data));
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseInt32>, I>>(object: I): EvResponseInt32 {
    const message = createBaseEvResponseInt32();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data ?? 0;
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseString(): EvResponseString {
  return { timestamp: "", data: "", statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseString = {
  encode(message: EvResponseString, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data !== "") {
      writer.uint32(18).string(message.data);
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseString {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseString();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = reader.string();
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseString {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? String(object.data) : "",
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseString): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = message.data);
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseString>, I>>(object: I): EvResponseString {
    const message = createBaseEvResponseString();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data ?? "";
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseGeoBoxSearch(): GeoBoxSearch {
  return { LowerLeft: undefined, UpperRight: undefined };
}

export const GeoBoxSearch = {
  encode(message: GeoBoxSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.LowerLeft !== undefined) {
      GeoSpace.encode(message.LowerLeft, writer.uint32(10).fork()).ldelim();
    }
    if (message.UpperRight !== undefined) {
      GeoSpace.encode(message.UpperRight, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoBoxSearch {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoBoxSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LowerLeft = GeoSpace.decode(reader, reader.uint32());
          break;
        case 2:
          message.UpperRight = GeoSpace.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoBoxSearch {
    return {
      LowerLeft: isSet(object.LowerLeft) ? GeoSpace.fromJSON(object.LowerLeft) : undefined,
      UpperRight: isSet(object.UpperRight) ? GeoSpace.fromJSON(object.UpperRight) : undefined,
    };
  },

  toJSON(message: GeoBoxSearch): unknown {
    const obj: any = {};
    message.LowerLeft !== undefined &&
      (obj.LowerLeft = message.LowerLeft ? GeoSpace.toJSON(message.LowerLeft) : undefined);
    message.UpperRight !== undefined &&
      (obj.UpperRight = message.UpperRight ? GeoSpace.toJSON(message.UpperRight) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoBoxSearch>, I>>(object: I): GeoBoxSearch {
    const message = createBaseGeoBoxSearch();
    message.LowerLeft = (object.LowerLeft !== undefined && object.LowerLeft !== null)
      ? GeoSpace.fromPartial(object.LowerLeft)
      : undefined;
    message.UpperRight = (object.UpperRight !== undefined && object.UpperRight !== null)
      ? GeoSpace.fromPartial(object.UpperRight)
      : undefined;
    return message;
  },
};

function createBaseGeoCircleSearch(): GeoCircleSearch {
  return { Center: undefined, Radius: 0 };
}

export const GeoCircleSearch = {
  encode(message: GeoCircleSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Center !== undefined) {
      GeoSpace.encode(message.Center, writer.uint32(10).fork()).ldelim();
    }
    if (message.Radius !== 0) {
      writer.uint32(17).double(message.Radius);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoCircleSearch {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoCircleSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Center = GeoSpace.decode(reader, reader.uint32());
          break;
        case 2:
          message.Radius = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoCircleSearch {
    return {
      Center: isSet(object.Center) ? GeoSpace.fromJSON(object.Center) : undefined,
      Radius: isSet(object.Radius) ? Number(object.Radius) : 0,
    };
  },

  toJSON(message: GeoCircleSearch): unknown {
    const obj: any = {};
    message.Center !== undefined && (obj.Center = message.Center ? GeoSpace.toJSON(message.Center) : undefined);
    message.Radius !== undefined && (obj.Radius = message.Radius);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoCircleSearch>, I>>(object: I): GeoCircleSearch {
    const message = createBaseGeoCircleSearch();
    message.Center = (object.Center !== undefined && object.Center !== null)
      ? GeoSpace.fromPartial(object.Center)
      : undefined;
    message.Radius = object.Radius ?? 0;
    return message;
  },
};

function createBaseGeoMultiPolygon(): GeoMultiPolygon {
  return { Polygons: [] };
}

export const GeoMultiPolygon = {
  encode(message: GeoMultiPolygon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Polygons) {
      GeoSpace.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoMultiPolygon {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoMultiPolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Polygons.push(GeoSpace.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoMultiPolygon {
    return { Polygons: Array.isArray(object?.Polygons) ? object.Polygons.map((e: any) => GeoSpace.fromJSON(e)) : [] };
  },

  toJSON(message: GeoMultiPolygon): unknown {
    const obj: any = {};
    if (message.Polygons) {
      obj.Polygons = message.Polygons.map((e) => e ? GeoSpace.toJSON(e) : undefined);
    } else {
      obj.Polygons = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoMultiPolygon>, I>>(object: I): GeoMultiPolygon {
    const message = createBaseGeoMultiPolygon();
    message.Polygons = object.Polygons?.map((e) => GeoSpace.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeoPoint(): GeoPoint {
  return { Longitude: 0, Latitude: 0 };
}

export const GeoPoint = {
  encode(message: GeoPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Longitude !== 0) {
      writer.uint32(9).double(message.Longitude);
    }
    if (message.Latitude !== 0) {
      writer.uint32(17).double(message.Latitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoPoint {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Longitude = reader.double();
          break;
        case 2:
          message.Latitude = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoPoint {
    return {
      Longitude: isSet(object.Longitude) ? Number(object.Longitude) : 0,
      Latitude: isSet(object.Latitude) ? Number(object.Latitude) : 0,
    };
  },

  toJSON(message: GeoPoint): unknown {
    const obj: any = {};
    message.Longitude !== undefined && (obj.Longitude = message.Longitude);
    message.Latitude !== undefined && (obj.Latitude = message.Latitude);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoPoint>, I>>(object: I): GeoPoint {
    const message = createBaseGeoPoint();
    message.Longitude = object.Longitude ?? 0;
    message.Latitude = object.Latitude ?? 0;
    return message;
  },
};

function createBaseGeoPolygon(): GeoPolygon {
  return { Coordinates: [] };
}

export const GeoPolygon = {
  encode(message: GeoPolygon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Coordinates) {
      GeoSpace.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoPolygon {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoPolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Coordinates.push(GeoSpace.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoPolygon {
    return {
      Coordinates: Array.isArray(object?.Coordinates) ? object.Coordinates.map((e: any) => GeoSpace.fromJSON(e)) : [],
    };
  },

  toJSON(message: GeoPolygon): unknown {
    const obj: any = {};
    if (message.Coordinates) {
      obj.Coordinates = message.Coordinates.map((e) => e ? GeoSpace.toJSON(e) : undefined);
    } else {
      obj.Coordinates = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoPolygon>, I>>(object: I): GeoPolygon {
    const message = createBaseGeoPolygon();
    message.Coordinates = object.Coordinates?.map((e) => GeoSpace.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeoSearch(): GeoSearch {
  return { Path: "", GeoSpaceIntersect: undefined, GeoCircleSearch: undefined, GeoBoxSearch: undefined };
}

export const GeoSearch = {
  encode(message: GeoSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Path !== "") {
      writer.uint32(10).string(message.Path);
    }
    if (message.GeoSpaceIntersect !== undefined) {
      GeoSpaceIntersect.encode(message.GeoSpaceIntersect, writer.uint32(8002).fork()).ldelim();
    }
    if (message.GeoCircleSearch !== undefined) {
      GeoCircleSearch.encode(message.GeoCircleSearch, writer.uint32(8010).fork()).ldelim();
    }
    if (message.GeoBoxSearch !== undefined) {
      GeoBoxSearch.encode(message.GeoBoxSearch, writer.uint32(8018).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoSearch {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Path = reader.string();
          break;
        case 1000:
          message.GeoSpaceIntersect = GeoSpaceIntersect.decode(reader, reader.uint32());
          break;
        case 1001:
          message.GeoCircleSearch = GeoCircleSearch.decode(reader, reader.uint32());
          break;
        case 1002:
          message.GeoBoxSearch = GeoBoxSearch.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoSearch {
    return {
      Path: isSet(object.Path) ? String(object.Path) : "",
      GeoSpaceIntersect: isSet(object.GeoSpaceIntersect)
        ? GeoSpaceIntersect.fromJSON(object.GeoSpaceIntersect)
        : undefined,
      GeoCircleSearch: isSet(object.GeoCircleSearch) ? GeoCircleSearch.fromJSON(object.GeoCircleSearch) : undefined,
      GeoBoxSearch: isSet(object.GeoBoxSearch) ? GeoBoxSearch.fromJSON(object.GeoBoxSearch) : undefined,
    };
  },

  toJSON(message: GeoSearch): unknown {
    const obj: any = {};
    message.Path !== undefined && (obj.Path = message.Path);
    message.GeoSpaceIntersect !== undefined && (obj.GeoSpaceIntersect = message.GeoSpaceIntersect
      ? GeoSpaceIntersect.toJSON(message.GeoSpaceIntersect)
      : undefined);
    message.GeoCircleSearch !== undefined &&
      (obj.GeoCircleSearch = message.GeoCircleSearch ? GeoCircleSearch.toJSON(message.GeoCircleSearch) : undefined);
    message.GeoBoxSearch !== undefined &&
      (obj.GeoBoxSearch = message.GeoBoxSearch ? GeoBoxSearch.toJSON(message.GeoBoxSearch) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoSearch>, I>>(object: I): GeoSearch {
    const message = createBaseGeoSearch();
    message.Path = object.Path ?? "";
    message.GeoSpaceIntersect = (object.GeoSpaceIntersect !== undefined && object.GeoSpaceIntersect !== null)
      ? GeoSpaceIntersect.fromPartial(object.GeoSpaceIntersect)
      : undefined;
    message.GeoCircleSearch = (object.GeoCircleSearch !== undefined && object.GeoCircleSearch !== null)
      ? GeoCircleSearch.fromPartial(object.GeoCircleSearch)
      : undefined;
    message.GeoBoxSearch = (object.GeoBoxSearch !== undefined && object.GeoBoxSearch !== null)
      ? GeoBoxSearch.fromPartial(object.GeoBoxSearch)
      : undefined;
    return message;
  },
};

function createBaseGeoSpace(): GeoSpace {
  return { GeoPoint: undefined, GeoPolygon: undefined, GeoMultiPolygon: undefined };
}

export const GeoSpace = {
  encode(message: GeoSpace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.GeoPoint !== undefined) {
      GeoPoint.encode(message.GeoPoint, writer.uint32(8002).fork()).ldelim();
    }
    if (message.GeoPolygon !== undefined) {
      GeoPolygon.encode(message.GeoPolygon, writer.uint32(16002).fork()).ldelim();
    }
    if (message.GeoMultiPolygon !== undefined) {
      GeoMultiPolygon.encode(message.GeoMultiPolygon, writer.uint32(24002).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoSpace {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoSpace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          message.GeoPoint = GeoPoint.decode(reader, reader.uint32());
          break;
        case 2000:
          message.GeoPolygon = GeoPolygon.decode(reader, reader.uint32());
          break;
        case 3000:
          message.GeoMultiPolygon = GeoMultiPolygon.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoSpace {
    return {
      GeoPoint: isSet(object.GeoPoint) ? GeoPoint.fromJSON(object.GeoPoint) : undefined,
      GeoPolygon: isSet(object.GeoPolygon) ? GeoPolygon.fromJSON(object.GeoPolygon) : undefined,
      GeoMultiPolygon: isSet(object.GeoMultiPolygon) ? GeoMultiPolygon.fromJSON(object.GeoMultiPolygon) : undefined,
    };
  },

  toJSON(message: GeoSpace): unknown {
    const obj: any = {};
    message.GeoPoint !== undefined && (obj.GeoPoint = message.GeoPoint ? GeoPoint.toJSON(message.GeoPoint) : undefined);
    message.GeoPolygon !== undefined &&
      (obj.GeoPolygon = message.GeoPolygon ? GeoPolygon.toJSON(message.GeoPolygon) : undefined);
    message.GeoMultiPolygon !== undefined &&
      (obj.GeoMultiPolygon = message.GeoMultiPolygon ? GeoMultiPolygon.toJSON(message.GeoMultiPolygon) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoSpace>, I>>(object: I): GeoSpace {
    const message = createBaseGeoSpace();
    message.GeoPoint = (object.GeoPoint !== undefined && object.GeoPoint !== null)
      ? GeoPoint.fromPartial(object.GeoPoint)
      : undefined;
    message.GeoPolygon = (object.GeoPolygon !== undefined && object.GeoPolygon !== null)
      ? GeoPolygon.fromPartial(object.GeoPolygon)
      : undefined;
    message.GeoMultiPolygon = (object.GeoMultiPolygon !== undefined && object.GeoMultiPolygon !== null)
      ? GeoMultiPolygon.fromPartial(object.GeoMultiPolygon)
      : undefined;
    return message;
  },
};

function createBaseGeoSpaceIntersect(): GeoSpaceIntersect {
  return { Geometry: undefined };
}

export const GeoSpaceIntersect = {
  encode(message: GeoSpaceIntersect, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Geometry !== undefined) {
      GeoSpace.encode(message.Geometry, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoSpaceIntersect {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoSpaceIntersect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Geometry = GeoSpace.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoSpaceIntersect {
    return { Geometry: isSet(object.Geometry) ? GeoSpace.fromJSON(object.Geometry) : undefined };
  },

  toJSON(message: GeoSpaceIntersect): unknown {
    const obj: any = {};
    message.Geometry !== undefined && (obj.Geometry = message.Geometry ? GeoSpace.toJSON(message.Geometry) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoSpaceIntersect>, I>>(object: I): GeoSpaceIntersect {
    const message = createBaseGeoSpaceIntersect();
    message.Geometry = (object.Geometry !== undefined && object.Geometry !== null)
      ? GeoSpace.fromPartial(object.Geometry)
      : undefined;
    return message;
  },
};

function createBaseGetChargerSpaceDataRequest(): GetChargerSpaceDataRequest {
  return { SpaceIds: [] };
}

export const GetChargerSpaceDataRequest = {
  encode(message: GetChargerSpaceDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.SpaceIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetChargerSpaceDataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChargerSpaceDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SpaceIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetChargerSpaceDataRequest {
    return { SpaceIds: Array.isArray(object?.SpaceIds) ? object.SpaceIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: GetChargerSpaceDataRequest): unknown {
    const obj: any = {};
    if (message.SpaceIds) {
      obj.SpaceIds = message.SpaceIds.map((e) => e);
    } else {
      obj.SpaceIds = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetChargerSpaceDataRequest>, I>>(object: I): GetChargerSpaceDataRequest {
    const message = createBaseGetChargerSpaceDataRequest();
    message.SpaceIds = object.SpaceIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseInnerSearchRequest(): InnerSearchRequest {
  return { Path: "", Filter: undefined, SearchType: 0 };
}

export const InnerSearchRequest = {
  encode(message: InnerSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Path !== "") {
      writer.uint32(10).string(message.Path);
    }
    if (message.Filter !== undefined) {
      SearchFilter.encode(message.Filter, writer.uint32(18).fork()).ldelim();
    }
    if (message.SearchType !== 0) {
      writer.uint32(24).int32(message.SearchType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InnerSearchRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInnerSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Path = reader.string();
          break;
        case 2:
          message.Filter = SearchFilter.decode(reader, reader.uint32());
          break;
        case 3:
          message.SearchType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InnerSearchRequest {
    return {
      Path: isSet(object.Path) ? String(object.Path) : "",
      Filter: isSet(object.Filter) ? SearchFilter.fromJSON(object.Filter) : undefined,
      SearchType: isSet(object.SearchType) ? searchTypeFromJSON(object.SearchType) : 0,
    };
  },

  toJSON(message: InnerSearchRequest): unknown {
    const obj: any = {};
    message.Path !== undefined && (obj.Path = message.Path);
    message.Filter !== undefined && (obj.Filter = message.Filter ? SearchFilter.toJSON(message.Filter) : undefined);
    message.SearchType !== undefined && (obj.SearchType = searchTypeToJSON(message.SearchType));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InnerSearchRequest>, I>>(object: I): InnerSearchRequest {
    const message = createBaseInnerSearchRequest();
    message.Path = object.Path ?? "";
    message.Filter = (object.Filter !== undefined && object.Filter !== null)
      ? SearchFilter.fromPartial(object.Filter)
      : undefined;
    message.SearchType = object.SearchType ?? 0;
    return message;
  },
};

function createBaseLocale(): Locale {
  return { Culture: "" };
}

export const Locale = {
  encode(message: Locale, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Culture !== "") {
      writer.uint32(10).string(message.Culture);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Locale {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocale();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Culture = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Locale {
    return { Culture: isSet(object.Culture) ? String(object.Culture) : "" };
  },

  toJSON(message: Locale): unknown {
    const obj: any = {};
    message.Culture !== undefined && (obj.Culture = message.Culture);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Locale>, I>>(object: I): Locale {
    const message = createBaseLocale();
    message.Culture = object.Culture ?? "";
    return message;
  },
};

function createBaseObjectIdEntity(): ObjectIdEntity {
  return {};
}

export const ObjectIdEntity = {
  encode(_: ObjectIdEntity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectIdEntity {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectIdEntity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ObjectIdEntity {
    return {};
  },

  toJSON(_: ObjectIdEntity): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ObjectIdEntity>, I>>(_: I): ObjectIdEntity {
    const message = createBaseObjectIdEntity();
    return message;
  },
};

function createBaseOcpiProperties(): OcpiProperties {
  return { PowerType: 0, Power: "", ConnectorType: 0 };
}

export const OcpiProperties = {
  encode(message: OcpiProperties, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.PowerType !== 0) {
      writer.uint32(8).int32(message.PowerType);
    }
    if (message.Power !== "") {
      writer.uint32(18).string(message.Power);
    }
    if (message.ConnectorType !== 0) {
      writer.uint32(24).int32(message.ConnectorType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OcpiProperties {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOcpiProperties();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PowerType = reader.int32() as any;
          break;
        case 2:
          message.Power = reader.string();
          break;
        case 3:
          message.ConnectorType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OcpiProperties {
    return {
      PowerType: isSet(object.PowerType) ? powerTypeFromJSON(object.PowerType) : 0,
      Power: isSet(object.Power) ? String(object.Power) : "",
      ConnectorType: isSet(object.ConnectorType) ? connectorTypeFromJSON(object.ConnectorType) : 0,
    };
  },

  toJSON(message: OcpiProperties): unknown {
    const obj: any = {};
    message.PowerType !== undefined && (obj.PowerType = powerTypeToJSON(message.PowerType));
    message.Power !== undefined && (obj.Power = message.Power);
    message.ConnectorType !== undefined && (obj.ConnectorType = connectorTypeToJSON(message.ConnectorType));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OcpiProperties>, I>>(object: I): OcpiProperties {
    const message = createBaseOcpiProperties();
    message.PowerType = object.PowerType ?? 0;
    message.Power = object.Power ?? "";
    message.ConnectorType = object.ConnectorType ?? 0;
    return message;
  },
};

function createBaseSearchFilter(): SearchFilter {
  return {
    SearchNAry: undefined,
    SearchUnary: undefined,
    SearchLiteral: undefined,
    InnerSearchRequest: undefined,
    GeoSearch: undefined,
  };
}

export const SearchFilter = {
  encode(message: SearchFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.SearchNAry !== undefined) {
      SearchNAry.encode(message.SearchNAry, writer.uint32(8002).fork()).ldelim();
    }
    if (message.SearchUnary !== undefined) {
      SearchUnary.encode(message.SearchUnary, writer.uint32(16002).fork()).ldelim();
    }
    if (message.SearchLiteral !== undefined) {
      SearchLiteral.encode(message.SearchLiteral, writer.uint32(24002).fork()).ldelim();
    }
    if (message.InnerSearchRequest !== undefined) {
      InnerSearchRequest.encode(message.InnerSearchRequest, writer.uint32(32002).fork()).ldelim();
    }
    if (message.GeoSearch !== undefined) {
      GeoSearch.encode(message.GeoSearch, writer.uint32(40002).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchFilter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          message.SearchNAry = SearchNAry.decode(reader, reader.uint32());
          break;
        case 2000:
          message.SearchUnary = SearchUnary.decode(reader, reader.uint32());
          break;
        case 3000:
          message.SearchLiteral = SearchLiteral.decode(reader, reader.uint32());
          break;
        case 4000:
          message.InnerSearchRequest = InnerSearchRequest.decode(reader, reader.uint32());
          break;
        case 5000:
          message.GeoSearch = GeoSearch.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchFilter {
    return {
      SearchNAry: isSet(object.SearchNAry) ? SearchNAry.fromJSON(object.SearchNAry) : undefined,
      SearchUnary: isSet(object.SearchUnary) ? SearchUnary.fromJSON(object.SearchUnary) : undefined,
      SearchLiteral: isSet(object.SearchLiteral) ? SearchLiteral.fromJSON(object.SearchLiteral) : undefined,
      InnerSearchRequest: isSet(object.InnerSearchRequest)
        ? InnerSearchRequest.fromJSON(object.InnerSearchRequest)
        : undefined,
      GeoSearch: isSet(object.GeoSearch) ? GeoSearch.fromJSON(object.GeoSearch) : undefined,
    };
  },

  toJSON(message: SearchFilter): unknown {
    const obj: any = {};
    message.SearchNAry !== undefined &&
      (obj.SearchNAry = message.SearchNAry ? SearchNAry.toJSON(message.SearchNAry) : undefined);
    message.SearchUnary !== undefined &&
      (obj.SearchUnary = message.SearchUnary ? SearchUnary.toJSON(message.SearchUnary) : undefined);
    message.SearchLiteral !== undefined &&
      (obj.SearchLiteral = message.SearchLiteral ? SearchLiteral.toJSON(message.SearchLiteral) : undefined);
    message.InnerSearchRequest !== undefined && (obj.InnerSearchRequest = message.InnerSearchRequest
      ? InnerSearchRequest.toJSON(message.InnerSearchRequest)
      : undefined);
    message.GeoSearch !== undefined &&
      (obj.GeoSearch = message.GeoSearch ? GeoSearch.toJSON(message.GeoSearch) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchFilter>, I>>(object: I): SearchFilter {
    const message = createBaseSearchFilter();
    message.SearchNAry = (object.SearchNAry !== undefined && object.SearchNAry !== null)
      ? SearchNAry.fromPartial(object.SearchNAry)
      : undefined;
    message.SearchUnary = (object.SearchUnary !== undefined && object.SearchUnary !== null)
      ? SearchUnary.fromPartial(object.SearchUnary)
      : undefined;
    message.SearchLiteral = (object.SearchLiteral !== undefined && object.SearchLiteral !== null)
      ? SearchLiteral.fromPartial(object.SearchLiteral)
      : undefined;
    message.InnerSearchRequest = (object.InnerSearchRequest !== undefined && object.InnerSearchRequest !== null)
      ? InnerSearchRequest.fromPartial(object.InnerSearchRequest)
      : undefined;
    message.GeoSearch = (object.GeoSearch !== undefined && object.GeoSearch !== null)
      ? GeoSearch.fromPartial(object.GeoSearch)
      : undefined;
    return message;
  },
};

function createBaseSearchLiteral(): SearchLiteral {
  return { Operator: 0, Path: "", Value: undefined, ValuePosition: 0, IsIgnoreCase: false };
}

export const SearchLiteral = {
  encode(message: SearchLiteral, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Operator !== 0) {
      writer.uint32(8).int32(message.Operator);
    }
    if (message.Path !== "") {
      writer.uint32(18).string(message.Path);
    }
    if (message.Value !== undefined) {
      SearchValue.encode(message.Value, writer.uint32(26).fork()).ldelim();
    }
    if (message.ValuePosition !== 0) {
      writer.uint32(32).int32(message.ValuePosition);
    }
    if (message.IsIgnoreCase === true) {
      writer.uint32(48).bool(message.IsIgnoreCase);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchLiteral {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchLiteral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Operator = reader.int32() as any;
          break;
        case 2:
          message.Path = reader.string();
          break;
        case 3:
          message.Value = SearchValue.decode(reader, reader.uint32());
          break;
        case 4:
          message.ValuePosition = reader.int32() as any;
          break;
        case 6:
          message.IsIgnoreCase = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchLiteral {
    return {
      Operator: isSet(object.Operator) ? searchLiteralOperatorFromJSON(object.Operator) : 0,
      Path: isSet(object.Path) ? String(object.Path) : "",
      Value: isSet(object.Value) ? SearchValue.fromJSON(object.Value) : undefined,
      ValuePosition: isSet(object.ValuePosition) ? valuePositionFromJSON(object.ValuePosition) : 0,
      IsIgnoreCase: isSet(object.IsIgnoreCase) ? Boolean(object.IsIgnoreCase) : false,
    };
  },

  toJSON(message: SearchLiteral): unknown {
    const obj: any = {};
    message.Operator !== undefined && (obj.Operator = searchLiteralOperatorToJSON(message.Operator));
    message.Path !== undefined && (obj.Path = message.Path);
    message.Value !== undefined && (obj.Value = message.Value ? SearchValue.toJSON(message.Value) : undefined);
    message.ValuePosition !== undefined && (obj.ValuePosition = valuePositionToJSON(message.ValuePosition));
    message.IsIgnoreCase !== undefined && (obj.IsIgnoreCase = message.IsIgnoreCase);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchLiteral>, I>>(object: I): SearchLiteral {
    const message = createBaseSearchLiteral();
    message.Operator = object.Operator ?? 0;
    message.Path = object.Path ?? "";
    message.Value = (object.Value !== undefined && object.Value !== null)
      ? SearchValue.fromPartial(object.Value)
      : undefined;
    message.ValuePosition = object.ValuePosition ?? 0;
    message.IsIgnoreCase = object.IsIgnoreCase ?? false;
    return message;
  },
};

function createBaseSearchNAry(): SearchNAry {
  return { Operator: 0, Clauses: [] };
}

export const SearchNAry = {
  encode(message: SearchNAry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Operator !== 0) {
      writer.uint32(8).int32(message.Operator);
    }
    for (const v of message.Clauses) {
      SearchFilter.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchNAry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchNAry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Operator = reader.int32() as any;
          break;
        case 2:
          message.Clauses.push(SearchFilter.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchNAry {
    return {
      Operator: isSet(object.Operator) ? searchNAryOperatorFromJSON(object.Operator) : 0,
      Clauses: Array.isArray(object?.Clauses) ? object.Clauses.map((e: any) => SearchFilter.fromJSON(e)) : [],
    };
  },

  toJSON(message: SearchNAry): unknown {
    const obj: any = {};
    message.Operator !== undefined && (obj.Operator = searchNAryOperatorToJSON(message.Operator));
    if (message.Clauses) {
      obj.Clauses = message.Clauses.map((e) => e ? SearchFilter.toJSON(e) : undefined);
    } else {
      obj.Clauses = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchNAry>, I>>(object: I): SearchNAry {
    const message = createBaseSearchNAry();
    message.Operator = object.Operator ?? 0;
    message.Clauses = object.Clauses?.map((e) => SearchFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchOrderField(): SearchOrderField {
  return { Path: "", Direction: 0 };
}

export const SearchOrderField = {
  encode(message: SearchOrderField, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Path !== "") {
      writer.uint32(10).string(message.Path);
    }
    if (message.Direction !== 0) {
      writer.uint32(16).int32(message.Direction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchOrderField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchOrderField();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Path = reader.string();
          break;
        case 2:
          message.Direction = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchOrderField {
    return {
      Path: isSet(object.Path) ? String(object.Path) : "",
      Direction: isSet(object.Direction) ? searchOrderDirectionFromJSON(object.Direction) : 0,
    };
  },

  toJSON(message: SearchOrderField): unknown {
    const obj: any = {};
    message.Path !== undefined && (obj.Path = message.Path);
    message.Direction !== undefined && (obj.Direction = searchOrderDirectionToJSON(message.Direction));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchOrderField>, I>>(object: I): SearchOrderField {
    const message = createBaseSearchOrderField();
    message.Path = object.Path ?? "";
    message.Direction = object.Direction ?? 0;
    return message;
  },
};

function createBaseSearchRequest(): SearchRequest {
  return { Filter: undefined, Take: 0, OrderBy: [], Locale: undefined };
}

export const SearchRequest = {
  encode(message: SearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Filter !== undefined) {
      SearchFilter.encode(message.Filter, writer.uint32(10).fork()).ldelim();
    }
    if (message.Take !== 0) {
      writer.uint32(16).int32(message.Take);
    }
    for (const v of message.OrderBy) {
      SearchOrderField.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.Locale !== undefined) {
      Locale.encode(message.Locale, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Filter = SearchFilter.decode(reader, reader.uint32());
          break;
        case 2:
          message.Take = reader.int32();
          break;
        case 3:
          message.OrderBy.push(SearchOrderField.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Locale = Locale.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchRequest {
    return {
      Filter: isSet(object.Filter) ? SearchFilter.fromJSON(object.Filter) : undefined,
      Take: isSet(object.Take) ? Number(object.Take) : 0,
      OrderBy: Array.isArray(object?.OrderBy) ? object.OrderBy.map((e: any) => SearchOrderField.fromJSON(e)) : [],
      Locale: isSet(object.Locale) ? Locale.fromJSON(object.Locale) : undefined,
    };
  },

  toJSON(message: SearchRequest): unknown {
    const obj: any = {};
    message.Filter !== undefined && (obj.Filter = message.Filter ? SearchFilter.toJSON(message.Filter) : undefined);
    message.Take !== undefined && (obj.Take = Math.round(message.Take));
    if (message.OrderBy) {
      obj.OrderBy = message.OrderBy.map((e) => e ? SearchOrderField.toJSON(e) : undefined);
    } else {
      obj.OrderBy = [];
    }
    message.Locale !== undefined && (obj.Locale = message.Locale ? Locale.toJSON(message.Locale) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchRequest>, I>>(object: I): SearchRequest {
    const message = createBaseSearchRequest();
    message.Filter = (object.Filter !== undefined && object.Filter !== null)
      ? SearchFilter.fromPartial(object.Filter)
      : undefined;
    message.Take = object.Take ?? 0;
    message.OrderBy = object.OrderBy?.map((e) => SearchOrderField.fromPartial(e)) || [];
    message.Locale = (object.Locale !== undefined && object.Locale !== null)
      ? Locale.fromPartial(object.Locale)
      : undefined;
    return message;
  },
};

function createBaseSearchResponseCharger(): SearchResponseCharger {
  return { Items: [] };
}

export const SearchResponseCharger = {
  encode(message: SearchResponseCharger, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Items) {
      Charger.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchResponseCharger {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchResponseCharger();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Items.push(Charger.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchResponseCharger {
    return { Items: Array.isArray(object?.Items) ? object.Items.map((e: any) => Charger.fromJSON(e)) : [] };
  },

  toJSON(message: SearchResponseCharger): unknown {
    const obj: any = {};
    if (message.Items) {
      obj.Items = message.Items.map((e) => e ? Charger.toJSON(e) : undefined);
    } else {
      obj.Items = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchResponseCharger>, I>>(object: I): SearchResponseCharger {
    const message = createBaseSearchResponseCharger();
    message.Items = object.Items?.map((e) => Charger.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchUnary(): SearchUnary {
  return { Operator: 0, Clause: undefined };
}

export const SearchUnary = {
  encode(message: SearchUnary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Operator !== 0) {
      writer.uint32(8).int32(message.Operator);
    }
    if (message.Clause !== undefined) {
      SearchFilter.encode(message.Clause, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchUnary {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchUnary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Operator = reader.int32() as any;
          break;
        case 2:
          message.Clause = SearchFilter.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchUnary {
    return {
      Operator: isSet(object.Operator) ? searchUnaryOperatorFromJSON(object.Operator) : 0,
      Clause: isSet(object.Clause) ? SearchFilter.fromJSON(object.Clause) : undefined,
    };
  },

  toJSON(message: SearchUnary): unknown {
    const obj: any = {};
    message.Operator !== undefined && (obj.Operator = searchUnaryOperatorToJSON(message.Operator));
    message.Clause !== undefined && (obj.Clause = message.Clause ? SearchFilter.toJSON(message.Clause) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchUnary>, I>>(object: I): SearchUnary {
    const message = createBaseSearchUnary();
    message.Operator = object.Operator ?? 0;
    message.Clause = (object.Clause !== undefined && object.Clause !== null)
      ? SearchFilter.fromPartial(object.Clause)
      : undefined;
    return message;
  },
};

function createBaseSearchValue(): SearchValue {
  return { Value: new Uint8Array() };
}

export const SearchValue = {
  encode(message: SearchValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Value.length !== 0) {
      writer.uint32(10).bytes(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchValue {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchValue {
    return { Value: isSet(object.Value) ? bytesFromBase64(object.Value) : new Uint8Array() };
  },

  toJSON(message: SearchValue): unknown {
    const obj: any = {};
    message.Value !== undefined &&
      (obj.Value = base64FromBytes(message.Value !== undefined ? message.Value : new Uint8Array()));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SearchValue>, I>>(object: I): SearchValue {
    const message = createBaseSearchValue();
    message.Value = object.Value ?? new Uint8Array();
    return message;
  },
};

export interface ChargerService {
  AvailableChargerAmountBySpace(
    request: DeepPartial<AvailableChargerRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseInt32>;
  ControlCharger(request: DeepPartial<ChargerControlRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger>;
  CreateCharger(request: DeepPartial<ChargerCreateRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger>;
  CreateChargers(
    request: DeepPartial<ChargersCreateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseIListCharger>;
  DeleteAllChargers(request: DeepPartial<ChargersDeleteRequest>, metadata?: grpc.Metadata): Promise<EvResponseBoolean>;
  GetCharger(request: DeepPartial<ChargerRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger>;
  GetChargers(request: DeepPartial<ChargersRequest>, metadata?: grpc.Metadata): Promise<EvResponseIListCharger>;
  GetChargerSpaceData(
    request: DeepPartial<GetChargerSpaceDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargerSpaces>;
  Search(request: DeepPartial<SearchRequest>, metadata?: grpc.Metadata): Promise<SearchResponseCharger>;
  SyncCharger(request: DeepPartial<ChargerSyncRequest>, metadata?: grpc.Metadata): Promise<EvResponseString>;
  SyncChargers(request: DeepPartial<ChargersSyncRequest>, metadata?: grpc.Metadata): Promise<EvResponseString>;
}

export class ChargerServiceClientImpl implements ChargerService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AvailableChargerAmountBySpace = this.AvailableChargerAmountBySpace.bind(this);
    this.ControlCharger = this.ControlCharger.bind(this);
    this.CreateCharger = this.CreateCharger.bind(this);
    this.CreateChargers = this.CreateChargers.bind(this);
    this.DeleteAllChargers = this.DeleteAllChargers.bind(this);
    this.GetCharger = this.GetCharger.bind(this);
    this.GetChargers = this.GetChargers.bind(this);
    this.GetChargerSpaceData = this.GetChargerSpaceData.bind(this);
    this.Search = this.Search.bind(this);
    this.SyncCharger = this.SyncCharger.bind(this);
    this.SyncChargers = this.SyncChargers.bind(this);
  }

  AvailableChargerAmountBySpace(
    request: DeepPartial<AvailableChargerRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseInt32> {
    return this.rpc.unary(
      ChargerServiceAvailableChargerAmountBySpaceDesc,
      AvailableChargerRequest.fromPartial(request),
      metadata,
    );
  }

  ControlCharger(request: DeepPartial<ChargerControlRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger> {
    return this.rpc.unary(ChargerServiceControlChargerDesc, ChargerControlRequest.fromPartial(request), metadata);
  }

  CreateCharger(request: DeepPartial<ChargerCreateRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger> {
    return this.rpc.unary(ChargerServiceCreateChargerDesc, ChargerCreateRequest.fromPartial(request), metadata);
  }

  CreateChargers(
    request: DeepPartial<ChargersCreateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseIListCharger> {
    return this.rpc.unary(ChargerServiceCreateChargersDesc, ChargersCreateRequest.fromPartial(request), metadata);
  }

  DeleteAllChargers(request: DeepPartial<ChargersDeleteRequest>, metadata?: grpc.Metadata): Promise<EvResponseBoolean> {
    return this.rpc.unary(ChargerServiceDeleteAllChargersDesc, ChargersDeleteRequest.fromPartial(request), metadata);
  }

  GetCharger(request: DeepPartial<ChargerRequest>, metadata?: grpc.Metadata): Promise<EvResponseCharger> {
    return this.rpc.unary(ChargerServiceGetChargerDesc, ChargerRequest.fromPartial(request), metadata);
  }

  GetChargers(request: DeepPartial<ChargersRequest>, metadata?: grpc.Metadata): Promise<EvResponseIListCharger> {
    return this.rpc.unary(ChargerServiceGetChargersDesc, ChargersRequest.fromPartial(request), metadata);
  }

  GetChargerSpaceData(
    request: DeepPartial<GetChargerSpaceDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargerSpaces> {
    return this.rpc.unary(
      ChargerServiceGetChargerSpaceDataDesc,
      GetChargerSpaceDataRequest.fromPartial(request),
      metadata,
    );
  }

  Search(request: DeepPartial<SearchRequest>, metadata?: grpc.Metadata): Promise<SearchResponseCharger> {
    return this.rpc.unary(ChargerServiceSearchDesc, SearchRequest.fromPartial(request), metadata);
  }

  SyncCharger(request: DeepPartial<ChargerSyncRequest>, metadata?: grpc.Metadata): Promise<EvResponseString> {
    return this.rpc.unary(ChargerServiceSyncChargerDesc, ChargerSyncRequest.fromPartial(request), metadata);
  }

  SyncChargers(request: DeepPartial<ChargersSyncRequest>, metadata?: grpc.Metadata): Promise<EvResponseString> {
    return this.rpc.unary(ChargerServiceSyncChargersDesc, ChargersSyncRequest.fromPartial(request), metadata);
  }
}

export const ChargerServiceDesc = { serviceName: "EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerService" };

export const ChargerServiceAvailableChargerAmountBySpaceDesc: UnaryMethodDefinitionish = {
  methodName: "AvailableChargerAmountBySpace",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AvailableChargerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseInt32.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceControlChargerDesc: UnaryMethodDefinitionish = {
  methodName: "ControlCharger",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargerControlRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceCreateChargerDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCharger",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargerCreateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceCreateChargersDesc: UnaryMethodDefinitionish = {
  methodName: "CreateChargers",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargersCreateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseIListCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceDeleteAllChargersDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteAllChargers",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargersDeleteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseBoolean.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceGetChargerDesc: UnaryMethodDefinitionish = {
  methodName: "GetCharger",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceGetChargersDesc: UnaryMethodDefinitionish = {
  methodName: "GetChargers",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseIListCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceGetChargerSpaceDataDesc: UnaryMethodDefinitionish = {
  methodName: "GetChargerSpaceData",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetChargerSpaceDataRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseChargerSpaces.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceSearchDesc: UnaryMethodDefinitionish = {
  methodName: "Search",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SearchResponseCharger.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceSyncChargerDesc: UnaryMethodDefinitionish = {
  methodName: "SyncCharger",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargerSyncRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseString.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargerServiceSyncChargersDesc: UnaryMethodDefinitionish = {
  methodName: "SyncChargers",
  service: ChargerServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargersSyncRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseString.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type ProtoMetaMessageOptions = {
  options?: { [key: string]: any };
  fields?: { [key: string]: { [key: string]: any } };
  oneof?: { [key: string]: { [key: string]: any } };
  nested?: { [key: string]: ProtoMetaMessageOptions };
};

export interface ProtoMetadata {
  fileDescriptor: FileDescriptorProto;
  references: { [key: string]: any };
  dependencies?: ProtoMetadata[];
  options?: {
    options?: { [key: string]: any };
    services?: {
      [key: string]: { options?: { [key: string]: any }; methods?: { [key: string]: { [key: string]: any } } };
    };
    messages?: { [key: string]: ProtoMetaMessageOptions };
    enums?: { [key: string]: { options?: { [key: string]: any }; values?: { [key: string]: { [key: string]: any } } } };
  };
}

export const protoMetadata: ProtoMetadata = {
  fileDescriptor: FileDescriptorProto.fromPartial({
    "name": "evmng.mapi.IChargerService.proto",
    "package": "EvMng.Services.ManagementApi.Abstractions.Chargers",
    "dependency": ["google/protobuf/timestamp.proto"],
    "publicDependency": [],
    "weakDependency": [],
    "messageType": [{
      "name": "AuditInformation",
      "field": [{
        "name": "CreatedBy",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ObjectIdEntity",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "CreatedBy",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "CreatedOn",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "CreatedOn",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ModifiedBy",
        "number": 3,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ObjectIdEntity",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ModifiedBy",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ModifiedOn",
        "number": 4,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ModifiedOn",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "AvailableChargerRequest",
      "field": [{
        "name": "SpaceId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Charger",
      "field": [{
        "name": "Id",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Id",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalConnectorId",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalConnectorId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Status",
        "number": 4,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Status",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Status",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalSource",
        "number": 5,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvSource",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalSource",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalLocationId",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalLocationId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "DisplayName",
        "number": 7,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "DisplayName",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ImageUrls",
        "number": 8,
        "label": 3,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ImageUrls",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "LastStatusUpdate",
        "number": 9,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LastStatusUpdate",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceName",
        "number": 10,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceName",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceId",
        "number": 11,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Audit",
        "number": 12,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.AuditInformation",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Audit",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "LastSync",
        "number": 13,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LastSync",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "LastPowerDelivery",
        "number": 14,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LastPowerDelivery",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "PriceInfo",
        "number": 15,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvPriceInfo",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "PriceInfo",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "IsAvailable",
        "number": 16,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "IsAvailable",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "OcpiData",
        "number": 17,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.OcpiProperties",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "OcpiData",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerControlRequest",
      "field": [{
        "name": "ChargerId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ChargerCommand",
        "number": 2,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCommand",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerCommand",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerCreateDto",
      "field": [{
        "name": "ExternalId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalConnectorId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalConnectorId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceId",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceName",
        "number": 4,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceName",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "DisplayName",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "DisplayName",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerCreateRequest",
      "field": [{
        "name": "Source",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvSource",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Source",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalConnectorId",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalConnectorId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Status",
        "number": 4,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Status",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Status",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerRequest",
      "field": [{
        "name": "Source",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvSource",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Source",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExternalConnectorId",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExternalConnectorId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerSpace",
      "field": [{
        "name": "AmountOfAvailableChargers",
        "number": 1,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "AmountOfAvailableChargers",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerSpaces",
      "field": [{
        "name": "SpaceIdToChargerSpace",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpaces.SpaceIdToChargerSpaceEntry",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceIdToChargerSpace",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [{
        "name": "SpaceIdToChargerSpaceEntry",
        "field": [{
          "name": "key",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "key",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "value",
          "number": 2,
          "label": 1,
          "type": 11,
          "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpace",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "value",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": {
          "messageSetWireFormat": false,
          "noStandardDescriptorAccessor": false,
          "deprecated": false,
          "mapEntry": true,
          "uninterpretedOption": [],
        },
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargerSyncRequest",
      "field": [{
        "name": "ChargerId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargersCreateRequest",
      "field": [{
        "name": "ChargerCreateDtos",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCreateDto",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerCreateDtos",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargersDeleteRequest",
      "field": [],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargersRequest",
      "field": [{
        "name": "Version",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Version",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Ids",
        "number": 2,
        "label": 3,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Ids",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "OnlyPublished",
        "number": 3,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "OnlyPublished",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargersSyncRequest",
      "field": [],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Currency",
      "field": [],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvPriceInfo",
      "field": [{
        "name": "NetPricePerUnit",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "NetPricePerUnit",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GrossPricePerUnit",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GrossPricePerUnit",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Unit",
        "number": 3,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Unit",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Unit",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "TaxPercentagePerUnit",
        "number": 4,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "TaxPercentagePerUnit",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Currency",
        "number": 5,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Currency",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Currency",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "CountryCode",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "CountryCode",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_Boolean",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_Charger",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Charger",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_ChargerSpaces",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpaces",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_IList_Charger",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Charger",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_Int32",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_String",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoBoxSearch",
      "field": [{
        "name": "LowerLeft",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LowerLeft",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "UpperRight",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "UpperRight",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoCircleSearch",
      "field": [{
        "name": "Center",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Center",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Radius",
        "number": 2,
        "label": 1,
        "type": 1,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Radius",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoMultiPolygon",
      "field": [{
        "name": "Polygons",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Polygons",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoPoint",
      "field": [{
        "name": "Longitude",
        "number": 1,
        "label": 1,
        "type": 1,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Longitude",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Latitude",
        "number": 2,
        "label": 1,
        "type": 1,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Latitude",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoPolygon",
      "field": [{
        "name": "Coordinates",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Coordinates",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoSearch",
      "field": [{
        "name": "Path",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Path",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoSpaceIntersect",
        "number": 1000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpaceIntersect",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoSpaceIntersect",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoCircleSearch",
        "number": 1001,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoCircleSearch",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoCircleSearch",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoBoxSearch",
        "number": 1002,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoBoxSearch",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoBoxSearch",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "subtype", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoSpace",
      "field": [{
        "name": "GeoPoint",
        "number": 1000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoPoint",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoPoint",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoPolygon",
        "number": 2000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoPolygon",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoPolygon",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoMultiPolygon",
        "number": 3000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoMultiPolygon",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoMultiPolygon",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "subtype", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoSpaceIntersect",
      "field": [{
        "name": "Geometry",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Geometry",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GetChargerSpaceDataRequest",
      "field": [{
        "name": "SpaceIds",
        "number": 1,
        "label": 3,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceIds",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "InnerSearchRequest",
      "field": [{
        "name": "Path",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Path",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Filter",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchFilter",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Filter",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SearchType",
        "number": 3,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchType",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SearchType",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Locale",
      "field": [{
        "name": "Culture",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Culture",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ObjectIdEntity",
      "field": [],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "OcpiProperties",
      "field": [{
        "name": "PowerType",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.PowerType",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "PowerType",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Power",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Power",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ConnectorType",
        "number": 3,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ConnectorType",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ConnectorType",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchFilter",
      "field": [{
        "name": "SearchNAry",
        "number": 1000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchNAry",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SearchNAry",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SearchUnary",
        "number": 2000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchUnary",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SearchUnary",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SearchLiteral",
        "number": 3000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchLiteral",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SearchLiteral",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "InnerSearchRequest",
        "number": 4000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.InnerSearchRequest",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "InnerSearchRequest",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoSearch",
        "number": 5000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSearch",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoSearch",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "subtype", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchLiteral",
      "field": [{
        "name": "Operator",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchLiteralOperator",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Operator",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Path",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Path",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Value",
        "number": 3,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchValue",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Value",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ValuePosition",
        "number": 4,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ValuePosition",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ValuePosition",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "IsIgnoreCase",
        "number": 6,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "IsIgnoreCase",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [{ "start": 5, "end": 6 }],
      "reservedName": [],
    }, {
      "name": "SearchNAry",
      "field": [{
        "name": "Operator",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchNAryOperator",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Operator",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Clauses",
        "number": 2,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchFilter",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Clauses",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchOrderField",
      "field": [{
        "name": "Path",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Path",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Direction",
        "number": 2,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchOrderDirection",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Direction",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchRequest",
      "field": [{
        "name": "Filter",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchFilter",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Filter",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Take",
        "number": 2,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Take",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "OrderBy",
        "number": 3,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchOrderField",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "OrderBy",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Locale",
        "number": 4,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Locale",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Locale",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchResponse_Charger",
      "field": [{
        "name": "Items",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.Charger",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Items",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchUnary",
      "field": [{
        "name": "Operator",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchUnaryOperator",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Operator",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Clause",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchFilter",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Clause",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchValue",
      "field": [{
        "name": "Value",
        "number": 1,
        "label": 1,
        "type": 12,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Value",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "enumType": [{
      "name": "ChargerCommand",
      "value": [
        { "name": "UNKNOWN", "number": 0, "options": undefined },
        { "name": "RESTART", "number": 1, "options": undefined },
        { "name": "RESET", "number": 2, "options": undefined },
        { "name": "START", "number": 3, "options": undefined },
        { "name": "STOP", "number": 4, "options": undefined },
        { "name": "UNLOCK", "number": 5, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ConnectorType",
      "value": [
        { "name": "CHADEMO", "number": 0, "options": undefined },
        { "name": "DOMESTIC_A", "number": 1, "options": undefined },
        { "name": "DOMESTIC_B", "number": 2, "options": undefined },
        { "name": "DOMESTIC_C", "number": 3, "options": undefined },
        { "name": "DOMESTIC_D", "number": 4, "options": undefined },
        { "name": "DOMESTIC_E", "number": 5, "options": undefined },
        { "name": "DOMESTIC_F", "number": 6, "options": undefined },
        { "name": "DOMESTIC_G", "number": 7, "options": undefined },
        { "name": "DOMESTIC_H", "number": 8, "options": undefined },
        { "name": "DOMESTIC_I", "number": 9, "options": undefined },
        { "name": "DOMESTIC_J", "number": 10, "options": undefined },
        { "name": "DOMESTIC_K", "number": 11, "options": undefined },
        { "name": "DOMESTIC_L", "number": 12, "options": undefined },
        { "name": "IEC_60309_2_single_16", "number": 13, "options": undefined },
        { "name": "IEC_60309_2_three_16", "number": 14, "options": undefined },
        { "name": "IEC_60309_2_three_32", "number": 15, "options": undefined },
        { "name": "IEC_60309_2_three_64", "number": 16, "options": undefined },
        { "name": "IEC_62196_T1", "number": 17, "options": undefined },
        { "name": "IEC_62196_T1_COMBO", "number": 18, "options": undefined },
        { "name": "IEC_62196_T2", "number": 19, "options": undefined },
        { "name": "IEC_62196_T2_COMBO", "number": 20, "options": undefined },
        { "name": "IEC_62196_T3A", "number": 21, "options": undefined },
        { "name": "IEC_62196_T3C", "number": 22, "options": undefined },
        { "name": "TESLA_R", "number": 23, "options": undefined },
        { "name": "TESLA_S", "number": 24, "options": undefined },
        { "name": "PANTOGRAPH_BOTTOM_UP", "number": 25, "options": undefined },
        { "name": "PANTOGRAPH_TOP_DOWN", "number": 26, "options": undefined },
        { "name": "ConnectorType_UNKNOWN", "number": 27, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvSource",
      "value": [
        { "name": "DEFA", "number": 0, "options": undefined },
        { "name": "ZAPTEC", "number": 1, "options": undefined },
        { "name": "EASEE", "number": 2, "options": undefined },
        { "name": "SCHNEIDER", "number": 3, "options": undefined },
        { "name": "GREENFLUX", "number": 4, "options": undefined },
        { "name": "INTERNAL", "number": 5, "options": undefined },
        { "name": "TEST", "number": 6, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "PowerType",
      "value": [{ "name": "AC_1_PHASE", "number": 0, "options": undefined }, {
        "name": "AC_3_PHASE",
        "number": 1,
        "options": undefined,
      }, { "name": "DC", "number": 2, "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchLiteralOperator",
      "value": [
        { "name": "UnknownSearchLiteralOperator", "number": 0, "options": undefined },
        { "name": "Equal", "number": 1, "options": undefined },
        { "name": "NotEqual", "number": 2, "options": undefined },
        { "name": "LessThanOrEqual", "number": 3, "options": undefined },
        { "name": "LessThan", "number": 4, "options": undefined },
        { "name": "GreaterThanOrEqual", "number": 5, "options": undefined },
        { "name": "GreaterThan", "number": 6, "options": undefined },
        { "name": "Contains", "number": 7, "options": undefined },
        { "name": "StringContains", "number": 8, "options": undefined },
        { "name": "StartsWith", "number": 9, "options": undefined },
        { "name": "EndsWith", "number": 10, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchNAryOperator",
      "value": [{ "name": "UnknownSearchNAryOperator", "number": 0, "options": undefined }, {
        "name": "And",
        "number": 1,
        "options": undefined,
      }, { "name": "Or", "number": 2, "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchOrderDirection",
      "value": [{ "name": "UnknownSearchOrderDirection", "number": 0, "options": undefined }, {
        "name": "ASC",
        "number": 1,
        "options": undefined,
      }, { "name": "DESC", "number": 2, "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchType",
      "value": [
        { "name": "UnknownSearchType", "number": 0, "options": undefined },
        { "name": "Where", "number": 1, "options": undefined },
        { "name": "Any", "number": 2, "options": undefined },
        { "name": "All", "number": 3, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SearchUnaryOperator",
      "value": [{ "name": "UnknownSearchUnaryOperator", "number": 0, "options": undefined }, {
        "name": "Not",
        "number": 1,
        "options": undefined,
      }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Status",
      "value": [
        { "name": "Status_UNKNOWN", "number": 0, "options": undefined },
        { "name": "AVAILABLE", "number": 1, "options": undefined },
        { "name": "BLOCKED", "number": 2, "options": undefined },
        { "name": "CHARGING", "number": 3, "options": undefined },
        { "name": "INOPERATIVE", "number": 4, "options": undefined },
        { "name": "OUTOFORDER", "number": 5, "options": undefined },
        { "name": "PLANNED", "number": 6, "options": undefined },
        { "name": "REMOVED", "number": 7, "options": undefined },
        { "name": "RESERVED", "number": 8, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Unit",
      "value": [{ "name": "Kwh", "number": 0, "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ValuePosition",
      "value": [{ "name": "UnknownPosition", "number": 0, "options": undefined }, {
        "name": "Left",
        "number": 1,
        "options": undefined,
      }, { "name": "Right", "number": 2, "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "service": [{
      "name": "ChargerService",
      "method": [{
        "name": "AvailableChargerAmountBySpace",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.AvailableChargerRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_Int32",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "ControlCharger",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerControlRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "CreateCharger",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCreateRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "CreateChargers",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersCreateRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_IList_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "DeleteAllChargers",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersDeleteRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_Boolean",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetCharger",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetChargers",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_IList_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetChargerSpaceData",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.GetChargerSpaceDataRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_ChargerSpaces",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "Search",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchResponse_Charger",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "SyncCharger",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSyncRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_String",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "SyncChargers",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersSyncRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponse_String",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }],
      "options": undefined,
    }],
    "extension": [],
    "options": undefined,
    "sourceCodeInfo": {
      "location": [{
        "path": [4, 36, 9],
        "span": [254, 3, 14],
        "leadingComments": "",
        "trailingComments": " Was IsMatchCase ",
        "leadingDetachedComments": [],
      }],
    },
    "syntax": "proto3",
  }),
  references: {
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCommand": ChargerCommand,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ConnectorType": ConnectorType,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvSource": EvSource,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.PowerType": PowerType,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchLiteralOperator": SearchLiteralOperator,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchNAryOperator": SearchNAryOperator,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchOrderDirection": SearchOrderDirection,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchType": SearchType,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchUnaryOperator": SearchUnaryOperator,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.Status": Status,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.Unit": Unit,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ValuePosition": ValuePosition,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.AuditInformation": AuditInformation,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.AvailableChargerRequest": AvailableChargerRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.Charger": Charger,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerControlRequest": ChargerControlRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCreateDto": ChargerCreateDto,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerCreateRequest": ChargerCreateRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerRequest": ChargerRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpace": ChargerSpace,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpaces": ChargerSpaces,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSpaces.SpaceIdToChargerSpaceEntry":
      ChargerSpaces_SpaceIdToChargerSpaceEntry,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerSyncRequest": ChargerSyncRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersCreateRequest": ChargersCreateRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersDeleteRequest": ChargersDeleteRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersRequest": ChargersRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargersSyncRequest": ChargersSyncRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.Currency": Currency,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvPriceInfo": EvPriceInfo,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseBoolean": EvResponseBoolean,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseCharger": EvResponseCharger,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseChargerSpaces": EvResponseChargerSpaces,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseIListCharger": EvResponseIListCharger,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseInt32": EvResponseInt32,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.EvResponseString": EvResponseString,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoBoxSearch": GeoBoxSearch,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoCircleSearch": GeoCircleSearch,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoMultiPolygon": GeoMultiPolygon,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoPoint": GeoPoint,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoPolygon": GeoPolygon,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSearch": GeoSearch,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpace": GeoSpace,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GeoSpaceIntersect": GeoSpaceIntersect,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.GetChargerSpaceDataRequest": GetChargerSpaceDataRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.InnerSearchRequest": InnerSearchRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.Locale": Locale,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ObjectIdEntity": ObjectIdEntity,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.OcpiProperties": OcpiProperties,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchFilter": SearchFilter,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchLiteral": SearchLiteral,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchNAry": SearchNAry,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchOrderField": SearchOrderField,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchRequest": SearchRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchResponseCharger": SearchResponseCharger,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchUnary": SearchUnary,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.SearchValue": SearchValue,
    ".EvMng.Services.ManagementApi.Abstractions.Chargers.ChargerService": ChargerServiceClientImpl,
  },
  dependencies: [protoMetadata1],
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
