import { ImportanceLevel } from "../../types/importanceLevel";
import { Body4 } from "./typography";

export interface StatusIndicatorDisplayProps {
  status: ImportanceLevel;
  descriptor: string;
}

export function StatusIndicatorDisplay({
  status,
  descriptor,
}: StatusIndicatorDisplayProps) {
  let fullClass =
    "max-w-min whitespace-nowrap rounded-lg p-2 font-bold border h-6 flex items-center justify-center font-helveticaBold";
  if (status === ImportanceLevel.Emergency) {
    fullClass =
      fullClass + " bg-comicRed-200 text-comicRed-600 border-comicRed-600";
  } else if (status === ImportanceLevel.Warning) {
    fullClass =
      fullClass +
      " bg-honeyYellow-100 text-honeyYellow-750 border-honeyYellow-750";
  } else {
    fullClass =
      fullClass + " bg-diselDust-50 text-diselDust-600 border-diselDust-200";
  }
  return (
    <div className={fullClass}>
      <Body4>{descriptor}</Body4>
    </div>
  );
}
