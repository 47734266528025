import _ from "lodash";
import { FilterCategory, FilterDictionary, FilterOption } from "../types/filterTypes";

const timeFilterNames = ["Time"];

export const buildFiltersFromURL = (
  filters: FilterDictionary,
  searchParams: URLSearchParams,
  defaultFilters: FilterDictionary
) => {
  let possibleFilters = Object.keys(defaultFilters);
  let currentFilters = _.cloneDeep(filters);
  possibleFilters.forEach((possibleFilter) => {
    if (searchParams.getAll(possibleFilter).length) {
      let defaultFilter = defaultFilters[possibleFilter];
      let options = defaultFilter.options;
      searchParams.getAll(possibleFilter).forEach((param) => {
        if (!timeFilterNames.includes(possibleFilter))
        {
          options = {
          ...options,
          [param]: { ...options[param], selected: !options[param].selected },
        };
        }
        else {
          const unstringifiedParam:FilterOption = JSON.parse(param);
          const paramId = unstringifiedParam.id
          options = {
            ...options,
            [paramId]: { ...unstringifiedParam},
        }
      }
      });
      currentFilters = {
        ...currentFilters,
        [possibleFilter]: { ...defaultFilter, options },
      };
    }
  });
  return currentFilters;
};

const addQueryParamFromURL = (
  filterOption: FilterOption,
  searchParams: URLSearchParams
) => {
  const attribute = filterOption.path[0];
  searchParams.append(attribute, filterOption.id);
  return searchParams;
};

const addTimeQueryParamFromUrl = (
  filterOption: FilterOption,
  searchParams: URLSearchParams
) => {
  const attribute = filterOption.path[0];
  searchParams.append(attribute, JSON.stringify(filterOption));
  return searchParams;
};

const updateSearchParams = (
  filterOption: FilterOption,
  searchParams: URLSearchParams
) => {
  if (filterOption.selected) {
    if (filterOption.id.includes("Time")) {
      return addTimeQueryParamFromUrl(filterOption, searchParams);
    }
    return addQueryParamFromURL(filterOption, searchParams);
  }
  return searchParams;
};

const getCategorySearchParams = (
  filterCategory: FilterCategory,
  searchParams: URLSearchParams
) => {
  //loop through each category and update each category's options with updateSearchParams
  for (let option in filterCategory.options)
  {
    let filterOption = filterCategory.options[option];
    searchParams = updateSearchParams(filterOption, searchParams);
  }
  return searchParams;
};


export const updateDictionarySearchParams = (
  filterDictionary: FilterDictionary,
) => {
  let newSearchParams = new URLSearchParams();
  //loop through each category and update each category's options with updateSearchParams
  for (let category in filterDictionary)
  {
    newSearchParams = getCategorySearchParams(filterDictionary[category], newSearchParams);
  }
  return newSearchParams;
};
