/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";

export const protobufPackage = "EvMng.Services.ManagementApi.Abstractions.Spaces";

export enum DayOfWeek {
  UnknownDayOfWeek = 0,
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
  UNRECOGNIZED = -1,
}

export function dayOfWeekFromJSON(object: any): DayOfWeek {
  switch (object) {
    case 0:
    case "UnknownDayOfWeek":
      return DayOfWeek.UnknownDayOfWeek;
    case 1:
    case "Sunday":
      return DayOfWeek.Sunday;
    case 2:
    case "Monday":
      return DayOfWeek.Monday;
    case 3:
    case "Tuesday":
      return DayOfWeek.Tuesday;
    case 4:
    case "Wednesday":
      return DayOfWeek.Wednesday;
    case 5:
    case "Thursday":
      return DayOfWeek.Thursday;
    case 6:
    case "Friday":
      return DayOfWeek.Friday;
    case 7:
    case "Saturday":
      return DayOfWeek.Saturday;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DayOfWeek.UNRECOGNIZED;
  }
}

export function dayOfWeekToJSON(object: DayOfWeek): string {
  switch (object) {
    case DayOfWeek.UnknownDayOfWeek:
      return "UnknownDayOfWeek";
    case DayOfWeek.Sunday:
      return "Sunday";
    case DayOfWeek.Monday:
      return "Monday";
    case DayOfWeek.Tuesday:
      return "Tuesday";
    case DayOfWeek.Wednesday:
      return "Wednesday";
    case DayOfWeek.Thursday:
      return "Thursday";
    case DayOfWeek.Friday:
      return "Friday";
    case DayOfWeek.Saturday:
      return "Saturday";
    case DayOfWeek.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Address {
  DisplayValue: string;
  Country: string;
  CountryCode: string;
  Region: string;
  Locality: string;
  StreetAddress: string;
  ExtendedAddress: string;
  PostalCode: string;
  PoBox: string;
}

export interface ConsumableId {
  Value: string;
  Type: string;
}

export interface DateMessage {
  Day: number;
  Month: number;
  Year: number;
}

export interface Duration {
  Seconds: number;
}

export interface EvResponseIListSpace {
  timestamp: string;
  data: Space[];
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface GeoMultiPolygon {
  Polygons: GeoSpace[];
}

export interface GeoPoint {
  Longitude: number;
  Latitude: number;
}

export interface GeoPolygon {
  Coordinates: GeoSpace[];
}

export interface GeoSpace {
  GeoPoint: GeoPoint | undefined;
  GeoPolygon: GeoPolygon | undefined;
  GeoMultiPolygon: GeoMultiPolygon | undefined;
}

export interface Locale {
  Culture: string;
}

export interface ObjectId {
  Value: string;
  Type: string;
}

export interface OpeningHours {
  Ranges: OpeningHoursRange[];
}

export interface OpeningHoursRange {
  Open: OpeningHoursRangeDetails | undefined;
  Close: OpeningHoursRangeDetails | undefined;
}

export interface OpeningHoursRangeDetails {
  Day: DayOfWeek;
  Time: TimeOfDay | undefined;
  Date: DateMessage | undefined;
}

export interface Space {
  Id: SpaceId | undefined;
  Owner: ObjectId | undefined;
  Localization: SpaceLocalization | undefined;
  Location: GeoSpace | undefined;
  ParentSpaces: SpaceId[];
  Consumables: ConsumableId[];
  Name: string;
  Address: Address | undefined;
  OpeningHours: OpeningHours | undefined;
  BillingDelayAfterSessionEnds: Duration | undefined;
}

export interface SpaceId {
  Value: string;
}

export interface SpaceLocalization {
  Locale: Locale | undefined;
  Name: string;
  Description: string;
  ImageUris: string[];
}

export interface SpacesRequest {
  Version: string;
  Ids: SpaceId[];
}

export interface TimeOfDay {
  Seconds: number;
}

function createBaseAddress(): Address {
  return {
    DisplayValue: "",
    Country: "",
    CountryCode: "",
    Region: "",
    Locality: "",
    StreetAddress: "",
    ExtendedAddress: "",
    PostalCode: "",
    PoBox: "",
  };
}

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.DisplayValue !== "") {
      writer.uint32(10).string(message.DisplayValue);
    }
    if (message.Country !== "") {
      writer.uint32(18).string(message.Country);
    }
    if (message.CountryCode !== "") {
      writer.uint32(26).string(message.CountryCode);
    }
    if (message.Region !== "") {
      writer.uint32(34).string(message.Region);
    }
    if (message.Locality !== "") {
      writer.uint32(42).string(message.Locality);
    }
    if (message.StreetAddress !== "") {
      writer.uint32(50).string(message.StreetAddress);
    }
    if (message.ExtendedAddress !== "") {
      writer.uint32(58).string(message.ExtendedAddress);
    }
    if (message.PostalCode !== "") {
      writer.uint32(66).string(message.PostalCode);
    }
    if (message.PoBox !== "") {
      writer.uint32(74).string(message.PoBox);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DisplayValue = reader.string();
          break;
        case 2:
          message.Country = reader.string();
          break;
        case 3:
          message.CountryCode = reader.string();
          break;
        case 4:
          message.Region = reader.string();
          break;
        case 5:
          message.Locality = reader.string();
          break;
        case 6:
          message.StreetAddress = reader.string();
          break;
        case 7:
          message.ExtendedAddress = reader.string();
          break;
        case 8:
          message.PostalCode = reader.string();
          break;
        case 9:
          message.PoBox = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      DisplayValue: isSet(object.DisplayValue) ? String(object.DisplayValue) : "",
      Country: isSet(object.Country) ? String(object.Country) : "",
      CountryCode: isSet(object.CountryCode) ? String(object.CountryCode) : "",
      Region: isSet(object.Region) ? String(object.Region) : "",
      Locality: isSet(object.Locality) ? String(object.Locality) : "",
      StreetAddress: isSet(object.StreetAddress) ? String(object.StreetAddress) : "",
      ExtendedAddress: isSet(object.ExtendedAddress) ? String(object.ExtendedAddress) : "",
      PostalCode: isSet(object.PostalCode) ? String(object.PostalCode) : "",
      PoBox: isSet(object.PoBox) ? String(object.PoBox) : "",
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.DisplayValue !== undefined && (obj.DisplayValue = message.DisplayValue);
    message.Country !== undefined && (obj.Country = message.Country);
    message.CountryCode !== undefined && (obj.CountryCode = message.CountryCode);
    message.Region !== undefined && (obj.Region = message.Region);
    message.Locality !== undefined && (obj.Locality = message.Locality);
    message.StreetAddress !== undefined && (obj.StreetAddress = message.StreetAddress);
    message.ExtendedAddress !== undefined && (obj.ExtendedAddress = message.ExtendedAddress);
    message.PostalCode !== undefined && (obj.PostalCode = message.PostalCode);
    message.PoBox !== undefined && (obj.PoBox = message.PoBox);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.DisplayValue = object.DisplayValue ?? "";
    message.Country = object.Country ?? "";
    message.CountryCode = object.CountryCode ?? "";
    message.Region = object.Region ?? "";
    message.Locality = object.Locality ?? "";
    message.StreetAddress = object.StreetAddress ?? "";
    message.ExtendedAddress = object.ExtendedAddress ?? "";
    message.PostalCode = object.PostalCode ?? "";
    message.PoBox = object.PoBox ?? "";
    return message;
  },
};

function createBaseConsumableId(): ConsumableId {
  return { Value: "", Type: "" };
}

export const ConsumableId = {
  encode(message: ConsumableId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Value !== "") {
      writer.uint32(10).string(message.Value);
    }
    if (message.Type !== "") {
      writer.uint32(18).string(message.Type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumableId {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumableId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConsumableId {
    return {
      Value: isSet(object.Value) ? String(object.Value) : "",
      Type: isSet(object.Type) ? String(object.Type) : "",
    };
  },

  toJSON(message: ConsumableId): unknown {
    const obj: any = {};
    message.Value !== undefined && (obj.Value = message.Value);
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ConsumableId>, I>>(object: I): ConsumableId {
    const message = createBaseConsumableId();
    message.Value = object.Value ?? "";
    message.Type = object.Type ?? "";
    return message;
  },
};

function createBaseDateMessage(): DateMessage {
  return { Day: 0, Month: 0, Year: 0 };
}

export const DateMessage = {
  encode(message: DateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Day !== 0) {
      writer.uint32(8).int32(message.Day);
    }
    if (message.Month !== 0) {
      writer.uint32(16).int32(message.Month);
    }
    if (message.Year !== 0) {
      writer.uint32(24).int32(message.Year);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DateMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Day = reader.int32();
          break;
        case 2:
          message.Month = reader.int32();
          break;
        case 3:
          message.Year = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DateMessage {
    return {
      Day: isSet(object.Day) ? Number(object.Day) : 0,
      Month: isSet(object.Month) ? Number(object.Month) : 0,
      Year: isSet(object.Year) ? Number(object.Year) : 0,
    };
  },

  toJSON(message: DateMessage): unknown {
    const obj: any = {};
    message.Day !== undefined && (obj.Day = Math.round(message.Day));
    message.Month !== undefined && (obj.Month = Math.round(message.Month));
    message.Year !== undefined && (obj.Year = Math.round(message.Year));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DateMessage>, I>>(object: I): DateMessage {
    const message = createBaseDateMessage();
    message.Day = object.Day ?? 0;
    message.Month = object.Month ?? 0;
    message.Year = object.Year ?? 0;
    return message;
  },
};

function createBaseDuration(): Duration {
  return { Seconds: 0 };
}

export const Duration = {
  encode(message: Duration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Seconds !== 0) {
      writer.uint32(8).int64(message.Seconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Duration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDuration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Seconds = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Duration {
    return { Seconds: isSet(object.Seconds) ? Number(object.Seconds) : 0 };
  },

  toJSON(message: Duration): unknown {
    const obj: any = {};
    message.Seconds !== undefined && (obj.Seconds = Math.round(message.Seconds));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Duration>, I>>(object: I): Duration {
    const message = createBaseDuration();
    message.Seconds = object.Seconds ?? 0;
    return message;
  },
};

function createBaseEvResponseIListSpace(): EvResponseIListSpace {
  return { timestamp: "", data: [], statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseIListSpace = {
  encode(message: EvResponseIListSpace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    for (const v of message.data) {
      Space.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseIListSpace {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseIListSpace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data.push(Space.decode(reader, reader.uint32()));
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseIListSpace {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: Array.isArray(object?.data) ? object.data.map((e: any) => Space.fromJSON(e)) : [],
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseIListSpace): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    if (message.data) {
      obj.data = message.data.map((e) => e ? Space.toJSON(e) : undefined);
    } else {
      obj.data = [];
    }
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseIListSpace>, I>>(object: I): EvResponseIListSpace {
    const message = createBaseEvResponseIListSpace();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data?.map((e) => Space.fromPartial(e)) || [];
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseGeoMultiPolygon(): GeoMultiPolygon {
  return { Polygons: [] };
}

export const GeoMultiPolygon = {
  encode(message: GeoMultiPolygon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Polygons) {
      GeoSpace.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoMultiPolygon {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoMultiPolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Polygons.push(GeoSpace.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoMultiPolygon {
    return { Polygons: Array.isArray(object?.Polygons) ? object.Polygons.map((e: any) => GeoSpace.fromJSON(e)) : [] };
  },

  toJSON(message: GeoMultiPolygon): unknown {
    const obj: any = {};
    if (message.Polygons) {
      obj.Polygons = message.Polygons.map((e) => e ? GeoSpace.toJSON(e) : undefined);
    } else {
      obj.Polygons = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoMultiPolygon>, I>>(object: I): GeoMultiPolygon {
    const message = createBaseGeoMultiPolygon();
    message.Polygons = object.Polygons?.map((e) => GeoSpace.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeoPoint(): GeoPoint {
  return { Longitude: 0, Latitude: 0 };
}

export const GeoPoint = {
  encode(message: GeoPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Longitude !== 0) {
      writer.uint32(9).double(message.Longitude);
    }
    if (message.Latitude !== 0) {
      writer.uint32(17).double(message.Latitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoPoint {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Longitude = reader.double();
          break;
        case 2:
          message.Latitude = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoPoint {
    return {
      Longitude: isSet(object.Longitude) ? Number(object.Longitude) : 0,
      Latitude: isSet(object.Latitude) ? Number(object.Latitude) : 0,
    };
  },

  toJSON(message: GeoPoint): unknown {
    const obj: any = {};
    message.Longitude !== undefined && (obj.Longitude = message.Longitude);
    message.Latitude !== undefined && (obj.Latitude = message.Latitude);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoPoint>, I>>(object: I): GeoPoint {
    const message = createBaseGeoPoint();
    message.Longitude = object.Longitude ?? 0;
    message.Latitude = object.Latitude ?? 0;
    return message;
  },
};

function createBaseGeoPolygon(): GeoPolygon {
  return { Coordinates: [] };
}

export const GeoPolygon = {
  encode(message: GeoPolygon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Coordinates) {
      GeoSpace.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoPolygon {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoPolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Coordinates.push(GeoSpace.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoPolygon {
    return {
      Coordinates: Array.isArray(object?.Coordinates) ? object.Coordinates.map((e: any) => GeoSpace.fromJSON(e)) : [],
    };
  },

  toJSON(message: GeoPolygon): unknown {
    const obj: any = {};
    if (message.Coordinates) {
      obj.Coordinates = message.Coordinates.map((e) => e ? GeoSpace.toJSON(e) : undefined);
    } else {
      obj.Coordinates = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoPolygon>, I>>(object: I): GeoPolygon {
    const message = createBaseGeoPolygon();
    message.Coordinates = object.Coordinates?.map((e) => GeoSpace.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeoSpace(): GeoSpace {
  return { GeoPoint: undefined, GeoPolygon: undefined, GeoMultiPolygon: undefined };
}

export const GeoSpace = {
  encode(message: GeoSpace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.GeoPoint !== undefined) {
      GeoPoint.encode(message.GeoPoint, writer.uint32(8002).fork()).ldelim();
    }
    if (message.GeoPolygon !== undefined) {
      GeoPolygon.encode(message.GeoPolygon, writer.uint32(16002).fork()).ldelim();
    }
    if (message.GeoMultiPolygon !== undefined) {
      GeoMultiPolygon.encode(message.GeoMultiPolygon, writer.uint32(24002).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeoSpace {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeoSpace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          message.GeoPoint = GeoPoint.decode(reader, reader.uint32());
          break;
        case 2000:
          message.GeoPolygon = GeoPolygon.decode(reader, reader.uint32());
          break;
        case 3000:
          message.GeoMultiPolygon = GeoMultiPolygon.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeoSpace {
    return {
      GeoPoint: isSet(object.GeoPoint) ? GeoPoint.fromJSON(object.GeoPoint) : undefined,
      GeoPolygon: isSet(object.GeoPolygon) ? GeoPolygon.fromJSON(object.GeoPolygon) : undefined,
      GeoMultiPolygon: isSet(object.GeoMultiPolygon) ? GeoMultiPolygon.fromJSON(object.GeoMultiPolygon) : undefined,
    };
  },

  toJSON(message: GeoSpace): unknown {
    const obj: any = {};
    message.GeoPoint !== undefined && (obj.GeoPoint = message.GeoPoint ? GeoPoint.toJSON(message.GeoPoint) : undefined);
    message.GeoPolygon !== undefined &&
      (obj.GeoPolygon = message.GeoPolygon ? GeoPolygon.toJSON(message.GeoPolygon) : undefined);
    message.GeoMultiPolygon !== undefined &&
      (obj.GeoMultiPolygon = message.GeoMultiPolygon ? GeoMultiPolygon.toJSON(message.GeoMultiPolygon) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeoSpace>, I>>(object: I): GeoSpace {
    const message = createBaseGeoSpace();
    message.GeoPoint = (object.GeoPoint !== undefined && object.GeoPoint !== null)
      ? GeoPoint.fromPartial(object.GeoPoint)
      : undefined;
    message.GeoPolygon = (object.GeoPolygon !== undefined && object.GeoPolygon !== null)
      ? GeoPolygon.fromPartial(object.GeoPolygon)
      : undefined;
    message.GeoMultiPolygon = (object.GeoMultiPolygon !== undefined && object.GeoMultiPolygon !== null)
      ? GeoMultiPolygon.fromPartial(object.GeoMultiPolygon)
      : undefined;
    return message;
  },
};

function createBaseLocale(): Locale {
  return { Culture: "" };
}

export const Locale = {
  encode(message: Locale, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Culture !== "") {
      writer.uint32(10).string(message.Culture);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Locale {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocale();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Culture = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Locale {
    return { Culture: isSet(object.Culture) ? String(object.Culture) : "" };
  },

  toJSON(message: Locale): unknown {
    const obj: any = {};
    message.Culture !== undefined && (obj.Culture = message.Culture);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Locale>, I>>(object: I): Locale {
    const message = createBaseLocale();
    message.Culture = object.Culture ?? "";
    return message;
  },
};

function createBaseObjectId(): ObjectId {
  return { Value: "", Type: "" };
}

export const ObjectId = {
  encode(message: ObjectId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Value !== "") {
      writer.uint32(10).string(message.Value);
    }
    if (message.Type !== "") {
      writer.uint32(18).string(message.Type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectId {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ObjectId {
    return {
      Value: isSet(object.Value) ? String(object.Value) : "",
      Type: isSet(object.Type) ? String(object.Type) : "",
    };
  },

  toJSON(message: ObjectId): unknown {
    const obj: any = {};
    message.Value !== undefined && (obj.Value = message.Value);
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ObjectId>, I>>(object: I): ObjectId {
    const message = createBaseObjectId();
    message.Value = object.Value ?? "";
    message.Type = object.Type ?? "";
    return message;
  },
};

function createBaseOpeningHours(): OpeningHours {
  return { Ranges: [] };
}

export const OpeningHours = {
  encode(message: OpeningHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Ranges) {
      OpeningHoursRange.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpeningHours {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpeningHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Ranges.push(OpeningHoursRange.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OpeningHours {
    return {
      Ranges: Array.isArray(object?.Ranges) ? object.Ranges.map((e: any) => OpeningHoursRange.fromJSON(e)) : [],
    };
  },

  toJSON(message: OpeningHours): unknown {
    const obj: any = {};
    if (message.Ranges) {
      obj.Ranges = message.Ranges.map((e) => e ? OpeningHoursRange.toJSON(e) : undefined);
    } else {
      obj.Ranges = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OpeningHours>, I>>(object: I): OpeningHours {
    const message = createBaseOpeningHours();
    message.Ranges = object.Ranges?.map((e) => OpeningHoursRange.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOpeningHoursRange(): OpeningHoursRange {
  return { Open: undefined, Close: undefined };
}

export const OpeningHoursRange = {
  encode(message: OpeningHoursRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Open !== undefined) {
      OpeningHoursRangeDetails.encode(message.Open, writer.uint32(10).fork()).ldelim();
    }
    if (message.Close !== undefined) {
      OpeningHoursRangeDetails.encode(message.Close, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpeningHoursRange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpeningHoursRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Open = OpeningHoursRangeDetails.decode(reader, reader.uint32());
          break;
        case 2:
          message.Close = OpeningHoursRangeDetails.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OpeningHoursRange {
    return {
      Open: isSet(object.Open) ? OpeningHoursRangeDetails.fromJSON(object.Open) : undefined,
      Close: isSet(object.Close) ? OpeningHoursRangeDetails.fromJSON(object.Close) : undefined,
    };
  },

  toJSON(message: OpeningHoursRange): unknown {
    const obj: any = {};
    message.Open !== undefined && (obj.Open = message.Open ? OpeningHoursRangeDetails.toJSON(message.Open) : undefined);
    message.Close !== undefined &&
      (obj.Close = message.Close ? OpeningHoursRangeDetails.toJSON(message.Close) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OpeningHoursRange>, I>>(object: I): OpeningHoursRange {
    const message = createBaseOpeningHoursRange();
    message.Open = (object.Open !== undefined && object.Open !== null)
      ? OpeningHoursRangeDetails.fromPartial(object.Open)
      : undefined;
    message.Close = (object.Close !== undefined && object.Close !== null)
      ? OpeningHoursRangeDetails.fromPartial(object.Close)
      : undefined;
    return message;
  },
};

function createBaseOpeningHoursRangeDetails(): OpeningHoursRangeDetails {
  return { Day: 0, Time: undefined, Date: undefined };
}

export const OpeningHoursRangeDetails = {
  encode(message: OpeningHoursRangeDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Day !== 0) {
      writer.uint32(8).int32(message.Day);
    }
    if (message.Time !== undefined) {
      TimeOfDay.encode(message.Time, writer.uint32(18).fork()).ldelim();
    }
    if (message.Date !== undefined) {
      DateMessage.encode(message.Date, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpeningHoursRangeDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpeningHoursRangeDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Day = reader.int32() as any;
          break;
        case 2:
          message.Time = TimeOfDay.decode(reader, reader.uint32());
          break;
        case 3:
          message.Date = DateMessage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OpeningHoursRangeDetails {
    return {
      Day: isSet(object.Day) ? dayOfWeekFromJSON(object.Day) : 0,
      Time: isSet(object.Time) ? TimeOfDay.fromJSON(object.Time) : undefined,
      Date: isSet(object.Date) ? DateMessage.fromJSON(object.Date) : undefined,
    };
  },

  toJSON(message: OpeningHoursRangeDetails): unknown {
    const obj: any = {};
    message.Day !== undefined && (obj.Day = dayOfWeekToJSON(message.Day));
    message.Time !== undefined && (obj.Time = message.Time ? TimeOfDay.toJSON(message.Time) : undefined);
    message.Date !== undefined && (obj.Date = message.Date ? DateMessage.toJSON(message.Date) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OpeningHoursRangeDetails>, I>>(object: I): OpeningHoursRangeDetails {
    const message = createBaseOpeningHoursRangeDetails();
    message.Day = object.Day ?? 0;
    message.Time = (object.Time !== undefined && object.Time !== null) ? TimeOfDay.fromPartial(object.Time) : undefined;
    message.Date = (object.Date !== undefined && object.Date !== null)
      ? DateMessage.fromPartial(object.Date)
      : undefined;
    return message;
  },
};

function createBaseSpace(): Space {
  return {
    Id: undefined,
    Owner: undefined,
    Localization: undefined,
    Location: undefined,
    ParentSpaces: [],
    Consumables: [],
    Name: "",
    Address: undefined,
    OpeningHours: undefined,
    BillingDelayAfterSessionEnds: undefined,
  };
}

export const Space = {
  encode(message: Space, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Id !== undefined) {
      SpaceId.encode(message.Id, writer.uint32(10).fork()).ldelim();
    }
    if (message.Owner !== undefined) {
      ObjectId.encode(message.Owner, writer.uint32(18).fork()).ldelim();
    }
    if (message.Localization !== undefined) {
      SpaceLocalization.encode(message.Localization, writer.uint32(26).fork()).ldelim();
    }
    if (message.Location !== undefined) {
      GeoSpace.encode(message.Location, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.ParentSpaces) {
      SpaceId.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.Consumables) {
      ConsumableId.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.Name !== "") {
      writer.uint32(58).string(message.Name);
    }
    if (message.Address !== undefined) {
      Address.encode(message.Address, writer.uint32(66).fork()).ldelim();
    }
    if (message.OpeningHours !== undefined) {
      OpeningHours.encode(message.OpeningHours, writer.uint32(74).fork()).ldelim();
    }
    if (message.BillingDelayAfterSessionEnds !== undefined) {
      Duration.encode(message.BillingDelayAfterSessionEnds, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Space {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Id = SpaceId.decode(reader, reader.uint32());
          break;
        case 2:
          message.Owner = ObjectId.decode(reader, reader.uint32());
          break;
        case 3:
          message.Localization = SpaceLocalization.decode(reader, reader.uint32());
          break;
        case 4:
          message.Location = GeoSpace.decode(reader, reader.uint32());
          break;
        case 5:
          message.ParentSpaces.push(SpaceId.decode(reader, reader.uint32()));
          break;
        case 6:
          message.Consumables.push(ConsumableId.decode(reader, reader.uint32()));
          break;
        case 7:
          message.Name = reader.string();
          break;
        case 8:
          message.Address = Address.decode(reader, reader.uint32());
          break;
        case 9:
          message.OpeningHours = OpeningHours.decode(reader, reader.uint32());
          break;
        case 10:
          message.BillingDelayAfterSessionEnds = Duration.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Space {
    return {
      Id: isSet(object.Id) ? SpaceId.fromJSON(object.Id) : undefined,
      Owner: isSet(object.Owner) ? ObjectId.fromJSON(object.Owner) : undefined,
      Localization: isSet(object.Localization) ? SpaceLocalization.fromJSON(object.Localization) : undefined,
      Location: isSet(object.Location) ? GeoSpace.fromJSON(object.Location) : undefined,
      ParentSpaces: Array.isArray(object?.ParentSpaces) ? object.ParentSpaces.map((e: any) => SpaceId.fromJSON(e)) : [],
      Consumables: Array.isArray(object?.Consumables)
        ? object.Consumables.map((e: any) => ConsumableId.fromJSON(e))
        : [],
      Name: isSet(object.Name) ? String(object.Name) : "",
      Address: isSet(object.Address) ? Address.fromJSON(object.Address) : undefined,
      OpeningHours: isSet(object.OpeningHours) ? OpeningHours.fromJSON(object.OpeningHours) : undefined,
      BillingDelayAfterSessionEnds: isSet(object.BillingDelayAfterSessionEnds)
        ? Duration.fromJSON(object.BillingDelayAfterSessionEnds)
        : undefined,
    };
  },

  toJSON(message: Space): unknown {
    const obj: any = {};
    message.Id !== undefined && (obj.Id = message.Id ? SpaceId.toJSON(message.Id) : undefined);
    message.Owner !== undefined && (obj.Owner = message.Owner ? ObjectId.toJSON(message.Owner) : undefined);
    message.Localization !== undefined &&
      (obj.Localization = message.Localization ? SpaceLocalization.toJSON(message.Localization) : undefined);
    message.Location !== undefined && (obj.Location = message.Location ? GeoSpace.toJSON(message.Location) : undefined);
    if (message.ParentSpaces) {
      obj.ParentSpaces = message.ParentSpaces.map((e) => e ? SpaceId.toJSON(e) : undefined);
    } else {
      obj.ParentSpaces = [];
    }
    if (message.Consumables) {
      obj.Consumables = message.Consumables.map((e) => e ? ConsumableId.toJSON(e) : undefined);
    } else {
      obj.Consumables = [];
    }
    message.Name !== undefined && (obj.Name = message.Name);
    message.Address !== undefined && (obj.Address = message.Address ? Address.toJSON(message.Address) : undefined);
    message.OpeningHours !== undefined &&
      (obj.OpeningHours = message.OpeningHours ? OpeningHours.toJSON(message.OpeningHours) : undefined);
    message.BillingDelayAfterSessionEnds !== undefined &&
      (obj.BillingDelayAfterSessionEnds = message.BillingDelayAfterSessionEnds
        ? Duration.toJSON(message.BillingDelayAfterSessionEnds)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Space>, I>>(object: I): Space {
    const message = createBaseSpace();
    message.Id = (object.Id !== undefined && object.Id !== null) ? SpaceId.fromPartial(object.Id) : undefined;
    message.Owner = (object.Owner !== undefined && object.Owner !== null)
      ? ObjectId.fromPartial(object.Owner)
      : undefined;
    message.Localization = (object.Localization !== undefined && object.Localization !== null)
      ? SpaceLocalization.fromPartial(object.Localization)
      : undefined;
    message.Location = (object.Location !== undefined && object.Location !== null)
      ? GeoSpace.fromPartial(object.Location)
      : undefined;
    message.ParentSpaces = object.ParentSpaces?.map((e) => SpaceId.fromPartial(e)) || [];
    message.Consumables = object.Consumables?.map((e) => ConsumableId.fromPartial(e)) || [];
    message.Name = object.Name ?? "";
    message.Address = (object.Address !== undefined && object.Address !== null)
      ? Address.fromPartial(object.Address)
      : undefined;
    message.OpeningHours = (object.OpeningHours !== undefined && object.OpeningHours !== null)
      ? OpeningHours.fromPartial(object.OpeningHours)
      : undefined;
    message.BillingDelayAfterSessionEnds =
      (object.BillingDelayAfterSessionEnds !== undefined && object.BillingDelayAfterSessionEnds !== null)
        ? Duration.fromPartial(object.BillingDelayAfterSessionEnds)
        : undefined;
    return message;
  },
};

function createBaseSpaceId(): SpaceId {
  return { Value: "" };
}

export const SpaceId = {
  encode(message: SpaceId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Value !== "") {
      writer.uint32(10).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceId {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SpaceId {
    return { Value: isSet(object.Value) ? String(object.Value) : "" };
  },

  toJSON(message: SpaceId): unknown {
    const obj: any = {};
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SpaceId>, I>>(object: I): SpaceId {
    const message = createBaseSpaceId();
    message.Value = object.Value ?? "";
    return message;
  },
};

function createBaseSpaceLocalization(): SpaceLocalization {
  return { Locale: undefined, Name: "", Description: "", ImageUris: [] };
}

export const SpaceLocalization = {
  encode(message: SpaceLocalization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Locale !== undefined) {
      Locale.encode(message.Locale, writer.uint32(10).fork()).ldelim();
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    for (const v of message.ImageUris) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceLocalization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Locale = Locale.decode(reader, reader.uint32());
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.ImageUris.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SpaceLocalization {
    return {
      Locale: isSet(object.Locale) ? Locale.fromJSON(object.Locale) : undefined,
      Name: isSet(object.Name) ? String(object.Name) : "",
      Description: isSet(object.Description) ? String(object.Description) : "",
      ImageUris: Array.isArray(object?.ImageUris) ? object.ImageUris.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SpaceLocalization): unknown {
    const obj: any = {};
    message.Locale !== undefined && (obj.Locale = message.Locale ? Locale.toJSON(message.Locale) : undefined);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Description !== undefined && (obj.Description = message.Description);
    if (message.ImageUris) {
      obj.ImageUris = message.ImageUris.map((e) => e);
    } else {
      obj.ImageUris = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SpaceLocalization>, I>>(object: I): SpaceLocalization {
    const message = createBaseSpaceLocalization();
    message.Locale = (object.Locale !== undefined && object.Locale !== null)
      ? Locale.fromPartial(object.Locale)
      : undefined;
    message.Name = object.Name ?? "";
    message.Description = object.Description ?? "";
    message.ImageUris = object.ImageUris?.map((e) => e) || [];
    return message;
  },
};

function createBaseSpacesRequest(): SpacesRequest {
  return { Version: "", Ids: [] };
}

export const SpacesRequest = {
  encode(message: SpacesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Version !== "") {
      writer.uint32(10).string(message.Version);
    }
    for (const v of message.Ids) {
      SpaceId.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpacesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpacesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Version = reader.string();
          break;
        case 2:
          message.Ids.push(SpaceId.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SpacesRequest {
    return {
      Version: isSet(object.Version) ? String(object.Version) : "",
      Ids: Array.isArray(object?.Ids) ? object.Ids.map((e: any) => SpaceId.fromJSON(e)) : [],
    };
  },

  toJSON(message: SpacesRequest): unknown {
    const obj: any = {};
    message.Version !== undefined && (obj.Version = message.Version);
    if (message.Ids) {
      obj.Ids = message.Ids.map((e) => e ? SpaceId.toJSON(e) : undefined);
    } else {
      obj.Ids = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SpacesRequest>, I>>(object: I): SpacesRequest {
    const message = createBaseSpacesRequest();
    message.Version = object.Version ?? "";
    message.Ids = object.Ids?.map((e) => SpaceId.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTimeOfDay(): TimeOfDay {
  return { Seconds: 0 };
}

export const TimeOfDay = {
  encode(message: TimeOfDay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Seconds !== 0) {
      writer.uint32(8).int32(message.Seconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeOfDay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeOfDay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Seconds = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeOfDay {
    return { Seconds: isSet(object.Seconds) ? Number(object.Seconds) : 0 };
  },

  toJSON(message: TimeOfDay): unknown {
    const obj: any = {};
    message.Seconds !== undefined && (obj.Seconds = Math.round(message.Seconds));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TimeOfDay>, I>>(object: I): TimeOfDay {
    const message = createBaseTimeOfDay();
    message.Seconds = object.Seconds ?? 0;
    return message;
  },
};

export interface SpaceService {
  GetSpaces(request: DeepPartial<SpacesRequest>, metadata?: grpc.Metadata): Promise<EvResponseIListSpace>;
}

export class SpaceServiceClientImpl implements SpaceService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetSpaces = this.GetSpaces.bind(this);
  }

  GetSpaces(request: DeepPartial<SpacesRequest>, metadata?: grpc.Metadata): Promise<EvResponseIListSpace> {
    return this.rpc.unary(SpaceServiceGetSpacesDesc, SpacesRequest.fromPartial(request), metadata);
  }
}

export const SpaceServiceDesc = { serviceName: "EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceService" };

export const SpaceServiceGetSpacesDesc: UnaryMethodDefinitionish = {
  methodName: "GetSpaces",
  service: SpaceServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SpacesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseIListSpace.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type ProtoMetaMessageOptions = {
  options?: { [key: string]: any };
  fields?: { [key: string]: { [key: string]: any } };
  oneof?: { [key: string]: { [key: string]: any } };
  nested?: { [key: string]: ProtoMetaMessageOptions };
};

export interface ProtoMetadata {
  fileDescriptor: FileDescriptorProto;
  references: { [key: string]: any };
  dependencies?: ProtoMetadata[];
  options?: {
    options?: { [key: string]: any };
    services?: {
      [key: string]: { options?: { [key: string]: any }; methods?: { [key: string]: { [key: string]: any } } };
    };
    messages?: { [key: string]: ProtoMetaMessageOptions };
    enums?: { [key: string]: { options?: { [key: string]: any }; values?: { [key: string]: { [key: string]: any } } } };
  };
}

export const protoMetadata: ProtoMetadata = {
  fileDescriptor: FileDescriptorProto.fromPartial({
    "name": "evmng.mapi.ISpaceService.proto",
    "package": "EvMng.Services.ManagementApi.Abstractions.Spaces",
    "dependency": [],
    "publicDependency": [],
    "weakDependency": [],
    "messageType": [{
      "name": "Address",
      "field": [{
        "name": "DisplayValue",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "DisplayValue",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Country",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Country",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "CountryCode",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "CountryCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Region",
        "number": 4,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Region",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Locality",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Locality",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "StreetAddress",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "StreetAddress",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ExtendedAddress",
        "number": 7,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ExtendedAddress",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "PostalCode",
        "number": 8,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "PostalCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "PoBox",
        "number": 9,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "PoBox",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ConsumableId",
      "field": [{
        "name": "Value",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Value",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Type",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Type",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Date",
      "field": [{
        "name": "Day",
        "number": 1,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Day",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Month",
        "number": 2,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Month",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Year",
        "number": 3,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Year",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Duration",
      "field": [{
        "name": "Seconds",
        "number": 1,
        "label": 1,
        "type": 3,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Seconds",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_IList_Space",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.Space",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoMultiPolygon",
      "field": [{
        "name": "Polygons",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Polygons",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoPoint",
      "field": [{
        "name": "Longitude",
        "number": 1,
        "label": 1,
        "type": 1,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Longitude",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Latitude",
        "number": 2,
        "label": 1,
        "type": 1,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Latitude",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoPolygon",
      "field": [{
        "name": "Coordinates",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Coordinates",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GeoSpace",
      "field": [{
        "name": "GeoPoint",
        "number": 1000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoPoint",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoPoint",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoPolygon",
        "number": 2000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoPolygon",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoPolygon",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "GeoMultiPolygon",
        "number": 3000,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoMultiPolygon",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "GeoMultiPolygon",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "subtype", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Locale",
      "field": [{
        "name": "Culture",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Culture",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ObjectId",
      "field": [{
        "name": "Value",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Value",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Type",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Type",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "OpeningHours",
      "field": [{
        "name": "Ranges",
        "number": 1,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHoursRange",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Ranges",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "OpeningHoursRange",
      "field": [{
        "name": "Open",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHoursRangeDetails",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Open",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Close",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHoursRangeDetails",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Close",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "OpeningHoursRangeDetails",
      "field": [{
        "name": "Day",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.DayOfWeek",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Day",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Time",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.TimeOfDay",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Time",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Date",
        "number": 3,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.Date",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Date",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Space",
      "field": [{
        "name": "Id",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceId",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Id",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Owner",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.ObjectId",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Owner",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Localization",
        "number": 3,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceLocalization",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Localization",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Location",
        "number": 4,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoSpace",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Location",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ParentSpaces",
        "number": 5,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceId",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ParentSpaces",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Consumables",
        "number": 6,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.ConsumableId",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Consumables",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Name",
        "number": 7,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Name",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Address",
        "number": 8,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.Address",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Address",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "OpeningHours",
        "number": 9,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHours",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "OpeningHours",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "BillingDelayAfterSessionEnds",
        "number": 10,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.Duration",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "BillingDelayAfterSessionEnds",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SpaceId",
      "field": [{
        "name": "Value",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Value",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SpaceLocalization",
      "field": [{
        "name": "Locale",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.Locale",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Locale",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Name",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Name",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Description",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Description",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ImageUris",
        "number": 4,
        "label": 3,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ImageUris",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SpacesRequest",
      "field": [{
        "name": "Version",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Version",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Ids",
        "number": 2,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceId",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Ids",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "TimeOfDay",
      "field": [{
        "name": "Seconds",
        "number": 1,
        "label": 1,
        "type": 5,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Seconds",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "enumType": [{
      "name": "DayOfWeek",
      "value": [
        { "name": "UnknownDayOfWeek", "number": 0, "options": undefined },
        { "name": "Sunday", "number": 1, "options": undefined },
        { "name": "Monday", "number": 2, "options": undefined },
        { "name": "Tuesday", "number": 3, "options": undefined },
        { "name": "Wednesday", "number": 4, "options": undefined },
        { "name": "Thursday", "number": 5, "options": undefined },
        { "name": "Friday", "number": 6, "options": undefined },
        { "name": "Saturday", "number": 7, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "service": [{
      "name": "SpaceService",
      "method": [{
        "name": "GetSpaces",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpacesRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.Spaces.EvResponse_IList_Space",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }],
      "options": undefined,
    }],
    "extension": [],
    "options": undefined,
    "sourceCodeInfo": { "location": [] },
    "syntax": "proto3",
  }),
  references: {
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.DayOfWeek": DayOfWeek,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.Address": Address,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.ConsumableId": ConsumableId,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.DateMessage": DateMessage,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.Duration": Duration,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.EvResponseIListSpace": EvResponseIListSpace,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoMultiPolygon": GeoMultiPolygon,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoPoint": GeoPoint,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoPolygon": GeoPolygon,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.GeoSpace": GeoSpace,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.Locale": Locale,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.ObjectId": ObjectId,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHours": OpeningHours,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHoursRange": OpeningHoursRange,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.OpeningHoursRangeDetails": OpeningHoursRangeDetails,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.Space": Space,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceId": SpaceId,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceLocalization": SpaceLocalization,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpacesRequest": SpacesRequest,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.TimeOfDay": TimeOfDay,
    ".EvMng.Services.ManagementApi.Abstractions.Spaces.SpaceService": SpaceServiceClientImpl,
  },
  dependencies: [],
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
