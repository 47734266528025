import { atom, selector } from "recoil";
import { getSpaces } from "../api/spaceClient";
import { Space } from "../generated/clients/mapi/ispaceservice/evmng.mapi.ISpaceService";
import { authAtom } from "./authAtom";
import itemsjs from "itemsjs";

export const allSpacesState = selector<Space[]>({
  key: "allSpacesState",
  get: async ({ get }) => {
    try {
      const userData = get(authAtom);
      if (!userData) {
        console.log("allSpacesState -> No userdata, returning empty list");
        return []; //TODO: maybe something else in the future, if we find more elaborate ways of lazy atom'ing
      }
      const response = await getSpaces(userData?.access_token);
      return response || [];
    } catch (error) {
      console.error(`allSpacesState -> getSpaces() ERROR: \n${error}`);
      return [];
    }
  },
});

// creating the state value
export const allSpaces = atom({
  key: "allSpaces",
  default: allSpacesState,
});

export const selectedSpaceId = atom<string | undefined>({
  key: "selectedSpaceId",
  default: "AllChargers",
});

export const selectedSpace = selector<Space | undefined>({
  key: "selectedSpace",
  get: ({ get }) => {
    const selectedId = get(selectedSpaceId);
    const spaces = get(allSpaces);
    return spaces.find((space) => space.Id?.Value === selectedId);
  },
});

export const spaceSearchAtom = atom({
  key: "spaceSearchAtom",
  default: "",
});

export const filteredSpacesSelector = selector<Space[]>({
  key: "filteredSpaces",
  get: ({ get }) => {
    const spaces = get(allSpaces);
    const searchQuery = get(spaceSearchAtom);
    // Create itemsjs instance
    const items = spaces.map((space) => ({
      id: space.Id,
      ...space,
    }));
    const itemsjsInstance = itemsjs(items, {
      searchableFields: ["Name", "Address"],
    });

    // Perform search
    const searchResults = itemsjsInstance.search({
      query: searchQuery,
    });
    // Return filtered spaces
    if (searchQuery === "") {
      return spaces; // Show all spaces if search query is empty
    } else {
      return searchResults.data.items;
    }
  },
});
