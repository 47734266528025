import {
  AlertTriangleIcon,
  ArrowIcon,
  BellIcon,
  CalendarIcon,
  CheckIcon,
  ChevronIcon,
  ClockIcon,
  CoinIcon,
  FilterIcon,
  LightningIcon,
  MapPinFlatIcon,
  MapPinPointIcon,
  PlugIcon,
  RefreshIcon,
  SearchIcon,
  SortIcon,
  StopwatchIcon,
} from "./icons";

interface GenericIconProps {
  className?: string;
  iconName: IconType;
  isCollapsed?: boolean;
}

export const GenericIcon: React.FC<GenericIconProps> = ({
  //TODO Maybe this could be a dictionary somehow?
  className,
  iconName,
  isCollapsed,
}) => {
  switch (iconName) {
    case "Calendar":
      return <CalendarIcon className={className} />;
    case "Coin":
      return <CoinIcon className={className} />;
    case "Lightning":
      return <LightningIcon className={className} />;
    case "MapPinFlat":
      return <MapPinFlatIcon className={className} />;
    case "MapPinPoint":
      return <MapPinPointIcon className={className} />;
    case "Clock":
      return <ClockIcon className={className} />;
    case "Stopwatch":
      return <StopwatchIcon className={className} />;
    case "Plug":
      return <PlugIcon className={className} />;
    case "Check":
      return <CheckIcon className={className} />;
    case "Chevron":
      return <ChevronIcon className={className} isCollapsed={isCollapsed} />;
    case "Refresh":
      return <RefreshIcon className={className} />;
    case "Bell":
      return <BellIcon className={className} />;
    case "Sort":
      return <SortIcon className={className} />;
    case "Arrow":
      return <ArrowIcon className={className} />;
    case "Filter":
      return <FilterIcon className={className} />;
    case "Search":
      return <SearchIcon className={className} />;
    case "AlertTriangle":
      return <AlertTriangleIcon className={className} />;
    default: {
      console.log("Error, name supplied does not match existing Icons"); //TODO: Remove console log, change it to error?
      return null;
    }
  }
};

export enum IconType {
  Calendar = "Calendar",
  Coin = "Coin",
  Lightning = "Lightning",
  MapPinFlat = "MapPinFlat",
  MapPinPoint = "MapPinPoint",
  Clock = "Clock",
  Stopwatch = "Stopwatch",
  Plug = "Plug",
  Check = "Check",
  Chevron = "Chevron",
  Refresh = "Refresh",
  Bell = "Bell",
  Sort = "Sort",
  Filter = "Filter",
  Arrow = "Arrow",
  Search = "Search",
  AlertTriangle = "AlertTriangle",
}
