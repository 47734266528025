import { H4 } from "../generic/typography";
import {
  FilterCategory,
  FilterDictionary,
  ValidFilterId,
  ValidSessionFilterId,
} from "../../types/filterTypes";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { DatePickerFilterCategory } from "../generic/filterComponents/datepicker";
import { Filter } from "../generic/filterComponents/filterWrapper";
import { CheckboxFilterGrid } from "../generic/filterComponents/checkboxFilterGrid";
import { sessionsIndexSelector } from "../../atoms/sessionsAtom";
import { useIntl } from "react-intl";
interface FiltersProps {
  title: string;
  open: boolean;
  closePanel: Function;
  localFilterSetting: FilterDictionary;
  setLocalFilterSetting: SetterOrUpdater<FilterDictionary>;
}

export function SessionFilters(props: FiltersProps) {
  const indexedData = useRecoilValue(sessionsIndexSelector({}));
  const intl = useIntl();
  function setFilterByKey(
    key: string,
    newValue: FilterCategory,
  ): void {
    const newSetting = { ...props.localFilterSetting, [key]: newValue };
    props.setLocalFilterSetting(newSetting);
  }

  function getAggregationData(filterName: ValidFilterId) {
    return indexedData.aggregation({
      name: filterName as ValidSessionFilterId,
    });
  }

  return (
    <div className="flex flex-col pt-20 overflow-scroll w-full">
      <div>
        <div className="pb-9 mb-9 border-b border-diselDust">
          <H4 className="font-normal">{props.title}</H4>
        </div>
        <Filter title={intl.formatMessage({id:props.localFilterSetting.Time.displayTitle})}>
          <DatePickerFilterCategory
            filterCategory={props.localFilterSetting.Time}
            setState={setFilterByKey}
            getAggregationData={getAggregationData}
          />
        </Filter>
        <Filter
          title={intl.formatMessage({id:props.localFilterSetting.ChargingSessionState.displayTitle})}
        >
          <CheckboxFilterGrid
            filterCategory={props.localFilterSetting.ChargingSessionState}
            setState={setFilterByKey}
            getAggregationData={getAggregationData}
          />
        </Filter>
      </div>
    </div>
  );
}
