import { BrowserHeaders } from "browser-headers";
import {
  ChargingSession,
  ChargingSessionsServiceClientImpl,
  GrpcWebImpl,
} from "../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import { getApiUrl } from "../utils/config";

export interface ChargingSessionFilter {
  ChargerId?: string | undefined;
  SpaceId?: string | undefined;
  Active?: boolean | undefined;
  Self?: boolean | undefined;
}

function initClient(token: string) {
  return new ChargingSessionsServiceClientImpl(
    new GrpcWebImpl(getApiUrl(), {
      debug: false,
      metadata: new BrowserHeaders({ Authorization: "Bearer " + token }),
    })
  );
}

export async function startSessionByChargerId(
  token: string | undefined,
  chargerId: string
) {
  if (!token) {
    console.error("startSessionByChargerId()-> No auth token found");
    return;
  }
  try {
    const sessionsService = initClient(token);
    const response = await sessionsService.CreateChargingSession({
      ChargerId: chargerId,
    });
    return response;
  } catch (err) {
    console.error("startSessionByChargerId() => ", err);
    return;
  }
}

export async function getChargingSessions(
  token: string | undefined,
  filters: ChargingSessionFilter
) {
  if (!token) {
    console.error("getChargingSessions()-> No auth token found");
    return;
  }
  try {
    const sessionsService = initClient(token);
    const response = await sessionsService.GetChargingSessions(filters);
    return response.data.length > 0 ?  response.data.map((session) => ChargingSession.fromJSON(session)) : [];
  } catch (err) {
    console.error("getChargingSessions() => ", err);
    return;
  }
}

export async function getSelectedChargerState(
  token: string | undefined,
  sessionId?: string
) {
  if (!token) {
    console.error("getSelectedChargerState()-> No auth token found");
    return;
  }
  try {
    const sessionsService = initClient(token);
    return await sessionsService.GetChargingSessionState({
      ChargingSessionId: sessionId,
    });
  } catch (err) {
    console.error("getSelectedChargerState() => ", err);
    return;
  }
}

export async function stopSessionBySessionId(
  token: string | undefined,
  sessionId: string
) {
  try {
    if (!token) {
      console.error("stopSessionByChargerId()-> No auth token found");
      return;
    }
    const sessionsService = initClient(token);
    const response = await sessionsService.StopChargingSession(
      {
        ChargingSessionId: sessionId,
      }, //empty interface type on purpose??
      new BrowserHeaders({ Authentication: "Bearer " + token })
    );
    return response;
  } catch (error) {
    console.error("stopSessionByChargerId() -> error", error);
    return;
  }
}
