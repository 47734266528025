import { Dispatch, SetStateAction } from "react";
import { IconInfoDisplay } from "../generic/iconInfo";
import { IconType } from "../generic/genericIcon";
import { ClockIcon, ArrowIcon } from "../generic/icons";
import { StatusIndicatorDisplay } from "../generic/statusIndicatorDisplay";
import { Body5, Body2 } from "../generic/typography";
import { ImportanceLevel } from "../../types/importanceLevel";
import { FromUntilRowItem } from "../generic/fromUntilRowItem";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Link } from "react-router-dom";
import { ChargingSession } from "../../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import { getChargerPictureBySource } from "../../types/charger";
import { EvSource } from "../../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { IntlShape, useIntl } from 'react-intl';
import { i18n } from "../../i18n/langType";

dayjs.extend(duration);

interface SessionEntryProps {
  session: ChargingSession;
  short?: boolean;
  setSelectedSession: Dispatch<SetStateAction<ChargingSession | undefined>>;
}

export function SessionEntry(props: SessionEntryProps) {
  const { session, short } = props;
  const intl = useIntl();

  function selectSession() {
    props.setSelectedSession(session);
  }
  let gridClasses =
    "grid gap-2 items-center bg-white px-5 py-4 divide-x border border-diselDust-100";
  if (short) {
    gridClasses += " grid-cols-6";
  } else {
    gridClasses += " grid-cols-8";
  }
  return (
    <div className={gridClasses} onClick={selectSession}>
      <Link to={`/chargers/${session.ChargerId}`}>
        <div className="col-span-1 flex flex-row items-center gap-2">
          <img
            className="h-8"
            src={
              getChargerPictureBySource(
                EvSource.UNRECOGNIZED
              ) /*TODO: Get source so we know how to */
            }
          />
          <div className="truncate">
            <Body5>{session.ChargerDisplayName || intl.formatMessage({id:i18n.displayName})}</Body5>
            <Body2 className="font-bold truncate">{session.Id}</Body2>
          </div>
        </div>
      </Link>
      <div className="col-span-1 flex flex-col px-2 truncate">
        <Body5>{intl.formatMessage({id:i18n.userName})}</Body5>
        <Body2 className="font-bold truncate">{session.Id}</Body2>
      </div>
      <div className="col-span-1 flex justify-center">
        <IconInfoDisplay
          topText={"placeName"} //TODO: dont remember what this is, placeholder?
          bottomText={
            session.Start ?
            intl.formatDate(session.Start, {year:'numeric', month:'numeric', day:'numeric'}) 
            : intl.formatMessage({id:i18n.unknownDate})
          }
          topIconName={IconType.MapPinPoint}
          bottomIconName={IconType.Calendar}
          iconFillColor={"diselDust"}
        />
      </div>
      <div className="col-span-2 flex flex-row gap-4 items-center justify-center">
        <ClockIcon className="h-4 w-4 fill-diselDust" />
        <FromUntilRowItem startTime={session.Start} endTime={session.End} />
        <IconInfoDisplay
          topIconName={IconType.Stopwatch}
          topText={calculateTime(session, intl)}
          iconFillColor={"diselDust"}
        />
      </div>
      {!short && (
        <div className="col-span-1 justify-center">
          <IconInfoDisplay
            topIconName={IconType.Lightning}
            topText={`${session.Kwh.toFixed(3)} kWh`}
            iconFillColor={"diselDust"}
          />
        </div>
      )}
      {!short && (
        <div className="col-span-1 justify-center">
          <IconInfoDisplay
            topIconName={IconType.Coin}
            topText={
              intl.formatNumber(0, {style:"currency", currency:"EUR"}) //todo: Fix currency setting
            }
            iconFillColor={"diselDust"}
          />
        </div>
      )}
      <div className="col-span-1 flex justify-center gap-4">
        <ArrowIcon className={"h-5 w-5"} />
      </div>
    </div>
  );
}

function calculateTime(session: ChargingSession, intl:IntlShape): string {
  if (session.End && session.Start) {
    const minDiff = dayjs(session.End).diff(dayjs(session.Start), "minute");
    const hoursDiff = dayjs(session.End).diff(dayjs(session.Start), "hour");
    const diff = dayjs
      .duration({ hours: hoursDiff, minutes: minDiff })
      .format("HH:mm"); //TODO: Format datetime in a way we want
    return `${diff} ${intl.formatMessage({id:i18n.hours})}`;
  }
  return intl.formatMessage({id:i18n.ongoingSession});
}
