import { atom, selector } from "recoil";
import {
  FilterDictionary,
  SortSetting,
  SortingSettings,
  ValidChargerFilterId,
  ValidSortId,
} from "../types/filterTypes";
import * as itemsjs from "itemsjs";
import { chargerFiltersAtom, chargerSearchAtom } from "./filterAtoms";
import _ from "lodash";
import { chargerSortingAtom } from "./chargerSortingAtom";
import { selectedSpace } from "./spaceAtom";
import { getChargerById, getChargersForSpace } from "../api/chargerClient";
import { authAtom } from "./authAtom";
import { Charger } from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";

export const selectedChargerSelector = selector({
  key: "selectedChargerDetails",
  get: async ({ get }) => {
    const chargerId = get(selectedChargerIdAtom);
    try {
      const userData = get(authAtom);
      if (!userData) {
        console.log("selectedChargerDetails -> No userdata, returning null");
        return null; //TODO: maybe something else in the future, if we find more elegant ways of lazy atom'ing
      }
      const response = await getChargerById(userData.access_token, chargerId);
      return response[0];
    } catch (error) {
      console.error(`getChargerDetails -> getChargerDetails() ERROR: \n${error}`);
      return null;
    }
  },
});

// creating the state value
export const selectedChargerIdAtom = atom({
  key: "selectedChargerId",
  default: "0",
});

export const filteredChargersSelector = selector({
  key: "filteredChargers",
  get: async ({ get }) => {
    try {
      const index = get(chargersIndexSelector);
      const chargerFilters = get(chargerFiltersAtom);
      const sortSettings = get(chargerSortingAtom);
      const querySetting = get(chargerSearchAtom);
      const filteredChargers = filterChargers(index, chargerFilters, sortSettings, querySetting);
      return filteredChargers;
    } catch (e) {
      console.error(e, "Error in filteredChargersSelector");
    }
  },
});

export const currentChargersSelector = selector({
  key: "currentChargersSelector",
  get: async ({ get }) => {
    const space = get(selectedSpace);
    const userData = get(authAtom);
    if (!userData) {
      console.log("currentChargersSelector -> No userdata, returning null");
      return null; //TODO: maybe something else in the future, if we find more elegant ways of lazy atom'ing
    }
    return await getChargersForSpace(space?.Id?.Value, userData.access_token);
  },
});

export const chargersIndexSelector = selector({
  key: "chargersIndex",
  get: async ({ get }) => {
    return indexChargers(get(currentChargersSelector) || []);
  },
});

export function indexChargers(chargers: Charger[]) {
  //TODO: find a way to not throw errors on search with no results.
  //For example selecting available and charging where there are no 'charging' chargers indexed but available results in no results, due to the error that is thrown
  const newChargers = chargers.map((c) => _.cloneDeep(c));
  const numChargers = newChargers.length;
  const searchIndex = itemsjs.default<Charger, string, ValidChargerFilterId>(newChargers, {
    searchableFields: ["ExternalId", "DisplayName"],
    sortings: {
      updated_asc: {
        field: "LastStatusUpdate",
        order: "asc",
      },
      updated_desc: {
        field: "LastStatusUpdate",
        order: "desc",
      },
      alphabetical_asc: {
        field: "DisplayName",
        order: "asc",
      },
      alphabetical_desc: {
        field: "DisplayName",
        order: "desc",
      },
    },
    aggregations: {
      ExternalId: {
        title: "ExternalId",
        size: numChargers,
      },
      Status: {
        title: "Status",
        size: numChargers,
        conjunction: false,
      },
      ExternalSource: {
        title: "ExternalSource",
        size: numChargers,
        conjunction: false,
      },
    },
  });
  return searchIndex;
}

export function filterChargers(
  chargerIndex: itemsjs.ItemsJs<Charger, ValidSortId, ValidChargerFilterId>,
  filters: FilterDictionary,
  sortSettings: SortingSettings,
  query?: string
) {
  const formattedFilters = formatSelectedFiltersForItemJs(filters);
  let selectedSort: SortSetting | undefined = getSelectedSortMethod(sortSettings);
  try {
    const filteredChargers = chargerIndex.search({
      per_page: 300,
      sort: selectedSort?.id,
      filters: {
        ...formattedFilters,
      },
      query: query,
    });

    return filteredChargers.data.items;
  } catch (err) {
    return [];
  }
}

export function getSelectedSortMethod(settings: SortingSettings) {
  const selectedSorting = Object.values(settings).filter((entry) => entry.selected);
  return selectedSorting[0];
}

export function formatSelectedFiltersForItemJs(filters: FilterDictionary) {
  const categories = Object.values(filters);
  const selectedOptions = categories
    .flatMap((category) => Object.values(category.options))
    .filter((option) => option.selected);

  let categoryOptionDict: { [key: string]: string[] } = {};
  selectedOptions.map((option) => {
    if (!categoryOptionDict[option.path[0]]) {
      categoryOptionDict[option.path[0]] = [];
    }
    categoryOptionDict[option.path[0]].push(option.value?.toString() || "");
  });
  return categoryOptionDict;
}
