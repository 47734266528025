import { startTransition, useEffect, useState } from "react";
import { AbbrIcon, Body2 } from "../generic/typography";
import { ChevronIcon } from "../generic/icons";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import ClickAwayListener from "@mui/base/ClickAwayListener/ClickAwayListener";
import {
  allSpaces,
  filteredSpacesSelector,
  selectedSpace,
  selectedSpaceId,
  spaceSearchAtom,
} from "../../atoms/spaceAtom";
import { useLocation, useNavigate } from "react-router";
import { GenericIcon, IconType } from "../generic/genericIcon";
import { Space } from "../../generated/clients/mapi/ispaceservice/evmng.mapi.ISpaceService";
import { PageSearchBar } from "../generic/searchHeader";

export function DropDownHeaderZoneSelector() {
  const [open, setOpen] = useState<boolean>(false);
  const [activeSpaceIndex, setLocationIndex] = useRecoilState(selectedSpaceId);
  const activeSpace = useRecoilValue(selectedSpace);
  const spaces = useRecoilValueLoadable(allSpaces);
  const navigate = useNavigate();
  const currPath = useLocation();
  const curSpaces = useRecoilValueLoadable(filteredSpacesSelector);

  function changeLocationIndex(newIndex: string) {
    startTransition(() => {
      setLocationIndex(newIndex);
    });
  }

  useEffect(() => {
    redirect();
  }, [activeSpaceIndex]);

  if (spaces.state === "loading") {
    return <Body2>Loading...</Body2>;
  }

  function redirect() {
    navigate(currPath.pathname + currPath.search);
  }

  function toggleOpen() {
    setOpen((open) => !open);
  }
  function clickAwayCloseMenuFunction() {
    setOpen(false); //Needed because click listener will always fire, even if object is already closed
  }

  if (spaces.state === "hasValue") {
    let fullClasses =
      "h-14 w-120 cursor-pointer shrink-0 rounded-full border flex justify-between items-center border-honeyYellow-750 py-1.5 pl-1.5 pr-4 select-none hover:bg-honeyYellow-550 active:bg-honeyYellow-750 focus:bg-honeyYellow-550";
    if (open) {
      fullClasses = fullClasses + " bg-honeyYellow-550";
    }

    return (
      <ClickAwayListener onClickAway={clickAwayCloseMenuFunction}>
        <div>
          <div className={fullClasses} onClick={toggleOpen}>
            {activeSpace ? (
              <ZoneDropDownSelectedItem
                abbreviation={""}
                displayName={activeSpace.Name}
                allFacilities={activeSpace.Id?.Value === "AllChargers"}
                address={activeSpace.Address?.DisplayValue}
              />
            ) : (
              <ZoneDropDownSelectedItem
                abbreviation="N/A"
                displayName="NO SPACES FOUND"
                address="Check internet connection"
                allFacilities={false}
              />
            )}
            <ChevronIcon className="m-1 h-5 w-5 flex-shrink-0" isCollapsed={!open} />
          </div>
          <ZoneDropDownBoxList
            zones={curSpaces.contents}
            open={open}
            setLocation={changeLocationIndex}
            toggleOpen={toggleOpen}
          />
        </div>
      </ClickAwayListener>
    );
  }
  return <div />;
}

interface ZoneBoxListProps {
  zones: Space[];
  open: boolean;
  setLocation: Function;
  toggleOpen: Function;
}

function ZoneDropDownBoxList({ zones, open, setLocation, toggleOpen }: ZoneBoxListProps) {
  if (open) {
    //TODO:Hide scrollbar that overflows, somehow
    return (
      <div className="absolute top-20 text-left w-120 z-10 overflow-hidden border rounded-3xl border-black ">
        <PageSearchBar searchQueryAtom={spaceSearchAtom} placeholder={""} />
        <div className=" gap-3 bg-white max-h-72 overflow-scroll snap-y">
          {zones.map((item, index) => (
            <ZoneDropDownBoxItem
              key={item.Id?.Value}
              zone={item}
              index={item.Id?.Value}
              setLocation={setLocation}
              toggleOpen={toggleOpen}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

interface ZoneBoxItemProps {
  zone: Space;
  index?: string;
  setLocation: Function;
  toggleOpen: Function;
}
function ZoneDropDownBoxItem({ zone, setLocation, toggleOpen, index }: ZoneBoxItemProps) {
  const selectedSpaceIdState = useRecoilValue(selectedSpaceId);
  function selectActive() {
    setLocation(index);
    toggleOpen();
  }
  return (
    <div
      className="border-t h-16 hover:bg-black cursor-pointer focused:bg-diselDust-50 hover:text-white flex flex-row justify-start items-center gap-4 p-4 select-none snap-start group"
      key={`dropDownItem_${zone.Id}`}
      onClick={selectActive}
    >
      <AbbrIcon abbreviation={zone.Address?.CountryCode || generateAbbreviation(zone.Name)} className="h-10 w-10" />
      <div className="flex flex-row justify-between items-center grow">
        <Body2>{zone.Name}</Body2>
        {selectedSpaceIdState === zone.Id?.Value && (
          <div>
            <GenericIcon className="fill-black h-6 w-6 group-hover:fill-white" iconName={IconType.Check} />
          </div>
        )}
      </div>
    </div>
  );
}

interface ZoneDropDownSelectedItemProps {
  abbreviation: string;
  displayName: string;
  allFacilities: boolean | undefined;
  address?: string;
}

function ZoneDropDownSelectedItem({
  abbreviation,
  displayName,
  allFacilities,
  address,
}: ZoneDropDownSelectedItemProps) {
  let abbreviationCurrent = abbreviation || generateAbbreviation(displayName);

  return (
    <div className={"flex flex-row justify-start w-full truncate"}>
      <AbbrIcon abbreviation={abbreviationCurrent} className="h-10 w-10 flex-shrink-0" />
      <div className="flex flex-row font-medium justify-start items-center gap-4 flex-nowrap truncate pl-2">
        <Body2>{displayName}</Body2>
        {allFacilities ? "" : <Body2>•</Body2>}
        <Body2 className="font-light truncate">{address || "No Address"}</Body2>
      </div>
    </div>
  );
}

function generateAbbreviation(displayName: string) {
  let abbreviation = displayName
    .split(" ")
    .map((word) => word[0])
    .join("");
  if (abbreviation.length > 2) {
    abbreviation = abbreviation.substring(0, 2);
  }
  return abbreviation;
}
