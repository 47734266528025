import {
  ChargerServiceClientImpl,
  Charger,
  GrpcWebImpl,
  ChargerCommand,
} from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { Eq } from "./utils";
import { BrowserHeaders } from "browser-headers";
import { getApiUrl } from "../utils/config";

function initClient(token: string) {
  return new ChargerServiceClientImpl(
    new GrpcWebImpl(getApiUrl(), {
      debug: false,
      metadata: new BrowserHeaders({ Authorization: "Bearer " + token }),
    })
  );
}

export const getChargersForSpace = async (currentSpaceId: string | undefined, token: string) => {
  if (currentSpaceId === undefined) {
    return [];
  }
  try {
    const chargerService = initClient(token);
    // Hack for now to show some chargers with other status that have no location data yet.
    let filters;
    switch (currentSpaceId) {
      case "AllChargers":
        const responseChargers = await chargerService.GetChargers({
          Version: "2.2",
        });
        return responseChargers.data.map((charger) => Charger.fromJSON(charger));
      default: {
        filters = { SearchLiteral: Eq("SpaceId", currentSpaceId) };
      }
    }
    const responseData = await chargerService.Search({ Filter: filters });
    return responseData.Items.length > 0 ? responseData.Items : [];
  } catch (err: any) {
    console.error(err);
    return [];
  }
};

export const getChargers = async (token: string, chargerIds?: string[]) => {
  try {
    const chargerService = initClient(token);
    let responseData;
    if (chargerIds !== undefined) {
      responseData = await chargerService.GetChargers({
        Version: "2.2",
        Ids: chargerIds,
      });
    } else {
      responseData = await chargerService.GetChargers({
        Version: "2.2",
      });
    }
    return responseData.data.map((charger) => Charger.fromJSON(charger));
  } catch (err: any) {
    console.error(err);
    return [];
  }
};

export const getChargerById = async (token: string, chargerId: string) => {
  const responseData = await getChargers(token, [chargerId]);
  return responseData;
};

export async function commandChargerById(token: string | undefined, chargerId: string, commandCode: ChargerCommand) {
  if (!token) {
    console.error("commandChargerById()-> No auth token found");
    return;
  }
  try {
    const chargerService = initClient(token);
    const response = await chargerService.ControlCharger(
      {
        ChargerId: chargerId,
        ChargerCommand: commandCode,
      },
      new BrowserHeaders({ Authentication: "Bearer " + token })
    );
    return response;
  } catch (err) {
    console.error("resetChargerById() => ", err);
    return;
  }
}

export async function syncChargers(token: string | undefined) {
  try {
    if (!token) {
      console.error("syncChargers()-> No auth token found");
      return;
    }
    const chargerService = initClient(token);
    const response = await chargerService.SyncChargers(
      {}, //empty interface type on purpose??
      new BrowserHeaders({ Authentication: "Bearer " + token })
    );
    return response;
  } catch (error) {
    console.error("syncChargers() -> error", error);
    return;
  }
}

export async function syncCharger(token: string | undefined, chargerId: string) {
  try {
    if (!token) {
      console.error("syncCharger()-> No auth token found");
      return;
    }
    const chargerService = initClient(token);
    const response = await chargerService.SyncCharger(
      { ChargerId: chargerId },
      new BrowserHeaders({ Authentication: "Bearer " + token })
    );
    return response;
  } catch (error) {
    console.error("syncCharger() -> error", error);
    return;
  }
}
