import { Body3, Body4 } from "../generic/typography";
import { RefreshIcon } from "../generic/icons";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { lastRefreshedAtom } from "../../atoms/lastRefreshedAtom";
import dayjs from "dayjs";
import { authAtom } from "../../atoms/authAtom";
import { currentChargersSelector } from "../../atoms/chargerAtom";

interface RefreshWidgetProps {}

export function RefreshWidget() {
  const [refreshTime, setRefreshTime] = useRecoilState(lastRefreshedAtom);
  const authInfo = useRecoilValue(authAtom);
  const refreshChargerInfo = useRecoilRefresher_UNSTABLE(
    currentChargersSelector
  );

  async function refreshFunction() {
    refreshChargerInfo();
    setRefreshTime(dayjs());
  } //TODO: Probably want to refresh all atoms in future including space and user info

  return (
    <div className="flex flex-row items-center justify-center gap-4">
      <div>
        <Body4 className="hidden xl:flex font-bold">
          Zuletzt aktualisiert:
        </Body4>
        <div className="flex flex-row gap-3 items-center">
          <Body3 className="border-r border-honeyYellow-900 border-solid pr-3 font-normal">{`${refreshTime.format(
            "DD. MMM YYYY"
          )}`}</Body3>
          <Body3 className="font-normal">{`${refreshTime.format(
            "HH:mm"
          )}`}</Body3>
        </div>
      </div>
      {/* <div
        className="h-5 w-5 cursor-pointer overflow-visible rounded-full bg-honeyYellow-750 p-1 flex justify-center items-center hover:bg-black focus:bg-black active:bg-black group"
        onClick={refreshFunction}
      >
        <RefreshIcon className="h-4 w-4 group-hover:fill-white group-focus:fill-white group-active:fill-diselDust-400" />
      </div> */}
    </div>
  );
}
