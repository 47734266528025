import { defaultChargerFilters } from "../atoms/defaultFilterJson";
import {
  Charger,
  EvSource,
  Status,
} from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import {
  FilterDictionary,
  FilterOption,
  FilterOptions,
} from "../types/filterTypes";

export const BROKEN_STATES = [
  Status.BLOCKED,
  Status.INOPERATIVE,
  Status.Status_UNKNOWN,
  Status.UNRECOGNIZED,
  Status.OUTOFORDER,
];

export const AVAILABLE_STATES = [Status.AVAILABLE];

export const BUSY_STATES = [Status.PLANNED, Status.CHARGING];

export const isAvailable = (status: Status) => {
  return AVAILABLE_STATES.includes(status);
};

export const isBusy = (status: Status) => {
  return BUSY_STATES.includes(status);
};

export const isBroken = (status: Status) => {
  return BROKEN_STATES.includes(status);
};

const statusExists = (status: Status, existing: string[]) => {
  return existing.includes(status.toString());
};

export const getStatusIds = (
  name: string,
  aggregates: {
    data: {
      buckets: itemsjs.Buckets<Charger>;
    };
    pagination: itemsjs.Pagination;
  }
) => {
  const defaultFilter: FilterDictionary = defaultChargerFilters;
  const options: FilterOptions = defaultFilter.Status.options;
  const existingKeys = aggregates.data.buckets.map((bucket) =>
    bucket.key.toString()
  );

  let predicate = null;
  if (name === "Broken") {
    predicate = (option: FilterOption) =>
      isBroken(option.value as Status) &&
      statusExists(option.value as Status, existingKeys);
  } else if (name === "Busy") {
    predicate = (option: FilterOption) =>
      isBusy(option.value as Status) &&
      statusExists(option.value as Status, existingKeys);
  } else if (name === "Available") {
    predicate = (option: FilterOption) =>
      isAvailable(option.value as Status) &&
      statusExists(option.value as Status, existingKeys);
  }
  if (predicate) {
    return Object.values(options)
      .filter(predicate)
      .map((value) => value.id);
  }
};
