import { Status } from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { ImportanceLevel } from "./importanceLevel";

export function statusToName(status: Status) {
  switch (status) {
    case Status.Status_UNKNOWN:
      return "UNKNOWN";
    case Status.AVAILABLE:
      return "AVAILABLE";
    case Status.BLOCKED:
      return "BLOCKED";
    case Status.CHARGING:
      return "CHARGING";
    case Status.INOPERATIVE:
      return "INOPERATIVE";
    case Status.OUTOFORDER:
      return "OUT OF ORDER";
    case Status.PLANNED:
      return "PLANNED";
    case Status.REMOVED:
      return "REMOVED";
    case Status.RESERVED:
      return "RESERVED";
    default:
      return "UNRECOGNIZED";
  }
}

export function statusToImportance(status: Status) {
  switch (status) {
    case 0:
      return ImportanceLevel.Warning;
    case 1:
      return ImportanceLevel.AllFine;
    case 2:
      return ImportanceLevel.Emergency;
    case 3:
      return ImportanceLevel.AllFine;
    case 4:
      return ImportanceLevel.Warning;
    case 5:
      return ImportanceLevel.Warning;
    case 6:
      return ImportanceLevel.AllFine;
    case 7:
      return ImportanceLevel.Emergency;
    case 8:
      return ImportanceLevel.AllFine;
    default:
      return ImportanceLevel.Warning;
  }
}
