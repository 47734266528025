import { ReactNode } from "react";
import { Body2, Body3 } from "./typography";

interface SendEmailButtonProps {
  email: string;
}

//TODO: Make functional when there is a design for it
export function SendEmailButton(props: SendEmailButtonProps) {
  const email = props.email;
  const buttonText = "SEND AN E-MAIL";

  if (!email) {
    return null;
  }

  function sendEmail() {}
  return (
    <div className="bg-diselDust-50 rounded-3xl px-4 py-1 font-medium border-diselDust-150 border h-6 flex items-center justify-center cursor-pointer">
      <Body3>{buttonText}</Body3>
    </div>
  );
}

interface ProfileCardButtonProps {
  text: string | ReactNode;
  onClickFunction: any;
  disabled?: boolean; //TODO: Figure out what functions we will use and pass them
}

export function ProfileCardButton(props: ProfileCardButtonProps) {
  const text = props.text;
  function clickHandler() {
    props.onClickFunction(text);
  }
  if (props.disabled) {
    return (
      <button
        disabled={props.disabled}
        className="w-90 p-3 flex justify-center items-center bg-diselDust-100 cursor-not-allowed rounded-3xl border border-diselDust-150 text-diselDust-400"
      >
        <Body2 className="font-medium">{text}</Body2>
      </button>
    );
  }
  return (
    <button
      className="w-90 p-3 flex justify-center items-center bg-diselDust-100 cursor-pointer rounded-3xl border hover:border-diselDust-300"
      onClick={clickHandler}
    >
      <Body2 className="font-medium">{text}</Body2>
    </button>
  );
}

interface GenericButtonProps {
  className?: string;
  text: string;
  onClick: Function;
  cancelButton?: boolean;
}
export function GenericButton(props: GenericButtonProps) {
  const text = props.text;
  const cancelButtonClassName =
    " bg-white hover:border-diselDust-100 active:border-diselDust-500 focused:border-diselDust-600 ";
  const normalButtonClassName =
    " bg-diselDust-100 hover:border-diselDust-300 active:border-diselDust-500 focused:border-diselDust-850 ";
  let fullClassName =
    "w-52 h-11 flex justify-center items-center rounded-3xl cursor-pointer border border-diselDust-150 ";
  if (props.cancelButton) {
    fullClassName = fullClassName + cancelButtonClassName;
  } else {
    fullClassName = fullClassName + normalButtonClassName;
  }
  fullClassName = fullClassName + " " + props.className;

  function clickHandler() {
    props.onClick(text);
  }

  return (
    <div className={fullClassName} onClick={clickHandler}>
      <Body2 className="font-medium">{text}</Body2>
    </div>
  );
}
