//lower order is prioritized

import { i18n } from "../i18n/langType";

interface KeyToDisplay {
  [key: string]: { displayLabel: string; orderValue: number };
}
export const chargerKeys: KeyToDisplay = {
  //left side is name of variable coming from backend, ordervalue is the order -top down- of how they will appear, with 1 at the top
  DisplayName: { displayLabel: i18n.name, orderValue: 1 },
  Status: { displayLabel: i18n.status, orderValue: 4 },
  SpaceName: { displayLabel: i18n.space, orderValue: 2 }, //probably wont use this as 'spacename', since cust serve doesnt care about nucleus spaces, instead use location data from FA
  LastStatusUpdate: { displayLabel: i18n.lastStatusUpdate, orderValue: 5 },
  ExternalSource: { displayLabel: i18n.cpo, orderValue: 3 },
  ExternalConnectorId: { displayLabel: i18n.connectorId, orderValue: 6 },
  ExternalId: { displayLabel: i18n.cpoId, orderValue: 7 },
  Id: { displayLabel: i18n.id, orderValue: 8 },
  LastSync: { displayLabel: i18n.lastSyncronized, orderValue: 9 },
  LastPowerDelivery: { displayLabel: i18n.lastPowerDelivery, orderValue: 10 },
  Capacity: { displayLabel: "Capacity", orderValue: 11 },
};
