import {
  atom,
  GetCallback,
  GetRecoilValue,
  selector,
  selectorFamily,
} from "recoil";
import * as itemsjs from "itemsjs";
import {
  ChargingSessionFilter,
  getChargingSessions,
} from "../api/chargerSessionsClient";
import { authAtom } from "./authAtom";
import { selectedChargerIdAtom } from "./chargerAtom";
import { selectedSpace } from "./spaceAtom";
import { ChargingSession } from "../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import _ from "lodash";
import {
  FilterDictionary,
  ValidSessionFilterId,
  TemporalFilterValue,
  TemporalOperator,
  FilterCategory,
  SortingSettings,
  SortSetting,
  ValidSortId,
} from "../types/filterTypes";
import { sessionFiltersAtom, sessionSearchAtom } from "./filterAtoms";
import { sessionSortingAtom } from "./sessionSortingAtom";

export interface FilterIndicators {
  chargerId?: boolean;
  spaceId?: boolean;
  active?: boolean;
  self?: boolean;
}

const buildFilters = (
  filterIndicators: Readonly<FilterIndicators>,
  get: GetRecoilValue
) => {
  let filters = {};
  if (filterIndicators.active) {
    filters = { ...filters, Active: true };
  }
  if (filterIndicators.self) {
    filters = { ...filters, Self: true };
  }
  if (filterIndicators.chargerId) {
    filters = { ...filters, ChargerId: get(selectedChargerIdAtom) };
  }
  if (filterIndicators.spaceId) {
    const spaceId = get(selectedSpace)?.Id?.Value;
    if (spaceId !== "AllChargers") {
      filters = {
        ...filters,
        SpaceId: spaceId,
      };
    }
  }
  return filters;
};

export const chargingSessionsFilteredByIndicator = selectorFamily({
  key: "chargingSessionsFilteredByIndicator",
  get:
    (filtersIndicators: Readonly<FilterIndicators>) =>
    async ({ get }: { get: GetRecoilValue; getCallback: GetCallback }) => {
      const filters = buildFilters(filtersIndicators, get);
      try {
        const userData = get(authAtom);
        if (!userData) {
          console.log(
            "filteredChargingSessions -> No userdata, returning null"
          );
          return []; //TODO: maybe something else in the future, if we find more elegant ways of lazy atom'ing
        }
        const response = await getChargingSessions(
          userData.access_token,
          filters as ChargingSessionFilter
        );
        if (response && response.length) {
          return response;
        }
        return [];
      } catch (error) {
        console.error(`filteredChargingSessions() ERROR: \n${error}`);
        return [];
      }
    },
});

export const sessionsIndexSelector = selectorFamily({
  key: "sessionsIndex",
  get:
    (filtersIndicators: Readonly<FilterIndicators>) =>
    async ({ get }) => {
      return indexSessions(
        get(chargingSessionsFilteredByIndicator(filtersIndicators)) || []
      );
    },
});

export function filterSessions(
  sessionIndex: itemsjs.ItemsJs<
    ChargingSession,
    ValidSortId,
    ValidSessionFilterId
  >,
  filters: FilterDictionary,
  sortSettings: SortingSettings,
  query?: string
) {
  const formattedFilters = formatSelectedFiltersForItemJs(filters);
  let selectedSort: SortSetting | undefined =
    getSelectedSortMethod(sortSettings);
  try {
    const filteredSessions = sessionIndex.search({
      per_page: 300,
      sort: selectedSort?.id,
      filters: {
        ...formattedFilters,
      },
      query: query,
      filter: (session) => {
        return filterSessionsByTime(session, filters.Time);
      },
    });
    return filteredSessions.data.items;
  } catch (err) {
    console.error("Error in FilterSessions", err);
    return [];
  }
}

export const filteredChargingSessions = selectorFamily({
  key: "filteredChargingSessions",
  get:
    (filtersIndicators: Readonly<FilterIndicators>) =>
    async ({ get }: { get: GetRecoilValue; getCallback: GetCallback }) => {
      const sessionFilters = get(sessionFiltersAtom);
      const sessionIndex = get(sessionsIndexSelector(filtersIndicators));
      const sortSettings = get(sessionSortingAtom);
      const querySetting = get(sessionSearchAtom);
      return filterSessions(sessionIndex, sessionFilters, sortSettings, querySetting);
    },
});

export function indexSessions(sessions: ChargingSession[]) {
  const newSessions = sessions.map((c) => _.cloneDeep(c));
  const numSessions = newSessions.length;
  const searchIndex = itemsjs.default<
    ChargingSession,
    string,
    ValidSessionFilterId
  >(newSessions, {
    searchableFields: ["ChargerId", "Id", "LicensePlateId", "SpaceId", "NucleusSessionId", "ChargerDisplayName"],
    sortings: {
      start_asc: {
        field: "Start",
        order: "asc",
      },
      start_desc: {
        field: "Start",
        order: "desc",
      },
      alphabetical_asc: {
        field: "ChargerDisplayName",
        order: "asc",
      },
      alphabetical_desc: {
        field: "ChargerDisplayName",
        order: "desc",
      },
    },
    aggregations: {
      Time: {
        title: "Time",
        size: numSessions,
      },
      ChargingSessionState: {
        title: "Charging Session Status",
        size: numSessions,
        conjunction: false,
      },
    },
  });
  return searchIndex;
}

export const selectedActiveChargerSession = selector({
  key: "selectedChargerActiveSession",
  get: async ({ get }) => {
    const activeChargingSessions = get(
      chargingSessionsFilteredByIndicator({ active: true, chargerId: true })
    );
    if (activeChargingSessions && activeChargingSessions.length) {
      return activeChargingSessions[0];
    }
    return null;
  },
});

export function formatSelectedFiltersForItemJs(filters: FilterDictionary) {
  const categories = Object.values(filters);
  let selectedOptions = categories
    .flatMap((category) => Object.values(category.options))
    .filter((option) => option.selected);
  selectedOptions = selectedOptions.filter((option) => {
    if (!option.id.includes("Time")) {
      return true;
    }
    return false;
  });
  let categoryOptionDict: { [key: string]: string[] } = {};
  selectedOptions.map((option) => {
    if (!categoryOptionDict[option.path[0]]) {
      categoryOptionDict[option.path[0]] = [];
    }
    categoryOptionDict[option.path[0]].push(option.value?.toString() || "");
  });
  return categoryOptionDict;
}

export function filterSessionsByTime(
  session: ChargingSession,
  timeFilter: FilterCategory
) {
  //if there are time filters selected, then we need to filter them
  const startTimeFilterIsSelected = timeFilter.options.startTime.selected;
  const endTimeFilterIsSelected = timeFilter.options.endTime.selected;
  const createdAtFilterIsSelected = timeFilter.options.createdAtTime.selected;
  const startTimeValue = timeFilter.options.startTime
    .value as TemporalFilterValue;
  const endTimeValue = timeFilter.options.endTime.value as TemporalFilterValue;
  const createdAtTimeValue = timeFilter.options.createdAtTime
    .value as TemporalFilterValue;
  if (
    startTimeFilterIsSelected &&
    !isWithinTimeFilter(startTimeValue, session.Start)
  ) {
    return false;
  }
  if (
    endTimeFilterIsSelected &&
    !isWithinTimeFilter(endTimeValue, session.End)
  ) {
    return false;
  }
  if (
    createdAtFilterIsSelected &&
    !isWithinTimeFilter(createdAtTimeValue, session.CreatedAt)
  ) {
    return false;
  }
  return true;
}

function isWithinTimeFilter(
  option: TemporalFilterValue,
  sessionDateTime: Date | undefined
) {
  if (!option.from || !sessionDateTime) {
    return false;
  }
  let fromOption = new Date(option.from);
  switch (option.operator) {
    case TemporalOperator.NEWER:
      if (sessionDateTime < fromOption) {
        return false;
      }
      break;
    case TemporalOperator.OLDER:
     
      if (sessionDateTime > fromOption) {
        return false;
      }
      break;
    case TemporalOperator.RANGE:
      if (
        sessionDateTime < fromOption ||
        !option.to ||
        sessionDateTime > new Date(option.to)
      ) {
        return false;
      }
  }
  return true;
}

function getSelectedSortMethod(settings: SortingSettings) {
  const selectedSorting = Object.values(settings).filter(
    (entry) => entry.selected
  );
  return selectedSorting[0];
} //TODO: Maybe create a filtering / sorting util function file