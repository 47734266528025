import TextField from "@mui/material/TextField";
import {
  FilterCategory,
  FilterComponentProps,
  FilterOption,
  FilterOptions,
  TemporalFilterValue,
  TemporalOperator,
} from "../../../types/filterTypes";
import { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ClickAwayListener } from "@mui/base";
import { ChevronIcon } from "../icons";
import { Body3 } from "../typography";
import React from "react";
import { useIntl } from "react-intl";
import { i18n } from "../../../i18n/langType";

export function DatePickerFilterCategory<T extends {}>(
  props: FilterComponentProps<T>
) {
  const options = props.filterCategory.options;
  const intl = useIntl();
  function changeOptionState(newOption: FilterOption) {
    const newOptions: FilterOptions = {
      ...props.filterCategory.options,
      [newOption.id]: newOption,
    };
    const newCategory: FilterCategory = {
      id: props.filterCategory.id,
      displayTitle: props.filterCategory.displayTitle,
      options: newOptions,
    };
    props.setState(newCategory.id, newCategory, newOption);
  }
  return (
    <div>
      <Body3>{intl.formatMessage({id:props.filterCategory.displayTitle})}</Body3>
      <div>
        {Object.values(options).map((option) => (
          <DatePickerFilter
            key={option.id}
            option={option}
            setState={(newOption: FilterOption) => changeOptionState(newOption)}
          />
        ))}
      </div>
    </div>
  );
}

export interface DatePickerFilterOptionProps {
  option: FilterOption;
  setState: Function;
}

export function DatePickerFilter(props: DatePickerFilterOptionProps) {
  const [temporalValueLocal, setTemporalValueLocal] =
    useState<TemporalFilterValue>(props.option.value as TemporalFilterValue);
  const [selected, setSelected] = useState(props.option.selected);
  const intl = useIntl();
  React.useEffect(() => {
    const newValue: TemporalFilterValue = {
      from: temporalValueLocal.from,
      to: temporalValueLocal.to,
      operator: temporalValueLocal.operator,
    };

    const newOption: FilterOption = {
      ...props.option,
      selected: selected,
      value: newValue,
    };
    props.setState(newOption);
  }, [temporalValueLocal, selected]);

  function setNewLocalTemporalValue(
    key: string,
    newValue: TemporalOperator | Date | null | undefined
  ) {
    const newLocalValue = { ...temporalValueLocal, [key]: newValue };
    setTemporalValueLocal(newLocalValue);
  }

  return (
    <div className="mt-6">
      <div>{intl.formatMessage({id:props.option.displayName})}</div>
      <div className="items-center gap-2 flex mb-2">
        <input
          type={"checkbox"}
          className={"accent-black h-6 w-6"}
          checked={selected}
          id={`dateTimeFilterItem${props.option.displayName}`}
          onChange={() => {
            setSelected(!selected);
          }}
        />
        <OperatorDropDown
          operatorKeyName={props.option.id}
          operatorState={temporalValueLocal.operator}
          setOperatorState={(newOperator: TemporalOperator) => {
            setNewLocalTemporalValue("operator", newOperator);
          }}
        />
      </div>
      <div className="flex gap-2 p-2">
        <DateTimePicker
          label={intl.formatMessage({id:i18n.fromDateTime})}
          value={temporalValueLocal.from}
          onChange={(newStart) => {
            setNewLocalTemporalValue("from", newStart);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        {temporalValueLocal.operator === TemporalOperator.RANGE && (
          <DateTimePicker
            label={intl.formatMessage({id:i18n.toDateTime})}
            value={temporalValueLocal.to}
            onChange={(newEnd) => {
              setNewLocalTemporalValue("to", newEnd);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </div>
    </div>
  );
}

export interface OperatorDropDownProps {
  operatorKeyName: string;
  operatorState: TemporalOperator;
  setOperatorState: Function;
}

export function OperatorDropDown(props: OperatorDropDownProps) {
  const [open, setOpen] = useState<boolean>(false);
  const dropDownList = [
    TemporalOperator.OLDER,
    TemporalOperator.NEWER,
    TemporalOperator.RANGE,
  ];

  function toggleOpenDropDown() {
    setOpen((open) => !open);
  }

  function clickAwayCloseMenuFunction() {
    setOpen(false); //Needed because click listener will always fire, even if object is already closed
  }

  function onClickOperator(newOperator: TemporalOperator) {
    props.setOperatorState(newOperator);
    setOpen(false);
  }

  let fullClasses =
    "relative h-10 cursor-pointer shrink-0 border flex justify-between items-center py-1 px-1 select-none";
  if (open) {
    fullClasses = fullClasses + " bg-white";
  }

  return (
    <ClickAwayListener onClickAway={clickAwayCloseMenuFunction}>
      <div className="relative">
        <div className={fullClasses} onClick={toggleOpenDropDown}>
          <OperatorItem
            keyValue={`operator_display_${props.operatorKeyName}`}
            operator={props.operatorState}
            selectItem={() => {
              return;
            }}
          />
          <ChevronIcon
            className="m-1 h-5 w-5 flex-shrink-0"
            isCollapsed={!open}
          />
        </div>
        {open && (
          <div className="absolute bg-diselDust-50 text-left z-10 gap-3 shadow-2xl max-h-72 overflow-scroll">
            {dropDownList.map((item, index) => (
              <OperatorItem
                key={`operator_${index}_${
                  props.operatorKeyName
                }_${item.toString()}`}
                keyValue={`operator_${index}_${
                  props.operatorKeyName
                }_${item.toString()}`}
                operator={item}
                selectItem={() => {
                  onClickOperator(item);
                }}
              />
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

interface OperatorItemProps {
  keyValue: string;
  operator: TemporalOperator;
  selectItem: Function;
}
function OperatorItem(props: OperatorItemProps) {
  const intl = useIntl();
  function thisOnClick() {
    props.selectItem();
  }
  return (
    <div
      className="hover:bg-black cursor-pointer focused:bg-diselDust-50 hover:text-white flex flex-row justify-start items-center gap-4 p-1 select-none snap-start group"
      key={`dropDownItem_${props.keyValue}`}
      onClick={thisOnClick}
    >
      {intl.formatMessage({id:OperatorToText(props.operator)})}
    </div>
  );
}

function OperatorToText(operator: TemporalOperator) {
  switch (operator) {
    case TemporalOperator.NEWER:
      return i18n.newerThan;
    case TemporalOperator.OLDER:
      return i18n.olderThan;
    case TemporalOperator.RANGE:
      return i18n.withinRange;
  }
}
