import { ReactNode } from "react";
import { Body1 } from "../typography";

export interface FilterProps {
  children: ReactNode;
  title: string;
}
export function Filter(props: FilterProps) {
  return (
    <div className="flex flex-col gap-11">
      <Body1 className="font-normal">{props.title}</Body1>
      <div>{props.children}</div>
    </div>
  );
}
