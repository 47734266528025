import { MouseEventHandler, ReactNode } from "react";

interface SidePanelProps {
  children: ReactNode;
  open: boolean;
  toggleOpen: MouseEventHandler<HTMLDivElement>;
  footerComponent?: ReactNode;
}
export function SidePanel(props: SidePanelProps) {
  if (props.open) {
    return (
      <div className="flex flex-col absolute top-0 right-0 h-full bg-diselDust-50 w-4/12 overflow-hidden shadow-lg flex-grow z-40">
        <div className="flex flex-row overflow-scroll flex-grow">
          <SidePanelHandle toggleOpen={props.toggleOpen} />
          {props.children}
        </div>
        {props.footerComponent && (
          <div className="flex justify-center items-center border-t py-10">
            {props.footerComponent}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="absolute top-0 right-0 bg-white w-0 overflow-hidden">
      {props.children}
    </div>
  );
}

interface SidePanelHandleProps {
  toggleOpen: MouseEventHandler<HTMLDivElement>;
}

function SidePanelHandle(props: SidePanelHandleProps) {
  return (
    <div
      className="h-full flex items-center cursor-pointer w-14"
      onClick={props.toggleOpen}
    >
      <div className="border h-20 w-0 border-diselDust-200 ml-3 rounded-full" />
    </div>
  );
}
