import { GenericButton } from "../buttons";

interface FilterPanelFooterProps {
  applyChanges: Function;
  closePanel: Function;
}
export function Footer(props: FilterPanelFooterProps) {
  return (
    <div className="flex flex-row gap-4">
      <GenericButton
        className="bg-transparent"
        text="Cancel"
        cancelButton
        onClick={props.closePanel}
      />
      <GenericButton text="Apply Changes" onClick={props.applyChanges} />
    </div>
  );
}
