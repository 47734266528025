import { EvSource } from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
const CHARGER_DEFAULT_IMG = "/siemenscharger.jpg";

export function getChargerPictureBySource(source: EvSource) {
  switch (source) {
    case EvSource.DEFA:
      return "/defa.png";
    case EvSource.EASEE:
      return "/easee.png";
    case EvSource.SCHNEIDER:
      return "/schneider.png";
    case EvSource.ZAPTEC:
      return "/zaptec.png";
    case EvSource.GREENFLUX:
      return "/greenflux.png";
    default:
      return CHARGER_DEFAULT_IMG;
  }
}
