import { atom } from "recoil";
import { SortingSettings } from "../types/filterTypes";
import { i18n } from "../i18n/langType";


export type SessionSortIdType =
  | "alphabetical_asc"
  | "alphabetical_desc"
  | "start_asc"
  | "start_desc";

export const defaultSessionSorting: SortingSettings = {
  start_asc: { id: "start_asc", selected: false, label: i18n.filterDateStartAsc },
  start_desc: {
    id: "start_desc",
    selected: false,
    label: i18n.filterDateStartDesc,
  },
  alphabetical_asc: {
    id: "alphabetical_asc",
    selected: false,
    label: i18n.filterAlphabetAsc,
  },
  alphabetical_desc: {
    id: "alphabetical_desc",
    selected: false,
    label: i18n.filterAlphabetDesc,
  },
};

export const sessionSortingAtom = atom({
  key: "sessionSorting",
  default: defaultSessionSorting,
});
