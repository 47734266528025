import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import { selectedChargerSelector } from "../../atoms/chargerAtom";
import { Charger, EvSource, Status } from "../../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { chargerKeys } from "../../translations/chargerKeysEnglish"; //todo make some sort of translation map for different languages
import { sourceToName } from "../../types/evSource";
import { Facility } from "../../types/facility";
import { statusToName } from "../../types/status";
import { SendEmailButton } from "./buttons";
import { PhoneIcon } from "./icons";
import { AbbrIcon, Body1, Body2, Body3 } from "./typography";
import { useIntl } from "react-intl";
import { i18n } from "../../i18n/langType";

interface InformationPanelButtonProps {
  text: string;
  action: Function; //TODO:Type properly, onclick event handler?
}

export function InformationPanel() {
  const intl = useIntl();
  const charger = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(selectedChargerSelector);
  if (!charger) {
    return <div>{intl.formatMessage({ id: i18n.noChargerFound })}</div>; //TODO: return something better or show error?
  }
  const chargerWithPower = { ...charger, Capacity: charger.OcpiData?.Power };
  const infoEntries = formatChargerInfoForPanel(chargerWithPower);
  const infoEntriesMoreInfo = infoEntries.map((entry) => {
    return { ...entry, displayInfo: chargerKeys[entry.label] };
  });
  return (
    <div className="h-140 bg-white border border-diselDust-100 overflow-y-scroll">
      <div className="ml-8 pt-5 mr-6">
        <div className="border-b-2 border-solid border-diselDust-100 pb-7 h-16">
          <Body1>{intl.formatMessage({ id: i18n.information })}</Body1>
        </div>
        <div>
          {returnOrderedEntries(infoEntriesMoreInfo).map((entry: InformationEntry) => {
            return <InformationPanelRow {...entry} key={`infoPanelRow_${entry.label}`} />;
          })}
        </div>
      </div>
    </div>
  );
}

function returnOrderedEntries(infoEntries: InformationEntry[]) {
  return infoEntries.sort((a, b) => {
    if (a.displayInfo === undefined) {
      return -1;
    }
    if (b.displayInfo === undefined) {
      return 1;
    }
    return a.displayInfo.orderValue > b.displayInfo.orderValue ? 1 : -1;
  });
}

interface InformationEntry {
  label: string;
  content: string | Status | EvSource;
  copy?: boolean;
  infoButtons?: InformationPanelButtonProps[];
  displayInfo?: { displayLabel: string; orderValue: number };
}

function InformationPanelRow({ label, content, copy, infoButtons, displayInfo }: InformationEntry) {
  const intl = useIntl();
  function CopyRHSToClipboard() {
    if (content) {
      navigator.clipboard.writeText(JSON.stringify(content));
    }
  }
  if (!Object.keys(chargerKeys).includes(label)) {
    return null;
  }
  let displayContent = transformContentBasedOnLabel(label, content); //TODO: needs to return a json I think, with "Display text, additional components, function attached to display content"
  if (displayContent == i18n.notAvailable) {
    displayContent = intl.formatMessage({ id: i18n.notAvailable }); //TODO: this feels bad somehow
  }
  return (
    <div
      key={`infoPanel_${label}`}
      className="h-full flex flex-row justify-between min-h-max items-center border-b-2 last:border-b-0 border-solid border-diselDust-100 py-4"
    >
      <Body3 className="text-diselDust-400 text-sm">{intl.formatMessage({ id: displayInfo?.displayLabel })}</Body3>
      <div className="flex flex-row items-center gap-5 whitespace-pre-wrap">
        <Body3 className="text-black text-sm max-w-md">{displayContent}</Body3>
        {copy && (
          <div className="underline cursor-pointer" onClick={CopyRHSToClipboard}>
            <Body3>{intl.formatMessage({ id: i18n.copy })}</Body3>
          </div>
        )}
        {infoButtons &&
          infoButtons.map((button) => {
            return (
              <InformationPanelButton
                key={`infoPanelButton_${button.text}`}
                text={button.text}
                action={button.action}
              />
            );
          })}
      </div>
    </div>
  );
}

function InformationPanelButton({ text, action }: InformationPanelButtonProps) {
  function clickHandler() {
    action();
  }
  return (
    <div key={`infoPanelButton_${text}`} className="underline cursor-pointer" onClick={clickHandler}>
      <Body3>{text}</Body3>
    </div>
  );
}

interface FacilityContactInfoProps {
  facility: Facility;
}

export function FacilityContactInfo(props: FacilityContactInfoProps) {
  //TODO: is this necessary / available?
  const facility = props.facility;
  const rIntl = useIntl();
  const sendEmailButtonText = rIntl.formatMessage({ id: i18n.sendAnEmail });
  return (
    <div className="h-full bg-white flex flex-row items-center justify-between px-8 py-8 border-diselDust-100 border">
      <div className="flex flex-row items-center gap-2.5">
        <AbbrIcon abbreviation={facility.abbreviation} className={"h-8 w-8"} />
        <Body2>{facility.name}</Body2>
      </div>
      <div className="flex flex-row items-center justify-center gap-4">
        <PhoneIcon className="fill-diselDust" />
        <Body2 className="text-diselDust">{facility.contactNumber}</Body2>
      </div>
      <SendEmailButton email={sendEmailButtonText} />
    </div>
  );
}

function transformContentBasedOnLabel(label: string, content: string | Status | EvSource) {
  let displayContent = content;
  switch (
    label //for enum transformation
  ) {
    case "Status":
      displayContent = JSON.stringify(displayContent);
      displayContent = displayContent.split('"').join("");
      displayContent = statusToName(parseInt(displayContent));
      break;
    case "ExternalSource":
      displayContent = JSON.stringify(displayContent);
      displayContent = displayContent.split('"').join("");
      displayContent = sourceToName(parseInt(displayContent));
      break;
    case "LastSync":
      if (!displayContent) {
        displayContent = i18n.neverSynchronized;
        return displayContent;
      }
      break;
    case "LastPowerDelivery":
      if (displayContent === "") {
        displayContent = i18n.notAvailable;
        break;
      }
      displayContent = displayContent + " KWh";
      break;
    case "Capacity":
      if (!displayContent) {
        displayContent = i18n.notAvailable;
        break;
      }
      displayContent = (parseInt(content.toString()) / 1000).toString() + " kW";
      break;
    default:
      break;
  }
  if (typeof displayContent === "object") {
    if (Object.prototype.toString.call(displayContent) === "[object Date]") {
      const options: {
        year: "numeric" | "2-digit" | undefined;
        month: "numeric" | "2-digit" | undefined;
        day: "numeric" | "2-digit" | undefined;
        hour: "numeric" | "2-digit" | undefined;
        minute: "numeric" | "2-digit" | undefined;
        second: "numeric" | "2-digit" | undefined;
      } = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      displayContent = new Intl.DateTimeFormat(["de-DE"], options).format(new Date(displayContent)); //Todo: Do date localization
    } else {
      displayContent = JSON.stringify(displayContent);
    }
  }
  if (displayContent) return displayContent;
}

function formatChargerInfoForPanel(charger: Charger) {
  const entries = Object.entries(charger).map((keyPair) => {
    return { label: keyPair[0], content: keyPair[1] };
  });
  return entries;
}
