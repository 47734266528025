import { useEffect, useState, useTransition } from "react";
import { commandChargerById, syncCharger } from "../../api/chargerClient";
import { statusToImportance, statusToName } from "../../types/status";
import { ProfileCardButton } from "../generic/buttons";
import { RefreshIcon } from "../generic/icons";
import { StatusIndicatorDisplay } from "../generic/statusIndicatorDisplay";
import { Body3, H3 } from "../generic/typography";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
  useRecoilValue,
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
} from "recoil";
import { authAtom } from "../../atoms/authAtom";
import { getChargerPictureBySource } from "../../types/charger";
import { GenericIcon, IconType } from "../generic/genericIcon";
import {
  selectedChargerIdAtom,
  selectedChargerSelector,
} from "../../atoms/chargerAtom";
import { useParams } from "react-router-dom";
import {
  getSelectedChargerState,
  startSessionByChargerId,
  stopSessionBySessionId,
} from "../../api/chargerSessionsClient";
import {
  selectedActiveChargerSession,
  chargingSessionsFilteredByIndicator,
} from "../../atoms/sessionsAtom";
import { SessionState } from "../../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import {
  ChargerCommand,
  EvSource,
} from "../../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";

import ConfirmModal from "../generic/confirmModal";

enum ConfirmModalType {
  StartSession,
  StopSession,
  Restart,
  Reset,
  Sync,
}

export function ChargerProfileCard() {
  const session = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    selectedActiveChargerSession
  );
  const refreshSessionInfo = useRecoilRefresher_UNSTABLE(
    chargingSessionsFilteredByIndicator({ chargerId: true })
  );

  const refreshActiveSession = useRecoilRefresher_UNSTABLE(
    chargingSessionsFilteredByIndicator({ chargerId: true, active: true })
  );

  const refreshSpaceSession = useRecoilRefresher_UNSTABLE(
    chargingSessionsFilteredByIndicator({ spaceId: true, active: true })
  );
  //TODO, change the charging sessions by indicator here to use the sidepanel filtering as well
  const refreshMySession = useRecoilRefresher_UNSTABLE(
    chargingSessionsFilteredByIndicator({ self: true })
  );

  const refreshChargerInfo = useRecoilRefresher_UNSTABLE(
    selectedChargerSelector
  );

  // Fix: Ugly for now, but does the job.
  const refresh = () => {
    refreshChargerInfo();
    refreshActiveSession();
    refreshSessionInfo();
    refreshSpaceSession();
    refreshMySession();
  };

  useEffect(() => {
    getChargingStatus();
  }, [session]);

  const authInfo = useRecoilValue(authAtom);
  const params = useParams();
  const [currentChargerId, setCurrentChargerId] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(selectedChargerIdAtom);

  if (params.chargerId && params.chargerId !== currentChargerId) {
    setCurrentChargerId(params.chargerId);
  }
  const [inTransition, startTransition] = useTransition();

  const [waitingForResponse, setWaitingForResponse] = useState<
    ConfirmModalType | undefined
  >();
  const [chargingStatus, setChargingStatus] = useState(
    SessionState.UnknownSessionState
  );

  const [modalType, setModalType] = useState<ConfirmModalType>();
  const [modalOpen, setModalOpen] = useState(false);

  const charger = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    selectedChargerSelector
  );
  if (!charger) {
    return null; //TODO: Return something better or show error
  }
  const chargerStatusImportance = statusToImportance(charger.Status);
  const chargerStatusName = statusToName(charger.Status);
  const chargerImg = getChargerPictureBySource(charger.ExternalSource);
  let fullDivClassName =
    "bg-diselDust-150 rounded-full p-2 flex-shrink-0 justify-center items-center flex";
  let fullIconClassName = "h-4 w-4";
  if (waitingForResponse) {
    fullIconClassName = fullIconClassName + " animate-spin";
  } else {
    fullIconClassName =
      fullIconClassName +
      "  group-hover:fill-white group-focus:fill-white group-active:fill-diselDust-400";
    fullDivClassName =
      fullDivClassName +
      " cursor-pointer hover:bg-black focus:bg-black active:bg-black group";
  }

  function ActiveConfirmModal() {
    switch (modalType) {
      case ConfirmModalType.Restart:
        return (
          <ConfirmModal
            open={modalOpen}
            toggleOpen={setModalOpen}
            description={"Are you sure you want to restart charger?"}
            title={"Confirm Restart Charger"}
            onConfirm={restartCharger}
          />
        );

      case ConfirmModalType.Reset:
        return (
          <ConfirmModal
            open={modalOpen}
            toggleOpen={setModalOpen}
            description={"Are you sure you want to reset charger?"}
            title={"Confirm Reset Charger"}
            onConfirm={resetCharger}
          />
        );
      case ConfirmModalType.StartSession:
        return (
          <ConfirmModal
            open={modalOpen}
            toggleOpen={setModalOpen}
            description={
              "Are you sure you want to start a session manually for this charger?"
            }
            title={"Confirm Start Session"}
            onConfirm={startSession}
          />
        );
      case ConfirmModalType.StopSession:
        return (
          <ConfirmModal
            open={modalOpen}
            toggleOpen={setModalOpen}
            description={
              "Are you sure you want to stop the session manually for this charger?"
            }
            title={"Confirm Stop Session"}
            onConfirm={stopSession}
          />
        );
      case ConfirmModalType.Sync:
        return (
          <ConfirmModal
            open={modalOpen}
            toggleOpen={setModalOpen}
            description={"Are you sure you want to Sync charger info?"}
            title={"Confirm Sync Charger Info"}
            onConfirm={callSyncCharger}
          />
        );
      default:
        return <div />;
    }
  }

  async function changeModalType(newType: ConfirmModalType) {
    setModalType(newType);
    setModalOpen(true);
  }

  async function restartCharger() {
    if (!charger) {
      return;
    }
    startTransition(() => {
      setWaitingForResponse(ConfirmModalType.Restart);
      commandChargerById(
        authInfo?.access_token,
        charger.Id,
        ChargerCommand.RESTART
      ).then(() => setWaitingForResponse(undefined));
    });
  }

  async function resetCharger() {
    if (!charger) {
      return;
    }
    startTransition(() => {
      setWaitingForResponse(ConfirmModalType.Reset);
      commandChargerById(
        authInfo?.access_token,
        charger.Id,
        ChargerCommand.RESET
      ).then(() => setWaitingForResponse(undefined));
    });
  }

  async function startSession() {
    if (charger?.Id) {
      setWaitingForResponse(ConfirmModalType.StartSession);
      await startSessionByChargerId(authInfo?.access_token, charger.Id);
      refresh();
    } else {
      console.error("No charger id found!");
    }
    setWaitingForResponse(undefined);
  }

  async function getChargingStatus() {
    if (session?.Id) {
      let stream = await getSelectedChargerState(
        authInfo?.access_token,
        session.Id
      );
      if (stream) {
        stream.subscribe((charger) => {
          setChargingStatus(charger.Status);
        });
      }
    }
    setChargingStatus(SessionState.UnknownSessionState);
  }

  async function stopSession() {
    if (session?.Id) {
      setWaitingForResponse(ConfirmModalType.StopSession);
      await stopSessionBySessionId(authInfo?.access_token, session.Id);
      refresh();
    } else {
      console.error("No session id found!");
    }
    setWaitingForResponse(undefined);
  }

  async function callSyncCharger() {
    if (!charger) {
      return;
    }
    startTransition(() => {
      setWaitingForResponse(ConfirmModalType.Sync);
      syncCharger(authInfo?.access_token, charger.Id).then(() => {
        setWaitingForResponse(undefined);
        refreshChargerInfo();
      });
    });
  }

  return (
    <div className="h-full bg-white flex flex-col border-diselDust-100 border">
      <ActiveConfirmModal />
      <div className="flex flex-row justify-between mx-10 pt-7 mb-11">
        {waitingForResponse ? (
          <RefreshIcon className={"animate-spin"} />
        ) : (
          <div className="flex flex-col">
            <Body3>Last Sync:</Body3>
            <Body3>{lastSyncToString(charger.LastSync)}</Body3>
          </div>
        )}
        <div className="flex flex-row justify-center items-center gap-2">
          <StatusIndicatorDisplay
            status={chargerStatusImportance}
            descriptor={chargerStatusName}
          />
          {charger.ExternalSource !== EvSource.GREENFLUX && (
            <div
              onClick={() => {
                changeModalType(ConfirmModalType.Sync);
              }}
              className={fullDivClassName}
            >
              <GenericIcon
                className={fullIconClassName}
                iconName={IconType.Refresh}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img className="h-44" src={`${chargerImg}`} />
      </div>
      <div className="flex justify-center items-center mt-9">
        <H3 className="px-32 font-medium truncate">
          {charger.DisplayName ? charger.DisplayName : charger.ExternalId}
        </H3>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center my-7">
        <ProfileCardButton
          text={
            waitingForResponse === ConfirmModalType.StartSession ? (
              <RefreshIcon className={"animate-spin"} />
            ) : (
              "Start Session"
            )
          }
          onClickFunction={() => {
            changeModalType(ConfirmModalType.StartSession);
          }}
          disabled={
            chargingStatus == SessionState.Active ||
            waitingForResponse !== undefined
          }
        />
        <ProfileCardButton
          text={
            waitingForResponse === ConfirmModalType.StopSession ? (
              <RefreshIcon className={"animate-spin"} />
            ) : (
              "Stop Session"
            )
          }
          onClickFunction={() => {
            changeModalType(ConfirmModalType.StopSession);
          }}
          disabled={
            chargingStatus !== SessionState.Active ||
            waitingForResponse !== undefined
          }
        />
        <ProfileCardButton
          text={
            waitingForResponse === ConfirmModalType.Restart ? (
              <RefreshIcon className={"animate-spin"} />
            ) : (
              "Reboot Charger"
            )
          }
          onClickFunction={() => {
            changeModalType(ConfirmModalType.Restart);
          }}
          disabled={waitingForResponse !== undefined}
        />
        <ProfileCardButton
          text={
            waitingForResponse === ConfirmModalType.Reset ? (
              <RefreshIcon className={"animate-spin"} />
            ) : (
              "Reset Charger"
            )
          }
          onClickFunction={() => {
            changeModalType(ConfirmModalType.Reset);
          }}
          disabled={waitingForResponse !== undefined}
        />
      </div>
    </div>
  );
}

function lastSyncToString(date: Date | undefined) {
  if (!date) {
    return "Never Synchronized";
  }
  const options: {
    year: "numeric" | "2-digit" | undefined;
    month: "numeric" | "2-digit" | undefined;
    day: "numeric" | "2-digit" | undefined;
    hour: "numeric" | "2-digit" | undefined;
    minute: "numeric" | "2-digit" | undefined;
  } = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat(["de-DE"], options).format(new Date(date));
}
