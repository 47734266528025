import _ from "lodash";
import React, { useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  currentChargersSelector,
} from "../../atoms/chargerAtom";
import { chargerFiltersAtom } from "../../atoms/filterAtoms";
import { selectedSpace } from "../../atoms/spaceAtom";
import { Footer } from "../generic/filterComponents/filterPanelFooter";
import { SidePanel } from "../generic/sidePanel";
import { H3 } from "../generic/typography";
import { ChargerFilters } from "./chargerFilters";
import { useSearchParams } from "react-router-dom";
import { updateDictionarySearchParams } from "../../utils/queryParamHandler";
import { useIntl } from 'react-intl';
import { i18n } from "../../i18n/langType";

interface ChargerFiltersSidePanelProps {
  open: boolean;
  toggleOpen: Function;
}

export function ChargerFiltersSidePanel({
  open,
  toggleOpen,
}: ChargerFiltersSidePanelProps) {
  const intl = useIntl();
  const currentSpace = useRecoilValue(selectedSpace);
  const curChargers = useRecoilValue(currentChargersSelector);
  const [topFilterSetting, setTopFilterSetting] =
    useRecoilState(chargerFiltersAtom);
  const [localFilterSetting, setLocalFilterSetting] =
    useState(topFilterSetting);
    const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (open) {
      setLocalFilterSetting(_.cloneDeep(topFilterSetting));
    }
  }, [open]);

  function applyFilterChanges() {
    setTopFilterSetting(localFilterSetting);
    toggleSidePanel();
  }

  React.useEffect(()=> {
    setSearchParams(updateDictionarySearchParams(topFilterSetting));
  }, [topFilterSetting])

  function toggleSidePanel() {
    toggleOpen();
  }

  return (
    <SidePanel
      toggleOpen={toggleSidePanel}
      open={open}
      footerComponent={
        <Footer
          applyChanges={applyFilterChanges}
          closePanel={toggleSidePanel}
        />
      }
    >
      {currentSpace && curChargers?.length ? (
        <ChargerFilters
          title={i18n.chargerFilters}
          open={open}
          closePanel={toggleSidePanel}
          localFilterSetting={localFilterSetting}
          setLocalFilterSetting={setLocalFilterSetting}
        />
      ) : (
        <H3 className="pt-20">... {intl.formatMessage({id:i18n.noChargersFound})} ... </H3>
      )}
    </SidePanel>
  );
}
