import { Outlet } from "react-router-dom";
import { Header } from "../components/navbar/header";
import { NavSidebar } from "../components/navbar/sidebar";

export function Layout() {
  return (
    <div className="h-full w-full flex flex-col">
      <Header />
      <div className="flex flex-row flex-grow overflow-hidden">
        <NavSidebar />
        <Outlet />
      </div>
    </div>
  );
}
