import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginPage";
import { ChargersPage } from "./pages/chargersPage";
import { SessionsPage } from "./pages/sessionsPage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ChargersListPage } from "./pages/chargersListPage";
import { ChargerDetailsPage } from "./pages/chargerDetailsPage";
import { Layout } from "./pages/layout";
import { AuthProvider, AuthProviderProps, useAuth, User } from "oidc-react";
import { useRecoilState } from "recoil";
import { authAtom } from "./atoms/authAtom";
import React from "react";
import { SessionsLoadingPage } from "./pages/sessionsLoadingPage";
import { config, getConfig } from "./utils/config";

export function AuthWrapper() {
  const config = getConfig(window.location.hostname);
  const oidcConfig: AuthProviderProps = {
    onSignIn: (user: User | null) => {
      console.log("LOGGED IN!", user);
    },
    authority: config.auth.authority,
    clientId: config.auth.clientId,
    redirectUri: config.auth.redirectUrl,
    autoSignIn: false,
    automaticSilentRenew: true,
    scope: "offline_access",
  };
  return (
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  );
}

export function App() {
  const [user, setUser] = useRecoilState(authAtom);
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.userData) {
      setUser(auth.userData);
    }
  }, [auth.userData?.access_token]);

  const loggedIn = auth.userData?.access_token != null && !auth.userData.expired;
  return (
    <div className="h-full w-full font-sans">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          {loggedIn ? (
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="/chargers" />} />
                <Route path="chargers" element={<ChargersPage />}>
                  <Route index element={<ChargersListPage />} />
                  <Route path=":chargerId" element={<ChargerDetailsPage />} />
                </Route>
                <Route path="sessions" element={<SessionsLoadingPage />}>
                  <Route index element={<SessionsPage />} />
                </Route>
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/"
                element={
                  auth.isLoading ? <div> Hang tight... logging you in </div> : <LoginPage loginSetter={auth.signIn} />
                }
              />
              <Route path="*" element={!auth.isLoading && <Navigate to="/" />} />
            </Routes>
          )}
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}
