import { H4 } from "../generic/typography";
import {
  FilterCategory,
  FilterDictionary,
  ValidChargerFilterId,
  ValidFilterId,
} from "../../types/filterTypes";
import { CheckboxFilterGrid } from "../generic/filterComponents/checkboxFilterGrid";
import { Filter } from "../generic/filterComponents/filterWrapper";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { chargersIndexSelector } from "../../atoms/chargerAtom";
import { useIntl } from "react-intl";
interface ChargerFiltersProps {
  title: string;
  open: boolean;
  closePanel: Function;
  localFilterSetting: FilterDictionary;
  setLocalFilterSetting: SetterOrUpdater<FilterDictionary>;
}

//TODO: Rename everything to better names, Ha Ha Ha
export function ChargerFilters(props: ChargerFiltersProps) {
  const indexedData = useRecoilValue(chargersIndexSelector);
  const rIntl = useIntl();

  function setFilterByKey(
    key: string,
    newValue: FilterCategory,
  ): void {
    const newSetting = { ...props.localFilterSetting, [key]: newValue };
    props.setLocalFilterSetting(newSetting);
  }

  function getAggregationData(filterName: ValidFilterId) {
    return indexedData.aggregation({
      name: filterName as ValidChargerFilterId,
    });
  }

  return (
    <div className="flex flex-col pt-20 overflow-scroll w-full">
      <div>
        <div className="pb-9 mb-9 border-b border-diselDust">
          <H4 className="font-normal">{rIntl.formatMessage({id:props.title})}</H4>
        </div>
        <div className="flex flex-col gap-12">
          <Filter title={rIntl.formatMessage({id:props.localFilterSetting.Status?.displayTitle})}>
            <CheckboxFilterGrid
              filterCategory={props.localFilterSetting.Status}
              setState={setFilterByKey}
              getAggregationData={getAggregationData}
            />
          </Filter>
          <Filter title={rIntl.formatMessage({id:props.localFilterSetting.ExternalSource?.displayTitle})}>
            <CheckboxFilterGrid
              filterCategory={props.localFilterSetting.ExternalSource}
              setState={setFilterByKey}
              getAggregationData={getAggregationData}
            />
          </Filter>
        </div>
      </div>
    </div>
  );
}
