import { IconType, GenericIcon } from "./genericIcon";
import { Body3, Body5 } from "./typography";

interface SingleLineIconInfoDisplayProps {
  iconType: IconType;
  text: string;
  iconFillColor?: string;
}

function SingleLineIconInfoDisplay({
  iconType,
  text,
  iconFillColor,
}: SingleLineIconInfoDisplayProps) {
  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      <GenericIcon
        className={`w-4 h-4 fill-${iconFillColor}`}
        iconName={iconType}
      />
      <Body3>{text}</Body3>
    </div>
  );
}

function TwoLineInfoDisplay({
  topText,
  bottomText,
  topIconName,
  bottomIconName,
  iconFillColor,
}: IconInfoProps) {
  return (
    <div className="flex gap-3">
      <div className="flex flex-col gap-2 justify-center">
        <GenericIcon
          className={`w-3 h-3 fill-${iconFillColor}`}
          iconName={topIconName}
        />
        {bottomIconName && (
          <GenericIcon
            className={`w-3 h-3 fill-${iconFillColor}`}
            iconName={bottomIconName}
          />
        )}
      </div>
      <div className="flex flex-col gap-2 justify-center">
        <Body5>{topText}</Body5>
        {bottomText && <Body5>{bottomText}</Body5>}
      </div>
    </div>
  );
}

interface IconInfoProps {
  topText: string;
  bottomText?: string;
  topIconName: IconType;
  bottomIconName?: IconType;
  iconFillColor?: string;
}

export function IconInfoDisplay({
  topText,
  bottomText,
  topIconName,
  bottomIconName,
  iconFillColor,
}: IconInfoProps) {
  if (topText && bottomText) {
    return (
      <TwoLineInfoDisplay
        topText={topText}
        bottomText={bottomText}
        topIconName={topIconName}
        bottomIconName={bottomIconName}
        iconFillColor={iconFillColor}
      />
    );
  }
  return (
    <SingleLineIconInfoDisplay
      iconType={topIconName}
      text={topText}
      iconFillColor={iconFillColor}
    />
  );
}
