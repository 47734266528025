import React from "react";
import { Suspense, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SearchHeader } from "../components/generic/searchHeader";
import { Body1 } from "../components/generic/typography";
import { SessionFiltersSidePanel } from "../components/session/sessionFiltersSidePanel";
import { SessionList } from "../components/session/sessionList";
import { FilterDictionary } from "../types/filterTypes";
import { buildFiltersFromURL } from "../utils/queryParamHandler";
import { sessionFiltersAtom, sessionSearchAtom } from "../atoms/filterAtoms";
import { defaultSessionFilters } from "../atoms/defaultFilterJson";
import { sessionSortingAtom } from "../atoms/sessionSortingAtom";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";

export function SessionsPage() {
  const intl = useIntl();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [filters, setFilters] =
    useRecoilState<FilterDictionary>(sessionFiltersAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const currentFilters = buildFiltersFromURL(
      filters,
      searchParams,
      defaultSessionFilters
    );
    setFilters(currentFilters);
  }, [searchParams]);

  function toggleSidePanel() {
    setSidePanelOpen((sidePanelOpen) => !sidePanelOpen);
  }

  return (
    <div className="w-full h-full bg-diselDust-50 flex flex-col my-10">
      <div className="h-[calc(100%_-_5rem)] overflow-scroll">
        <Suspense
          fallback={
            <div className="p-4 w-full px-10">
              <Body1>{intl.formatMessage({id:i18n.loadingSessions})}</Body1>
            </div>
          }
        >
          <div>
            <SearchHeader
              placeholder={intl.formatMessage({id:i18n.loadingFiltering})}
              toggleSidePanel={toggleSidePanel}
              sidePanelOpen={sidePanelOpen}
              filterAtom={sessionFiltersAtom}
              sortSettingAtom={sessionSortingAtom}
              searchQueryAtom={sessionSearchAtom}
            />
            <Suspense>
              <SessionFiltersSidePanel
                open={sidePanelOpen}
                toggleOpen={toggleSidePanel}
              />
            </Suspense>
            <div className="px-10 py-5">
              <Body1 className="mb-2">{intl.formatMessage({id:i18n.allSessions})}</Body1>
              <SessionList sessionFilters={{}} />
            </div>
          </div>
        </Suspense>
      </div>
    </div>
  );
}
