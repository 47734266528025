import { EvSource } from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";

export function sourceToName(status: EvSource) {
  switch (status) {
    case EvSource.INTERNAL:
      return "INTERNAL";
    case EvSource.DEFA:
      return "DEFA";
    case EvSource.EASEE:
      return "EASEE";
    case EvSource.GREENFLUX:
      return "GREENFLUX";
    case EvSource.SCHNEIDER:
      return "SCHNEIDER";
    case EvSource.ZAPTEC:
      return "ZAPTEC";
    default:
      return "UNRECOGNIZED";
  }
}
