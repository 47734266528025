import React, { Suspense, useState } from "react";
import { ChargerTileGrid } from "../components/charger/chargerTileList";
import { SearchHeader } from "../components/generic/searchHeader";
import { chargerFiltersAtom, chargerSearchAtom } from "../atoms/filterAtoms";
import { chargerSortingAtom } from "../atoms/chargerSortingAtom";
import _ from "lodash";
import { Body1 } from "../components/generic/typography";
import { ChargerFiltersSidePanel } from "../components/charger/chargerFiltersSidePanel";
import { useRecoilState } from "recoil";
import { useSearchParams } from "react-router-dom";
import { FilterDictionary } from "../types/filterTypes";
import { buildFiltersFromURL } from "../utils/queryParamHandler";
import { defaultChargerFilters } from "../atoms/defaultFilterJson";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";

export function ChargersListPage() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const rIntl = useIntl();

  const [filters, setFilters] =
    useRecoilState<FilterDictionary>(chargerFiltersAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const currentFilters = buildFiltersFromURL(
      filters,
      searchParams,
      defaultChargerFilters
    );

    setFilters(currentFilters);
  }, [searchParams]);

  function toggleSidePanel() {
    setSidePanelOpen((sidePanelOpen) => !sidePanelOpen);
  }

  return (
    <div className="w-full h-full bg-diselDust-50 flex flex-col">
      <Suspense
        fallback={
          <div>
            <Body1>{rIntl.formatMessage({id:i18n.loadingChargers})}</Body1>
          </div>
        }
      >
        <SearchHeader
          placeholder=""
          toggleSidePanel={toggleSidePanel}
          sidePanelOpen={sidePanelOpen}
          filterAtom={chargerFiltersAtom}
          sortSettingAtom={chargerSortingAtom}
          searchQueryAtom={chargerSearchAtom}
        />
      </Suspense>
      <Suspense>
        <ChargerFiltersSidePanel
          open={sidePanelOpen}
          toggleOpen={toggleSidePanel}
        />
      </Suspense>
      <div className="flex-grow overflow-scroll">
        <Suspense
          fallback={
            <div className="p-4 w-full px-10">
              <Body1>{rIntl.formatMessage({id:i18n.loadingChargers})}</Body1>
            </div>
          }
        >
          <ChargerTileGrid />
        </Suspense>
      </div>
    </div>
  );
}
