import { Body2, Body3, Body5 } from "../generic/typography";
import { StatusIndicatorDisplay } from "../generic/statusIndicatorDisplay";
import { statusToImportance, statusToName } from "../../types/status";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedChargerIdAtom } from "../../atoms/chargerAtom";

import { getChargerPictureBySource } from "../../types/charger";
import { Charger } from "../../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { i18n } from "../../i18n/langType";
import { useIntl } from "react-intl";
import { ArrowIcon } from "../generic/icons";

interface ChargerTileProps {
  charger: Charger;
}
export function ChargerTile({ charger }: ChargerTileProps): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const [currentChargerId, setCurrentChargerId] = useRecoilState(selectedChargerIdAtom);
  if (!charger || Object.keys(charger).length === 0 || !charger.Id) {
    return <div />;
  }
  const chargerId = charger.Id;
  const chargerImage = getChargerPictureBySource(charger.ExternalSource);
  const evseId = charger.ExternalId;
  const chargerStatus = statusToImportance(charger.Status);
  const chargerStatusName = statusToName(charger.Status);

  function redirectToDetailsPage() {
    setCurrentChargerId(chargerId);
    navigate(`${chargerId}`);
  }

  return (
    <div
      onClick={redirectToDetailsPage}
      className="grid gap-2 items-center bg-white px-5 py-4 divide-x border border-diselDust-100 grid-cols-8"
    >
      <div className="col-span-2 flex flex-row items-center gap-1">
        <div className="col-span-2 flex flex-row items-center gap-2">
          <img className="h-8" alt="charger" src={chargerImage} />
          <div className="text-ellipsis">
            <Body5>{intl.formatMessage({ id: i18n.displayName })}</Body5>
            <Body2>{charger.DisplayName || evseId}</Body2>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex flex-col px-2">
        <Body5>{intl.formatMessage({ id: i18n.id })}</Body5>
        <Body3 className="font-bold">{charger.Id}</Body3>
      </div>
      <div className="col-span-1 flex flex-col px-2">
        <Body5>{intl.formatMessage({ id: i18n.status })}</Body5>
        <Body2>
          <StatusIndicatorDisplay status={chargerStatus} descriptor={chargerStatusName} />{" "}
        </Body2>
      </div>
      <div className="col-span-1 flex flex-col px-2">
        <Body5>{intl.formatMessage({ id: i18n.space })}</Body5>
        <Body2 className="font-bold">
          {charger.SpaceName === (undefined || null || "") ? "N/A" : charger.SpaceName}
        </Body2>
      </div>
      <div className="col-span-1 flex flex-col px-2">
        <Body5>{intl.formatMessage({ id: i18n.lastStatusUpdate })}</Body5>
        <Body2 className="font-bold">
          {charger.LastStatusUpdate?.toLocaleDateString()} {charger.LastStatusUpdate?.toLocaleTimeString()}
        </Body2>
      </div>
      <div className="col-span-1 flex flex-col px-2">
        <Body5>{intl.formatMessage({ id: i18n.lastPowerDelivery })}</Body5>
        <Body2 className="font-bold">
          {charger.LastPowerDelivery === (undefined || null || "") ? "N/A" : charger.LastPowerDelivery}
        </Body2>
      </div>
      <div className="col-span-1 flex justify-center gap-4">
        <ArrowIcon className={"h-5 w-5"} />
      </div>
    </div>
  );
}
