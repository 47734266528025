import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import {
  filteredChargingSessions,
  FilterIndicators,
} from "../../atoms/sessionsAtom";
import { SessionEntry } from "./sessionEntry";
import { useIntl } from 'react-intl';
import { i18n } from "../../i18n/langType";

interface SessionListProps {
  short?: boolean;
  sessionFilters: Readonly<FilterIndicators>;
}
export function SessionList({ short, sessionFilters }: SessionListProps) {
  const sessions = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    filteredChargingSessions(sessionFilters)
  );
  const intl = useIntl();
  
  if (!sessions) {
    return <div>{intl.formatMessage({id:i18n.noSessions})}</div>;
  }
  return (
    <div className="flex flex-col gap-4 overflow-auto h-full w-full">
      {sessions.map((session) => {
        return (
          <SessionEntry
            short={short}
            key={`sessionTile_${session.Id}`}
            session={session}
            setSelectedSession={() => {}}
          />
        );
      })}
    </div>
  );
}
