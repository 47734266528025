import { Body2 } from "./typography";
import { RecoilState, useRecoilState } from "recoil";
import ClickAwayListener from "@mui/base/ClickAwayListener/ClickAwayListener";
import { SortSetting, SortingSettings, ValidSortId } from "../../types/filterTypes";
import { useIntl } from "react-intl";
import { i18n } from "../../i18n/langType";

interface SortMenuProps {
  open: boolean;
  sortSettingsAtom: RecoilState<SortingSettings>;
  clickAwayCloseMenuFunction: Function;
}

export function SortMenu({
  open,
  sortSettingsAtom,
  clickAwayCloseMenuFunction,
}: SortMenuProps) {
  const rIntl = useIntl();
  //TODO: have the filter options passed in and out instead of self contained logic
  //TODO: Generate the rows via filter options

  const [sortSettings, setSortSettings] = useRecoilState(sortSettingsAtom);

  function getFalseDictionary() {
    let falseDictionary: SortingSettings = {};
    Object.values(sortSettings).map((settingValue) => {
      if (settingValue.id) {
        falseDictionary[settingValue.id] = { ...settingValue, selected: false };
      }
    });
    return falseDictionary;
  }

  function toggleEntry(value: ValidSortId) {
    const falseDictionary = getFalseDictionary();
    if (value) {
      setSortSettings({
        ...falseDictionary,
        [value]: {
          ...sortSettings[value],
          selected: !sortSettings[value].selected,
        },
      });
    }
  }

  function closeSortMenu() {
    clickAwayCloseMenuFunction(false);
  }

  if (open) {
    return (
      <ClickAwayListener onClickAway={closeSortMenu}>
        <div className="flex flex-col pt-4 absolute top-24 bg-white border border-diselDust-150 w-90 rounded-md shadow-lg">
          <div>
            <div className="border-b border-dieselDust pl-6 pb-2">
              <Body2 className="font-bold">{rIntl.formatMessage({id:i18n.sortBy})}</Body2>
            </div>
            <div className="flex flex-col divide-y px-6">
              {Object.values(sortSettings).map((setting) => {
                return (
                  <FilterCheckboxRow
                    key={`checkboxRow_${setting.id}`}
                    toggleSortValue={toggleEntry}
                    setting={setting}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
  return null;
}

interface FilterCheckboxRow {
  toggleSortValue: (value: ValidSortId) => void;
  setting: SortSetting;
}

function FilterCheckboxRow({ setting, toggleSortValue }: FilterCheckboxRow) {
  const intl = useIntl();
  function toggleValue() {
    toggleSortValue(setting.id);
  }
  return (
    <div className="flex flex-row gap-4 items-center py-4 hover:bg-diselDust-25">
      <input
        type="checkbox"
        onChange={toggleValue}
        checked={setting.selected}
        className="accent-black h-4 w-4 active:accent-diselDust-750 hover:accent-diselDust-750"
      />
      <Body2 className="font-normal">{intl.formatMessage({id:setting.label})}</Body2>
    </div>
  );
}
