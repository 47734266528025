import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmModalProps {
  open: boolean;
  toggleOpen: Function;
  onConfirm: Function;
  title: string;
  description: string;
}
export default function ConfirmModal(props: ConfirmModalProps) {
  function confirm() {
    props.onConfirm();
    props.toggleOpen();
  }

  function toggleModal() {
    props.toggleOpen();
  }
  return (
    <Dialog open={props.open} onClose={toggleModal}>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <div>{props.description}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal}>Cancel</Button>
        <Button onClick={confirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
