import {
  FacilityContactInfo,
  InformationPanel,
} from "../components/generic/informationPanel";
import { Facility } from "../types/facility";
import { ChargerProfileCard } from "../components/charger/chargerProfileCard";
import { Suspense } from "react";
import { Body1 } from "../components/generic/typography";
import { SessionHistoryDisplay } from "../components/session/sessionHistory";

const dummyFacility: Facility = {
  id: "00001",
  name: "FacilityPlaceholder",
  contactNumber: "+49000000",
  abbreviation: "01",
};

export function ChargerDetailsPage() {
  const facility = dummyFacility;

  return (
    <div className="overflow-scroll">
      <div className="grid grid-rows-6 grid-cols-2 grid-flow-col gap-4 mx-10 mt-14">
        <div className="col-span-1 row-span-5 gap-4">
          <Suspense fallback={<Body1>Loading...</Body1>}>
            <InformationPanel />
          </Suspense>
        </div>
        <div className="col-span-1 row-span-1">
          <FacilityContactInfo facility={facility} />
        </div>
        <div className="col-span-1 row-span-6">
          <Suspense fallback={<Body1>Loading...</Body1>}>
            <ChargerProfileCard />
          </Suspense>
        </div>
      </div>
      <div className="py-8 px-10">
        <Suspense fallback={<Body1>Loading...</Body1>}>
          <SessionHistoryDisplay />
        </Suspense>
      </div>
    </div>
  );
}
