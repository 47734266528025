import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "./en.json";
import { Translations } from "./langType";

const defaultValue: any = "en";
export const Context = React.createContext(defaultValue);
const defaultLocale = navigator.language;
let lang: Translations = { en: English }[defaultLocale] ?? English; // TODO, change so it works the same as in spo

const TranslationWrapper = (props: any) => {
  const [locale, setLocale] = useState(defaultLocale);
  const [messages, setMessages] = useState(lang);
  function selectLanguage(e: any) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    switch (newLocale) {
      case "en":
        setMessages(English);
        break;
      case "en-us":
        setMessages(English);
        break;
      // case 'de':setMessages(German);
      // break;
      default:
        setMessages(English);
    }
  }
  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages as unknown as Record<string, string>} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default TranslationWrapper;
