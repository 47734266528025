import dayjs from "dayjs";
import { ChargingSessionState } from "../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import {
  EvSource,
  Status,
} from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import {
  FilterCategory,
  FilterDictionary,
  TemporalOperator,
} from "../types/filterTypes";
import { i18n } from "../i18n/langType";

const defaultGridFilters: FilterCategory = { //i18n is done at rendering, display text should be an i18n id
  displayTitle: i18n.lastKnownState,
  id: "Status",
  options: {
    available: {
      displayName: i18n.available,
      id: "available",
      selected: false,
      value: Status.AVAILABLE,
      path: ["Status", "available"],
    },
    charging: {
      displayName: i18n.charging,
      id: "charging",
      selected: false,
      value: Status.CHARGING,
      path: ["Status", "charging"],
    },
    blocked: {
      displayName: i18n.blocked,
      id: "blocked",
      selected: false,
      value: Status.BLOCKED,
      path: ["Status", "blocked"],
    },
    inoperative: {
      displayName: i18n.inoperative,
      id: "inoperative",
      selected: false,
      value: Status.INOPERATIVE,
      path: ["Status", "inoperative"],
    },
    outoforder: {
      displayName: i18n.outOfOrder,
      id: "outoforder",
      selected: false,
      value: Status.OUTOFORDER,
      path: ["Status", "outoforder"],
    },
    planned: {
      displayName: i18n.planned,
      id: "planned",
      selected: false,
      value: Status.PLANNED,
      path: ["Status", "planned"],
    },
    removed: {
      displayName: i18n.removed,
      id: "removed",
      selected: false,
      value: Status.REMOVED,
      path: ["Status", "removed"],
    },
    reserved: {
      displayName: i18n.reserved,
      id: "reserved",
      selected: false,
      value: Status.RESERVED,
      path: ["Status", "reserved"],
    },
    unknown: {
      displayName: i18n.unknownOffline,
      id: "unknown",
      selected: false,
      value: Status.Status_UNKNOWN,
      path: ["Status", "unknown"],
    },
    unrecognized: {
      displayName: i18n.unrecognized,
      id: "unrecognized",
      selected: false,
      value: Status.UNRECOGNIZED,
      path: ["Status", "unrecognized"],
    },
  },
};

const defaultEVFilters: FilterCategory = {
  displayTitle: i18n.evSource,
  id: "ExternalSource",
  options: {
    greenflux: {
      displayName: i18n.greenflux,
      id: "greenflux",
      selected: false,
      value: EvSource.GREENFLUX,
      path: ["ExternalSource", "greenflux"],
    },
    zaptec: {
      displayName: i18n.zaptec,
      id: "zaptec",
      selected: false,
      value: EvSource.ZAPTEC,
      path: ["ExternalSource", "zaptec"],
    },
    schneider: {
      displayName: i18n.schneider,
      id: "schneider",
      selected: false,
      value: EvSource.SCHNEIDER,
      path: ["ExternalSource", "schneider"],
    },
    defa: {
      displayName: i18n.defa,
      id: "defa",
      selected: false,
      value: EvSource.DEFA,
      path: ["ExternalSource", "defa"],
    },
    easee: {
      displayName: i18n.easee,
      id: "easee",
      selected: false,
      value: EvSource.EASEE,
      path: ["ExternalSource", "easee"],
    },
    chargenode: {
      displayName: "ChargeNode",
      id: "chargenode",
      selected: false,
      value: EvSource.TEST,
      path: ["ExternalSource", "chargenode"],
    },
    unrecognized: {
      displayName: i18n.unrecognized,
      id: "unrecognized",
      selected: false,
      value: EvSource.UNRECOGNIZED,
      path: ["ExternalSource", "unrecognized"],
    },
  },
};

const defaultSessionTimeFilters: FilterCategory = {
  displayTitle: i18n.time,
  id: "Time",
  options: {
    startTime: {
      displayName: i18n.startSessionTime,
      id: "startTime",
      selected: false,
      value: {
        from: dayjs().toDate(),
        to: dayjs().toDate(),
        operator: TemporalOperator.OLDER,
      },
      path: ["Time", "startTime"],
    },
    endTime: {
      displayName: i18n.endSessionTime,
      id: "endTime",
      selected: false,
      value: {
        from: dayjs().toDate(),
        to: dayjs().toDate(),
        operator: TemporalOperator.OLDER,
      },
      path: ["Time", "endTime"],
    },
    createdAtTime: {
      displayName: i18n.sessionCreationTime,
      id: "createdAtTime",
      selected: false,
      value: {
        from: dayjs().toDate(),
        to: dayjs().toDate(),
        operator: TemporalOperator.OLDER,
      },
      path: ["Time", "createdAtTime"],
    },
  },
};

const defaultSessionStatusFilters: FilterCategory = {
  displayTitle: i18n.sessionStatus,
  id: "ChargingSessionState",
  options: {
    charging: {
      displayName: i18n.charging,
      id: "charging",
      selected: false,
      value: ChargingSessionState.CHARGING,
      path: ["ChargingSessionState", "charging"],
    },
    error: {
      displayName: i18n.error,
      id: "error",
      selected: false,
      value: ChargingSessionState.ERROR,
      path: ["ChargingSessionState", "error"],
    },
    parking: {
      displayName: i18n.parking,
      id: "parking",
      selected: false,
      value: ChargingSessionState.PARKING,
      path: ["ChargingSessionState", "parking"],
    },
    pending: {
      displayName: i18n.pending,
      id: "pending",
      selected: false,
      value: ChargingSessionState.PENDING,
      path: ["ChargingSessionState", "pending"],
    },
    requested: {
      displayName: i18n.requested,
      id: "requested",
      selected: false,
      value: ChargingSessionState.REQUESTED,
      path: ["ChargingSessionState", "requested"],
    },
    started: {
      displayName: i18n.started,
      id: "started",
      selected: false,
      value: ChargingSessionState.STARTED,
      path: ["ChargingSessionState", "started"],
    },
    starting: {
      displayName: i18n.starting,
      id: "starting",
      selected: false,
      value: ChargingSessionState.STARTING,
      path: ["ChargingSessionState", "starting"],
    },
    stopping: {
      displayName: i18n.stopping,
      id: "stopping",
      selected: false,
      value: ChargingSessionState.STOPPING,
      path: ["ChargingSessionState", "stopping"],
    },
    finished: {
      displayName: i18n.finished,
      id: "finished",
      selected: false,
      value: ChargingSessionState.FINISHED,
      path: ["ChargingSessionState", "finished"],
    },
    paid: {
      displayName: i18n.paid,
      id: "paid",
      selected: false,
      value: ChargingSessionState.PAID,
      path: ["ChargingSessionState", "paid"],
    },
    cdravailable: {
      displayName: i18n.cdrAvailable,
      id: "cdravailable",
      selected: false,
      value: ChargingSessionState.CDR_AVAILABLE,
      path: ["ChargingSessionState", "cdravailable"],
    },
    unknown: {
      displayName: i18n.unknown,
      id: "unknown",
      selected: false,
      value: ChargingSessionState.UNKNOWN,
      path: ["ChargingSessionState", "unknown"],
    },
    unrecognized: {
      displayName: i18n.unrecognized,
      id: "unrecognized",
      selected: false,
      value: ChargingSessionState.UNRECOGNIZED,
      path: ["ChargingSessionState", "unrecognized"],
    },
  },
};
export const defaultChargerFilters: FilterDictionary = {
  Status: defaultGridFilters,
  ExternalSource: defaultEVFilters,
};

export const defaultSessionFilters: FilterDictionary = {
  Time: defaultSessionTimeFilters,
  ChargingSessionState: defaultSessionStatusFilters,
};