import { Body2, Body3 } from "../generic/typography";
import { GenericButton } from "../generic/buttons";
import { GenericIcon, IconType } from "../generic/genericIcon";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atoms/authAtom";

interface ProfilePanelProps {
  //picture
  //profile info such as name,
  //business name
  //role
  //This will probably be handled by atom instead of props actually when we get individual's data or login credentials
  closePanel: Function;
}

export function ProfilePanel(props: ProfilePanelProps) {
  const user = useRecoilValue(authAtom);
  return (
    <div className="flex flex-col pt-16 overflow-scroll">
      <ProfilePictureWidget />
      <div className="flex flex-col mt-14 mb-6 gap-2">
        <Body2>{"UserNamePlaceHolder"}</Body2>
        <Body3 className="text-diselDust-600 font-normal">
          EmailPlaceholder
        </Body3>
      </div>
      <div className="flex flex-col gap-2">
        <Body2 className="text-diselDust-600 font-normal">CompanyName</Body2>
        <Body3 className="text-diselDust-400 font-normal">
          RolePlaceholder
        </Body3>
      </div>
    </div>
  );
}

const IconBadge = (props: { iconName: IconType }) => {
  return (
    <div className="rounded-full h-4 w-4 bg-diselDust-100 absolute bottom-0 right-0 flex justify-center items-center">
      <GenericIcon
        iconName={props.iconName}
        className="text-white text-bold text-center h-3 w-3"
      />
    </div>
  );
};

interface ProfileFooterProps {
  logout: Function;
  closePanel: Function;
}

export function ProfileFooter(props: ProfileFooterProps) {
  return (
    <div className="flex flex-row gap-4">
      <GenericButton text="Close" onClick={props.closePanel} cancelButton />
      <GenericButton text="Logout" onClick={props.logout} />
    </div>
  );
}

interface ProfilePictureWidgetProps {}
function ProfilePictureWidget(props: ProfilePictureWidgetProps) {
  return (
    <div className="relative h-16 w-16">
      <img src="/profilePicture.jpg" className="rounded-full" />
      <IconBadge iconName={IconType.Coin} />
    </div>
  ); //TODO: Add picture Icon
}
