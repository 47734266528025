import { Type, Enum, Field } from "protobufjs";
import {
  FieldDescriptorProto_Label,
  FieldDescriptorProto_Type,
} from "ts-proto-descriptors";
import {
  protoMetadata,
  SearchLiteralOperator,
  ValuePosition,
} from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";

export const SearchValueBytes = (path: string, value: any) => {
  const currentLevel = protoMetadata.fileDescriptor.messageType;
  const charger = currentLevel.find((x) => x.name == "Charger");
  const now = charger?.field.find((x) => x.name == path);
  const is_list = now!.label == FieldDescriptorProto_Label.LABEL_REPEATED;
  const is_enum = now!.type == FieldDescriptorProto_Type.TYPE_ENUM;

  if (is_enum) {
    var Message = new Type("Temp")
      .add(new Enum("Temp"))
      .add(new Field(path, 1, "Temp"));
    var payload = { [path]: value };
    var msg = Message.create(payload);
    return Message.encode(msg).finish();
  }

  if (now!.type == FieldDescriptorProto_Type.TYPE_STRING) {
    var Message = new Type("Temp").add(new Field(path, 1, "string"));
    var payload = { [path]: value };
    var msg = Message.create(payload);
    return Message.encode(msg).finish();
  }
};

export const Literal = (
  op: SearchLiteralOperator,
  path: string,
  value: any
) => {
  return {
    Operator: op,
    Path: path,
    Value: { Value: SearchValueBytes(path, value) },
    ValuePosition: ValuePosition.Left,
  };
};

export const Eq = (path: string, value: any) =>
  Literal(SearchLiteralOperator.Equal, path, value);
