import { Body5 } from "./typography";
import dayjs from "dayjs";

interface FromUntilRowItemProps {
  startTime?: Date;
  endTime?: Date;
}

export function FromUntilRowItem({
  startTime,
  endTime,
}: FromUntilRowItemProps) {
  const startValueFormatted = startTime
    ? dayjs(startTime).format("hh:mm A")
    : "Uninitiated";
  const endValueFormatted = endTime
    ? dayjs(endTime).format("hh:mm A")
    : "Ongoing";
  return (
    <div className="flex gap-1 text-diselDust">
      <div className="flex flex-col gap-1 text-diselDust">
        <Body5 className="uppercase">From:</Body5>
        <Body5 className="uppercase">Till:</Body5>
      </div>
      <div className="flex flex-col gap-1 text-diselDust">
        <Body5 className="text-black">{startValueFormatted}</Body5>
        <Body5 className="text-black">{endValueFormatted}</Body5>
      </div>
    </div>
  );
}
