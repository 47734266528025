import { useState } from "react";
import { RecoilState } from "recoil";
import { sessionFiltersAtom, sessionSearchAtom } from "../../atoms/filterAtoms";
import { FilterDictionary, SortingSettings } from "../../types/filterTypes";
import { FilterPills } from "../generic/filterComponents/filterPills";
import { IconType } from "../generic/genericIcon";
import { FilterButton, PageSearchBar } from "../generic/searchHeader";
import { SortMenu } from "../generic/sortMenu";
import { Body1 } from "../generic/typography";
import { SessionFiltersSidePanel } from "./sessionFiltersSidePanel";
import { SessionList } from "./sessionList";
import { sessionSortingAtom } from "../../atoms/sessionSortingAtom";

export function SessionHistoryDisplay() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  function toggleSidePanel() {
    setSidePanelOpen((sidePanelOpen) => !sidePanelOpen);
  }

  return (
    <div className="px-10 pb-8 bg-white border border-diselDust-100">
      <div className="pt-5 px-6 bg-white">
        <div className=" border-diselDust-100 border-b pb-5">
          <Body1>Session History</Body1>
        </div>
        <SessionHistoryHeader
          placeholder="Type charger display name or user..."
          toggleSidePanel={toggleSidePanel}
          sidePanelOpen={sidePanelOpen}
          filterAtom={sessionFiltersAtom}
          sortSettingAtom={sessionSortingAtom}
          searchQueryAtom={sessionSearchAtom}
        />
        <SessionFiltersSidePanel
          open={sidePanelOpen}
          toggleOpen={toggleSidePanel}
        />
        <SessionList sessionFilters={{ chargerId: true }} />
      </div>
    </div>
  );
}

interface FilterHeaderSectionProps {
  placeholder: string;
  toggleSidePanel: Function;
  sidePanelOpen: boolean;
  filterAtom: RecoilState<FilterDictionary>;
  sortSettingAtom: RecoilState<SortingSettings>;
  searchQueryAtom: RecoilState<string>;
}

export function SessionHistoryHeader(props: FilterHeaderSectionProps) {
  //TODO: Consider making existing search header more generic, with styling options since functionality is identical to searchHeader
  const [sortOpen, setSortOpen] = useState(false);

  function sortMenuToggleOpen() {
    setSortOpen((sortOpen) => !sortOpen);
  }

  function clickAwayCloseMenuFunction() {
    setSortOpen(false); //Needed because click listener will always fire, even if object is already closed
  }
  return (
    <div className="flex flex-col">
      <div className="gap-4 flex flex-row items-center py-10 px-10">
        <div className="relative flex flex-row gap-4">
          <SortMenu
            open={sortOpen}
            sortSettingsAtom={props.sortSettingAtom}
            clickAwayCloseMenuFunction={clickAwayCloseMenuFunction}
          />
          <FilterButton
            filterIcon={IconType.Sort}
            sortMenuToggle={sortMenuToggleOpen}
            isOpen={sortOpen}
          />
          <FilterButton
            filterIcon={IconType.Filter}
            toggleSidePanel={props.toggleSidePanel}
            isOpen={props.sidePanelOpen}
          />
        </div>
        <PageSearchBar
          searchQueryAtom={props.searchQueryAtom}
          placeholder={props.placeholder}
        />
        {/*TODO: Make existing search component more generic for styling, as functionality should be identical*/}
      </div>
      <FilterPills filterAtom={props.filterAtom} className="px-10 pb-8" />
    </div>
  );
}
