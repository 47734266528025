import { ReactNode } from "react";

interface TextTagProps {
  children?: ReactNode;
  className?: string;
}

export function H1({ children, className }: TextTagProps) {
  const fullClass = "text-H1 font-helveticaRegular " + className;
  return <h1 className={fullClass}>{children}</h1>;
}

export function H2({ children, className }: TextTagProps) {
  const fullClass = "text-H2 font-helveticaRegular " + className;
  return <h2 className={fullClass}>{children}</h2>;
}

export function H3({ children, className }: TextTagProps) {
  const fullClass = "text-H3 font-helveticaRegular " + className;
  return <h3 className={fullClass}>{children}</h3>;
}

export function H4({ children, className }: TextTagProps) {
  const fullClass = "text-H4 font-helveticaRegular " + className;
  return <h4 className={fullClass}>{children}</h4>;
}

export function H5({ children, className }: TextTagProps) {
  const fullClass = "text-H5 font-HELVETICANEU " + className;
  return <h5 className={fullClass}>{children}</h5>;
}

export function Body1({ children, className }: TextTagProps) {
  const fullClass = "text-B1 font-helveticaRegular " + className;
  return <div className={fullClass}>{children}</div>;
}

export function Body2({ children, className }: TextTagProps) {
  const fullClass = "text-B2 font-helveticaRegular " + className;
  return <div className={fullClass}>{children}</div>;
}

export function Body3({ children, className }: TextTagProps) {
  const fullClass = "text-B3 font-helveticaRegular " + className;
  return <div className={fullClass}>{children}</div>;
}

export function Body4({ children, className }: TextTagProps) {
  const fullClass = "text-B4 font-helveticaRegular " + className;
  return <div className={fullClass}>{children}</div>;
}

export function Body5({ children, className }: TextTagProps) {
  const fullClass = "text-B5 font-helveticaRegular " + className;
  return <div className={fullClass}>{children}</div>;
}

interface AbbrIconProps {
  abbreviation?: string;
  className?: string;
}
export function AbbrIcon({ abbreviation, className }: AbbrIconProps) {
  if (!abbreviation) {
    return null;
  }
  const fullClassName =
    "rounded-full border flex justify-center items-center bg-slate-600 text-white " +
    className;
  return <div className={fullClassName}>{abbreviation}</div>;
}
