import { BrowserHeaders } from "browser-headers";
import {
  GrpcWebImpl,
  Space,
  SpaceServiceClientImpl,
} from "../generated/clients/mapi/ispaceservice/evmng.mapi.ISpaceService";
import { getApiUrl } from "../utils/config";

function initClient(token: string) {
  return new SpaceServiceClientImpl(
    new GrpcWebImpl(getApiUrl(), {
      debug: false,
      metadata: new BrowserHeaders({ Authorization: "Bearer " + token }),
    })
  );
}

const CommonEmptySpaceData = {
  Owner: undefined,
  BillingDelayAfterSessionEnds: undefined,
  Localization: undefined,
  Location: undefined,
  Address: undefined,
  ParentSpaces: [],
  Consumables: [],
  OpeningHours: undefined,
};

export const getSpaces = async (token: string) => {
  try {
    const spaceService = initClient(token);
    const responseData = await spaceService.GetSpaces({
      Version: "2.2",
    });
    let backendSpaces = responseData.data.map((space) => Space.fromJSON(space));
    const allChargersSpace: Space = {
      Id: { Value: "AllChargers" },
      Name: "All Chargers",
      ...CommonEmptySpaceData,
    };
    backendSpaces.unshift(allChargersSpace);
    return backendSpaces;
  } catch (err: any) {
    console.log("Error in getSpaces() => ", err);
    return [];
  }
};
