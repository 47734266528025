import { useRecoilValue } from "recoil";
import { currentChargersSelector } from "../../atoms/chargerAtom";
import { Charger } from "../../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { isAvailable, isBroken, isBusy } from "../../utils/chargerUtil";
import { Body3, Body4 } from "../generic/typography";

export function ChargerSummary() {
  const currentChargers = useRecoilValue(currentChargersSelector);
  if (!currentChargers) {
    return <div></div>;
  }
  const { available, busy, broken, total } =
    getChargerStatsPerSpace(currentChargers);
  return (
    <div className="w-96 px-6">
      <div className="flex flex-row gap-2 font-bold items-center font-helveticaBold">
        <Body3>{`Available Chargers:`}</Body3>
        <Body3>{`${available} of ${total}`}</Body3>
      </div>
      <div className="flex flex-row gap-6 items-center font-normal">
        <Body4>{`not working: ${broken}`}</Body4>
        <Body4>{`busy: ${busy}`}</Body4>
      </div>
    </div>
  );
}

export function getChargerStatsPerSpace(chargers: Charger[]) {
  if (!chargers) {
    return {
      busy: 0,
      available: 0,
      broken: 0,
      total: 0,
    };
  }
  const totalNumberOfChargers = chargers.length;
  let busyChargers = 0;
  let availableChargers = 0;
  let brokenChargers = 0;
  for (let charger of chargers) {
    if (isAvailable(charger.Status)) {
      availableChargers++;
    } else if (isBroken(charger.Status)) {
      brokenChargers++;
    } else if (isBusy(charger.Status)) {
      busyChargers++;
    }
  }
  return {
    busy: busyChargers,
    available: availableChargers,
    broken: brokenChargers,
    total: totalNumberOfChargers,
  };
}
