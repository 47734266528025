import { atom } from "recoil";
import {
  defaultChargerFilters,
  defaultSessionFilters,
} from "./defaultFilterJson";

export const chargerFiltersAtom = atom({
  key: "chargerFiltersAtom",
  default: defaultChargerFilters,
});

export const chargerSearchAtom = atom({
  key: "chargerSearchAtom",
  default: "",
});

export const sessionFiltersAtom = atom({
  key: "sessionFiltersAtom",
  default: defaultSessionFilters,
});

export const sessionSearchAtom = atom({
  key: "sessionSearchAtom",
  default: "",
});

export const ticketFilterAtom = atom({
  key: "ticketFilterAtom",
  default: defaultChargerFilters,
});

export const ticketSearchAtom = atom({
  key: "ticketSearchAtom",
  default: "",
});
