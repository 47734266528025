import { IconType } from "../components/generic/genericIcon";
import * as itemsjs from "itemsjs";
import { ChargingSessionState } from "../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import { ChargingSession } from "../generated/clients/mapi/ichargingsessionsservice/evmng.mapi.IChargingSessionsService";
import {
  Charger,
  EvSource,
  Status,
} from "../generated/clients/mapi/ichargerservice/evmng.mapi.IChargerService";
import { ChargerSortIdType as ChargerSortId } from "../atoms/chargerSortingAtom";
import { SessionSortIdType as SessionSortId } from "../atoms/sessionSortingAtom";

export type ValidChargerFilterId = "Status" | "ExternalId" | "ExternalSource";
export type ValidSessionFilterId = "Time" | "ChargingSessionState";
//TODO: Add Sessions and such to valid filterNames
export type ValidFilterId = ValidChargerFilterId | ValidSessionFilterId;
export interface FilterDictionary {
  [key: string]: FilterCategory;
}

export type ValidSortId = ChargerSortId | SessionSortId;
export interface SortSetting {
  id: ValidSortId;
  selected: boolean;
  label: string;
}
export interface SortingSettings {
  [key: string]: SortSetting;
}

type OptionId = string;
type SubOptionId = string;

export interface FilterCategory {
  id: ValidFilterId; //id and dictionary index
  displayTitle: string;
  options: FilterOptions;
}

export interface FilterOptions {
  [key: string]: FilterOption;
}

export interface FilterOption {
  id: OptionId | SubOptionId; //should be the same as key for dict
  displayName: string;
  selected: boolean;
  value: TemporalFilterValue | Status | EvSource | ChargingSessionState;
  iconType?: IconType;
  subFilters?: FilterOptions;
  path: [ValidFilterId, OptionId] | [ValidFilterId, OptionId, SubOptionId];
}

export interface TemporalFilterValue {
  from: Date | null | undefined;
  to: Date | null | undefined;
  operator: TemporalOperator;
}

export enum TemporalOperator { //todo: Add display names somehow?
  OLDER,
  NEWER,
  RANGE,
}

export type ValidBucketTypes = Charger | ChargingSession;
export interface FilterComponentProps<T extends {}> {
  filterCategory: FilterCategory;
  setState: Function;
  getAggregationData: (filterName: ValidFilterId) => {
    data: {
      buckets: itemsjs.Buckets<T>;
    };
    pagination: itemsjs.Pagination;
  };
}
