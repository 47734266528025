import { ChargerSummary } from "./chargerSummary";
import { RefreshWidget } from "./refreshWidget";
import { NotificationCenter } from "./notificationCenter";
import { DropDownHeaderZoneSelector } from "./zoneDropDown";
import { ImportanceLevel } from "../../types/importanceLevel";
import { Suspense, useState } from "react";
import { SidePanel } from "../generic/sidePanel";
import { ProfilePanel, ProfileFooter } from "./profilePanel";
import { useAuth } from "oidc-react";
import { AccountIcon, APCOAChargingLogoIcon } from "../generic/icons";
import ConfirmModal from "../generic/confirmModal";

const dummyNotificationData = {
  notifications: [
    {
      description: "Car smashed charger 1",
      timestamp: "10:00 June 23rd",
      importance: ImportanceLevel.Emergency,
    },
    {
      description: "Charger 123 gained sentience",
      timestamp: "13:00 July 18th",
      importance: ImportanceLevel.Warning,
      abbreviation: "AI",
    },
  ],
};

export function Header() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function toggleSidePanel() {
    setSidePanelOpen((sidePanelOpen) => !sidePanelOpen);
  }

  const auth = useAuth();
  async function logout() {
    await auth.signOut();
    window.location.reload();
  }

  return (
    <div className="bg-honeyYellow h-20 flex justify-between items-center p-6">
      <div className="px-2 whitespace-pre-line flex flex-col align-center justify-center text-center">
        <APCOAChargingLogoIcon />
      </div>
      <Suspense>
        <DropDownHeaderZoneSelector />
        <ChargerSummary />
        <RefreshWidget />
      </Suspense>
      <SidePanel
        open={sidePanelOpen}
        toggleOpen={toggleSidePanel}
        footerComponent={
          <ProfileFooter logout={toggleModal} closePanel={toggleSidePanel} />
        }
      >
        <ProfilePanel closePanel={toggleSidePanel} />
        <ConfirmModal
          toggleOpen={toggleModal}
          open={modalOpen}
          onConfirm={logout}
          title={"Are you sure you want to log out?"}
          description={"Confirm to log out"}
        />
      </SidePanel>
      <div className="pl-4 flex flex-row gap-6 justify-center items-center">
        <div
          onClick={toggleSidePanel}
          className="cursor-pointer h-8 w-8 flex flex-row"
        >
          <AccountIcon />
        </div>
      </div>
    </div>
  );
}
