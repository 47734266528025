import _ from "lodash";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { sessionFiltersAtom } from "../../atoms/filterAtoms";
import { Footer } from "../generic/filterComponents/filterPanelFooter";
import { SidePanel } from "../generic/sidePanel";
import { SessionFilters } from "./sessionFilters";
import { updateDictionarySearchParams } from "../../utils/queryParamHandler";
import { useSearchParams } from "react-router-dom";

interface SidePanelProps {
  open: boolean;
  toggleOpen: Function;
}
export function SessionFiltersSidePanel({ open, toggleOpen }: SidePanelProps) {
  const [topFilterSetting, setTopFilterSetting] =
    useRecoilState(sessionFiltersAtom);
  const [localFilterSetting, setLocalFilterSetting] =
    useState(topFilterSetting);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (!open) {
      const tempFilter = _.cloneDeep(topFilterSetting);
      setLocalFilterSetting(tempFilter);
    }
  }, [open, topFilterSetting]);

  React.useEffect(()=> {
    setSearchParams(updateDictionarySearchParams(topFilterSetting));
  }, [topFilterSetting])

  function applyFilterChanges() {
    setTopFilterSetting(localFilterSetting);
    toggleSidePanel();
  }

  function toggleSidePanel() {
    toggleOpen();
  }

  return (
    <SidePanel
      open={open}
      toggleOpen={toggleSidePanel}
      footerComponent={
        <Footer
          applyChanges={applyFilterChanges}
          closePanel={toggleSidePanel}
        />
      }
    >
      <SessionFilters
        title="Session Filters"
        open={open}
        closePanel={toggleSidePanel}
        localFilterSetting={localFilterSetting}
        setLocalFilterSetting={setLocalFilterSetting}
      />
    </SidePanel>
  );
}