const prodConfig = {
  auth: {
    authority: "https://auth.prd.nucleus.valuespaces.io",
    clientId: "dashboard.prd.evmng.valuespaces.io",
    redirectUrl: "https://dashboard.prd.evmng.valuespaces.io/",
  },
  apiUrl: "https://mapi.prd.evmng.valuespaces.io",
};

const devConfig = {
  auth: {
    authority: "https://auth.dev.nucleus.valuespaces.io",
    clientId: "dashboard.dev.evmng.valuespaces.io",
    redirectUrl: "https://dashboard.dev.evmng.valuespaces.io/",
  },
  apiUrl: "https://mapi.dev.evmng.valuespaces.io",
};

const intConfig = {
  auth: {
    authority: "https://auth.int.nucleus.valuespaces.io",
    clientId: "dashboard.int.evmng.valuespaces.io",
    redirectUrl: "https://dashboard.int.evmng.valuespaces.io/",
  },
  apiUrl: "https://mapi.int.evmng.valuespaces.io",
};

const localConfig = {
  auth: {
    authority: "https://auth.dev.nucleus.valuespaces.io",
    clientId: "dashboard.dev.evmng.valuespaces.io",
    redirectUrl: "https://localhost:44416/",
  },
  apiUrl: "http://localhost:5180",
};

export const config = {
  local: localConfig,
  dev: devConfig,
  int: intConfig,
  prd: prodConfig,
};

export const getConfig = (hostname: string) => {
  if (hostname.includes("dev")) {
    return config["dev"];
  } else if (hostname.includes("int")) {
    return config["int"];
  } else if (hostname.includes("prd")) {
    return config["prd"];
  } else {
    return config["local"];
  }
};

export const getApiUrl = (): string => {
  const self = window.location.hostname;
  const apiUrl = getConfig(self).apiUrl;
  console.log("URL: returned", apiUrl);
  return apiUrl;
};
