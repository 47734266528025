import { useIntl } from "react-intl";
import {
  FilterComponentProps,
  FilterOption,
  FilterOptions,
  FilterCategory,
} from "../../../types/filterTypes";
import { Body2 } from "../typography";
import * as itemsjs from "itemsjs";

export function CheckboxFilterGrid<T extends {}>(
  props: FilterComponentProps<T>
) {
  const intl = useIntl();
  const items = props.filterCategory.options;
  let aggregationList: itemsjs.Buckets<T> = [];
  let aggregationKeys: string[] = [];
  // TODO: Why exactly does this fail?!
  try {
    const aggregation = props.getAggregationData(props.filterCategory.id);
    aggregationList = aggregation.data.buckets;
  } catch (e) {
    console.error("could not aggregate data. will return empty list: ", e);
  }
  aggregationKeys = aggregationList.map((aggregation) => aggregation.key);

  function changeCheckbox(optionKey: string) {
    const newOption: FilterOption = {
      ...items[optionKey],
      selected: !items[optionKey].selected,
    };
    const newOptions: FilterOptions = {
      ...props.filterCategory.options,
      [optionKey]: newOption,
    };
    const newCategory: FilterCategory = {
      ...props.filterCategory,
      options: newOptions,
    };
    props.setState(props.filterCategory.id, newCategory, newOption);
  }
  //TODO: Change the background color of the checkboxes to transparent, change border to correct dust color
  return (
    <div className="grid grid-cols-2 gap-6">
      {Object.values(items).map((option) => {
        let className = "accent-black h-6 w-6";
        let disabled = false;
        if (!aggregationKeys.includes(option.value?.toString())) {
          disabled = true;
        }
        const keySuffix = `${option.path[0]}_${option.path[1]}_${option.path[2]}`;
        return (
          <div
            key={`checkBoxFilterGridItem_${keySuffix}`}
            className="flex flex-row gap-5"
          >
            <input
              type={"checkbox"}
              className={className}
              checked={option.selected}
              id={`inputGridFilterItem_${keySuffix}`}
              onChange={() => {
                changeCheckbox(option.id);
              }}
              disabled={disabled}
            />
            <Body2 className="font-normal">{intl.formatMessage({id:option.displayName})}</Body2>
          </div>
        );
      })}
    </div>
  );
}
