import { atom } from "recoil";
import { SortingSettings } from "../types/filterTypes";
import { i18n } from "../i18n/langType";

export type ChargerSortIdType =
  | "alphabetical_asc"
  | "alphabetical_desc"
  | "updated_asc"
  | "updated_desc";
export const defaultChargerSorting: SortingSettings = {
  updated_asc: { id: "updated_asc", selected: false, label: i18n.dateAscending },
  updated_desc: {
    id: "updated_desc",
    selected: false,
    label: i18n.dateDescending,
  },
  alphabetical_asc: {
    id: "alphabetical_asc",
    selected: false,
    label: i18n.alphAscending,
  },
  alphabetical_desc: {
    id: "alphabetical_desc",
    selected: false,
    label: i18n.alphDescending,
  },
};

export const chargerSortingAtom = atom({
  key: "chargerSorting",
  default: defaultChargerSorting,
});
