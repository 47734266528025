/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { FileDescriptorProto } from "ts-proto-descriptors";
import { protoMetadata as protoMetadata1, Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "EvMng.Services.ManagementApi.Abstractions.ChargingSessions";

export enum ChargingSessionState {
  PENDING = 0,
  STARTED = 1,
  REQUESTED = 2,
  ERROR = 3,
  FINISHED = 4,
  PARKING = 5,
  CHARGING = 6,
  STARTING = 7,
  STOPPING = 8,
  CDR_AVAILABLE = 9,
  PAID = 10,
  UNKNOWN = 11,
  ABORTED = 12,
  PAYMENT_CANCELLED = 13,
  UNRECOGNIZED = -1,
}

export function chargingSessionStateFromJSON(object: any): ChargingSessionState {
  switch (object) {
    case 0:
    case "PENDING":
      return ChargingSessionState.PENDING;
    case 1:
    case "STARTED":
      return ChargingSessionState.STARTED;
    case 2:
    case "REQUESTED":
      return ChargingSessionState.REQUESTED;
    case 3:
    case "ERROR":
      return ChargingSessionState.ERROR;
    case 4:
    case "FINISHED":
      return ChargingSessionState.FINISHED;
    case 5:
    case "PARKING":
      return ChargingSessionState.PARKING;
    case 6:
    case "CHARGING":
      return ChargingSessionState.CHARGING;
    case 7:
    case "STARTING":
      return ChargingSessionState.STARTING;
    case 8:
    case "STOPPING":
      return ChargingSessionState.STOPPING;
    case 9:
    case "CDR_AVAILABLE":
      return ChargingSessionState.CDR_AVAILABLE;
    case 10:
    case "PAID":
      return ChargingSessionState.PAID;
    case 11:
    case "UNKNOWN":
      return ChargingSessionState.UNKNOWN;
    case 12:
    case "ABORTED":
      return ChargingSessionState.ABORTED;
    case 13:
    case "PAYMENT_CANCELLED":
      return ChargingSessionState.PAYMENT_CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChargingSessionState.UNRECOGNIZED;
  }
}

export function chargingSessionStateToJSON(object: ChargingSessionState): string {
  switch (object) {
    case ChargingSessionState.PENDING:
      return "PENDING";
    case ChargingSessionState.STARTED:
      return "STARTED";
    case ChargingSessionState.REQUESTED:
      return "REQUESTED";
    case ChargingSessionState.ERROR:
      return "ERROR";
    case ChargingSessionState.FINISHED:
      return "FINISHED";
    case ChargingSessionState.PARKING:
      return "PARKING";
    case ChargingSessionState.CHARGING:
      return "CHARGING";
    case ChargingSessionState.STARTING:
      return "STARTING";
    case ChargingSessionState.STOPPING:
      return "STOPPING";
    case ChargingSessionState.CDR_AVAILABLE:
      return "CDR_AVAILABLE";
    case ChargingSessionState.PAID:
      return "PAID";
    case ChargingSessionState.UNKNOWN:
      return "UNKNOWN";
    case ChargingSessionState.ABORTED:
      return "ABORTED";
    case ChargingSessionState.PAYMENT_CANCELLED:
      return "PAYMENT_CANCELLED";
    case ChargingSessionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SessionState {
  UnknownSessionState = 0,
  Planned = 1,
  Active = 2,
  Ended = 3,
  Billed = 4,
  CanceledSessionState = 1000,
  ErrorSessionState = 1001,
  UNRECOGNIZED = -1,
}

export function sessionStateFromJSON(object: any): SessionState {
  switch (object) {
    case 0:
    case "UnknownSessionState":
      return SessionState.UnknownSessionState;
    case 1:
    case "Planned":
      return SessionState.Planned;
    case 2:
    case "Active":
      return SessionState.Active;
    case 3:
    case "Ended":
      return SessionState.Ended;
    case 4:
    case "Billed":
      return SessionState.Billed;
    case 1000:
    case "CanceledSessionState":
      return SessionState.CanceledSessionState;
    case 1001:
    case "ErrorSessionState":
      return SessionState.ErrorSessionState;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SessionState.UNRECOGNIZED;
  }
}

export function sessionStateToJSON(object: SessionState): string {
  switch (object) {
    case SessionState.UnknownSessionState:
      return "UnknownSessionState";
    case SessionState.Planned:
      return "Planned";
    case SessionState.Active:
      return "Active";
    case SessionState.Ended:
      return "Ended";
    case SessionState.Billed:
      return "Billed";
    case SessionState.CanceledSessionState:
      return "CanceledSessionState";
    case SessionState.ErrorSessionState:
      return "ErrorSessionState";
    case SessionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ChargingSession {
  Id: string;
  Start: Date | undefined;
  End: Date | undefined;
  Kwh: number;
  ChargerId: string;
  ChargerDisplayName: string;
  SpaceId: string;
  SessionState: string;
  ChargingSessionState: ChargingSessionState;
  NucleusSessionId: string;
  LicensePlateId: string;
  CreatedAt: Date | undefined;
}

export interface ChargingSessionStatus {
  Status: SessionState;
}

export interface CreateChargingSessionRequest {
  ChargerId: string;
  LicensePlateId: string;
}

export interface EvResponseChargingSession {
  timestamp: string;
  data: ChargingSession | undefined;
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface EvResponseIListChargingSession {
  timestamp: string;
  data: ChargingSession[];
  statusMessage: string;
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
}

export interface GetChargingSessionRequest {
  ChargingSessionId: string;
}

export interface GetChargingSessionsRequest {
  ChargerId: string;
  SpaceId: string;
  Active: boolean;
  Self: boolean;
}

export interface StopChargingSessionRequest {
  ChargingSessionId: string;
}

function createBaseChargingSession(): ChargingSession {
  return {
    Id: "",
    Start: undefined,
    End: undefined,
    Kwh: 0,
    ChargerId: "",
    ChargerDisplayName: "",
    SpaceId: "",
    SessionState: "",
    ChargingSessionState: 0,
    NucleusSessionId: "",
    LicensePlateId: "",
    CreatedAt: undefined,
  };
}

export const ChargingSession = {
  encode(message: ChargingSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Id !== "") {
      writer.uint32(10).string(message.Id);
    }
    if (message.Start !== undefined) {
      Timestamp.encode(toTimestamp(message.Start), writer.uint32(18).fork()).ldelim();
    }
    if (message.End !== undefined) {
      Timestamp.encode(toTimestamp(message.End), writer.uint32(26).fork()).ldelim();
    }
    if (message.Kwh !== 0) {
      writer.uint32(37).float(message.Kwh);
    }
    if (message.ChargerId !== "") {
      writer.uint32(42).string(message.ChargerId);
    }
    if (message.ChargerDisplayName !== "") {
      writer.uint32(50).string(message.ChargerDisplayName);
    }
    if (message.SpaceId !== "") {
      writer.uint32(58).string(message.SpaceId);
    }
    if (message.SessionState !== "") {
      writer.uint32(66).string(message.SessionState);
    }
    if (message.ChargingSessionState !== 0) {
      writer.uint32(72).int32(message.ChargingSessionState);
    }
    if (message.NucleusSessionId !== "") {
      writer.uint32(82).string(message.NucleusSessionId);
    }
    if (message.LicensePlateId !== "") {
      writer.uint32(90).string(message.LicensePlateId);
    }
    if (message.CreatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.CreatedAt), writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargingSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargingSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Id = reader.string();
          break;
        case 2:
          message.Start = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.End = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Kwh = reader.float();
          break;
        case 5:
          message.ChargerId = reader.string();
          break;
        case 6:
          message.ChargerDisplayName = reader.string();
          break;
        case 7:
          message.SpaceId = reader.string();
          break;
        case 8:
          message.SessionState = reader.string();
          break;
        case 9:
          message.ChargingSessionState = reader.int32() as any;
          break;
        case 10:
          message.NucleusSessionId = reader.string();
          break;
        case 11:
          message.LicensePlateId = reader.string();
          break;
        case 12:
          message.CreatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargingSession {
    return {
      Id: isSet(object.Id) ? String(object.Id) : "",
      Start: isSet(object.Start) ? fromJsonTimestamp(object.Start) : undefined,
      End: isSet(object.End) ? fromJsonTimestamp(object.End) : undefined,
      Kwh: isSet(object.Kwh) ? Number(object.Kwh) : 0,
      ChargerId: isSet(object.ChargerId) ? String(object.ChargerId) : "",
      ChargerDisplayName: isSet(object.ChargerDisplayName) ? String(object.ChargerDisplayName) : "",
      SpaceId: isSet(object.SpaceId) ? String(object.SpaceId) : "",
      SessionState: isSet(object.SessionState) ? String(object.SessionState) : "",
      ChargingSessionState: isSet(object.ChargingSessionState)
        ? chargingSessionStateFromJSON(object.ChargingSessionState)
        : 0,
      NucleusSessionId: isSet(object.NucleusSessionId) ? String(object.NucleusSessionId) : "",
      LicensePlateId: isSet(object.LicensePlateId) ? String(object.LicensePlateId) : "",
      CreatedAt: isSet(object.CreatedAt) ? fromJsonTimestamp(object.CreatedAt) : undefined,
    };
  },

  toJSON(message: ChargingSession): unknown {
    const obj: any = {};
    message.Id !== undefined && (obj.Id = message.Id);
    message.Start !== undefined && (obj.Start = message.Start.toISOString());
    message.End !== undefined && (obj.End = message.End.toISOString());
    message.Kwh !== undefined && (obj.Kwh = message.Kwh);
    message.ChargerId !== undefined && (obj.ChargerId = message.ChargerId);
    message.ChargerDisplayName !== undefined && (obj.ChargerDisplayName = message.ChargerDisplayName);
    message.SpaceId !== undefined && (obj.SpaceId = message.SpaceId);
    message.SessionState !== undefined && (obj.SessionState = message.SessionState);
    message.ChargingSessionState !== undefined &&
      (obj.ChargingSessionState = chargingSessionStateToJSON(message.ChargingSessionState));
    message.NucleusSessionId !== undefined && (obj.NucleusSessionId = message.NucleusSessionId);
    message.LicensePlateId !== undefined && (obj.LicensePlateId = message.LicensePlateId);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargingSession>, I>>(object: I): ChargingSession {
    const message = createBaseChargingSession();
    message.Id = object.Id ?? "";
    message.Start = object.Start ?? undefined;
    message.End = object.End ?? undefined;
    message.Kwh = object.Kwh ?? 0;
    message.ChargerId = object.ChargerId ?? "";
    message.ChargerDisplayName = object.ChargerDisplayName ?? "";
    message.SpaceId = object.SpaceId ?? "";
    message.SessionState = object.SessionState ?? "";
    message.ChargingSessionState = object.ChargingSessionState ?? 0;
    message.NucleusSessionId = object.NucleusSessionId ?? "";
    message.LicensePlateId = object.LicensePlateId ?? "";
    message.CreatedAt = object.CreatedAt ?? undefined;
    return message;
  },
};

function createBaseChargingSessionStatus(): ChargingSessionStatus {
  return { Status: 0 };
}

export const ChargingSessionStatus = {
  encode(message: ChargingSessionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Status !== 0) {
      writer.uint32(8).int32(message.Status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargingSessionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargingSessionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChargingSessionStatus {
    return { Status: isSet(object.Status) ? sessionStateFromJSON(object.Status) : 0 };
  },

  toJSON(message: ChargingSessionStatus): unknown {
    const obj: any = {};
    message.Status !== undefined && (obj.Status = sessionStateToJSON(message.Status));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChargingSessionStatus>, I>>(object: I): ChargingSessionStatus {
    const message = createBaseChargingSessionStatus();
    message.Status = object.Status ?? 0;
    return message;
  },
};

function createBaseCreateChargingSessionRequest(): CreateChargingSessionRequest {
  return { ChargerId: "", LicensePlateId: "" };
}

export const CreateChargingSessionRequest = {
  encode(message: CreateChargingSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargerId !== "") {
      writer.uint32(10).string(message.ChargerId);
    }
    if (message.LicensePlateId !== "") {
      writer.uint32(18).string(message.LicensePlateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateChargingSessionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateChargingSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargerId = reader.string();
          break;
        case 2:
          message.LicensePlateId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateChargingSessionRequest {
    return {
      ChargerId: isSet(object.ChargerId) ? String(object.ChargerId) : "",
      LicensePlateId: isSet(object.LicensePlateId) ? String(object.LicensePlateId) : "",
    };
  },

  toJSON(message: CreateChargingSessionRequest): unknown {
    const obj: any = {};
    message.ChargerId !== undefined && (obj.ChargerId = message.ChargerId);
    message.LicensePlateId !== undefined && (obj.LicensePlateId = message.LicensePlateId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateChargingSessionRequest>, I>>(object: I): CreateChargingSessionRequest {
    const message = createBaseCreateChargingSessionRequest();
    message.ChargerId = object.ChargerId ?? "";
    message.LicensePlateId = object.LicensePlateId ?? "";
    return message;
  },
};

function createBaseEvResponseChargingSession(): EvResponseChargingSession {
  return { timestamp: "", data: undefined, statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseChargingSession = {
  encode(message: EvResponseChargingSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.data !== undefined) {
      ChargingSession.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseChargingSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseChargingSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data = ChargingSession.decode(reader, reader.uint32());
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseChargingSession {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: isSet(object.data) ? ChargingSession.fromJSON(object.data) : undefined,
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseChargingSession): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    message.data !== undefined && (obj.data = message.data ? ChargingSession.toJSON(message.data) : undefined);
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseChargingSession>, I>>(object: I): EvResponseChargingSession {
    const message = createBaseEvResponseChargingSession();
    message.timestamp = object.timestamp ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? ChargingSession.fromPartial(object.data)
      : undefined;
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseEvResponseIListChargingSession(): EvResponseIListChargingSession {
  return { timestamp: "", data: [], statusMessage: "", isSuccess: false, errorCode: "", errorMessage: "" };
}

export const EvResponseIListChargingSession = {
  encode(message: EvResponseIListChargingSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    for (const v of message.data) {
      ChargingSession.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.statusMessage !== "") {
      writer.uint32(26).string(message.statusMessage);
    }
    if (message.isSuccess === true) {
      writer.uint32(32).bool(message.isSuccess);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    if (message.errorMessage !== "") {
      writer.uint32(50).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvResponseIListChargingSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvResponseIListChargingSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = reader.string();
          break;
        case 2:
          message.data.push(ChargingSession.decode(reader, reader.uint32()));
          break;
        case 3:
          message.statusMessage = reader.string();
          break;
        case 4:
          message.isSuccess = reader.bool();
          break;
        case 5:
          message.errorCode = reader.string();
          break;
        case 6:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EvResponseIListChargingSession {
    return {
      timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
      data: Array.isArray(object?.data) ? object.data.map((e: any) => ChargingSession.fromJSON(e)) : [],
      statusMessage: isSet(object.statusMessage) ? String(object.statusMessage) : "",
      isSuccess: isSet(object.isSuccess) ? Boolean(object.isSuccess) : false,
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : "",
    };
  },

  toJSON(message: EvResponseIListChargingSession): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp);
    if (message.data) {
      obj.data = message.data.map((e) => e ? ChargingSession.toJSON(e) : undefined);
    } else {
      obj.data = [];
    }
    message.statusMessage !== undefined && (obj.statusMessage = message.statusMessage);
    message.isSuccess !== undefined && (obj.isSuccess = message.isSuccess);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EvResponseIListChargingSession>, I>>(
    object: I,
  ): EvResponseIListChargingSession {
    const message = createBaseEvResponseIListChargingSession();
    message.timestamp = object.timestamp ?? "";
    message.data = object.data?.map((e) => ChargingSession.fromPartial(e)) || [];
    message.statusMessage = object.statusMessage ?? "";
    message.isSuccess = object.isSuccess ?? false;
    message.errorCode = object.errorCode ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseGetChargingSessionRequest(): GetChargingSessionRequest {
  return { ChargingSessionId: "" };
}

export const GetChargingSessionRequest = {
  encode(message: GetChargingSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargingSessionId !== "") {
      writer.uint32(10).string(message.ChargingSessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetChargingSessionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChargingSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargingSessionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetChargingSessionRequest {
    return { ChargingSessionId: isSet(object.ChargingSessionId) ? String(object.ChargingSessionId) : "" };
  },

  toJSON(message: GetChargingSessionRequest): unknown {
    const obj: any = {};
    message.ChargingSessionId !== undefined && (obj.ChargingSessionId = message.ChargingSessionId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetChargingSessionRequest>, I>>(object: I): GetChargingSessionRequest {
    const message = createBaseGetChargingSessionRequest();
    message.ChargingSessionId = object.ChargingSessionId ?? "";
    return message;
  },
};

function createBaseGetChargingSessionsRequest(): GetChargingSessionsRequest {
  return { ChargerId: "", SpaceId: "", Active: false, Self: false };
}

export const GetChargingSessionsRequest = {
  encode(message: GetChargingSessionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargerId !== "") {
      writer.uint32(10).string(message.ChargerId);
    }
    if (message.SpaceId !== "") {
      writer.uint32(18).string(message.SpaceId);
    }
    if (message.Active === true) {
      writer.uint32(24).bool(message.Active);
    }
    if (message.Self === true) {
      writer.uint32(32).bool(message.Self);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetChargingSessionsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChargingSessionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargerId = reader.string();
          break;
        case 2:
          message.SpaceId = reader.string();
          break;
        case 3:
          message.Active = reader.bool();
          break;
        case 4:
          message.Self = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetChargingSessionsRequest {
    return {
      ChargerId: isSet(object.ChargerId) ? String(object.ChargerId) : "",
      SpaceId: isSet(object.SpaceId) ? String(object.SpaceId) : "",
      Active: isSet(object.Active) ? Boolean(object.Active) : false,
      Self: isSet(object.Self) ? Boolean(object.Self) : false,
    };
  },

  toJSON(message: GetChargingSessionsRequest): unknown {
    const obj: any = {};
    message.ChargerId !== undefined && (obj.ChargerId = message.ChargerId);
    message.SpaceId !== undefined && (obj.SpaceId = message.SpaceId);
    message.Active !== undefined && (obj.Active = message.Active);
    message.Self !== undefined && (obj.Self = message.Self);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetChargingSessionsRequest>, I>>(object: I): GetChargingSessionsRequest {
    const message = createBaseGetChargingSessionsRequest();
    message.ChargerId = object.ChargerId ?? "";
    message.SpaceId = object.SpaceId ?? "";
    message.Active = object.Active ?? false;
    message.Self = object.Self ?? false;
    return message;
  },
};

function createBaseStopChargingSessionRequest(): StopChargingSessionRequest {
  return { ChargingSessionId: "" };
}

export const StopChargingSessionRequest = {
  encode(message: StopChargingSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ChargingSessionId !== "") {
      writer.uint32(10).string(message.ChargingSessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StopChargingSessionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopChargingSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChargingSessionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StopChargingSessionRequest {
    return { ChargingSessionId: isSet(object.ChargingSessionId) ? String(object.ChargingSessionId) : "" };
  },

  toJSON(message: StopChargingSessionRequest): unknown {
    const obj: any = {};
    message.ChargingSessionId !== undefined && (obj.ChargingSessionId = message.ChargingSessionId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StopChargingSessionRequest>, I>>(object: I): StopChargingSessionRequest {
    const message = createBaseStopChargingSessionRequest();
    message.ChargingSessionId = object.ChargingSessionId ?? "";
    return message;
  },
};

export interface ChargingSessionsService {
  CreateChargingSession(
    request: DeepPartial<CreateChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession>;
  GetChargingSession(
    request: DeepPartial<GetChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession>;
  GetChargingSessions(
    request: DeepPartial<GetChargingSessionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseIListChargingSession>;
  GetChargingSessionState(
    request: DeepPartial<GetChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Observable<ChargingSessionStatus>;
  StopChargingSession(
    request: DeepPartial<StopChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession>;
}

export class ChargingSessionsServiceClientImpl implements ChargingSessionsService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateChargingSession = this.CreateChargingSession.bind(this);
    this.GetChargingSession = this.GetChargingSession.bind(this);
    this.GetChargingSessions = this.GetChargingSessions.bind(this);
    this.GetChargingSessionState = this.GetChargingSessionState.bind(this);
    this.StopChargingSession = this.StopChargingSession.bind(this);
  }

  CreateChargingSession(
    request: DeepPartial<CreateChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession> {
    return this.rpc.unary(
      ChargingSessionsServiceCreateChargingSessionDesc,
      CreateChargingSessionRequest.fromPartial(request),
      metadata,
    );
  }

  GetChargingSession(
    request: DeepPartial<GetChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession> {
    return this.rpc.unary(
      ChargingSessionsServiceGetChargingSessionDesc,
      GetChargingSessionRequest.fromPartial(request),
      metadata,
    );
  }

  GetChargingSessions(
    request: DeepPartial<GetChargingSessionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseIListChargingSession> {
    return this.rpc.unary(
      ChargingSessionsServiceGetChargingSessionsDesc,
      GetChargingSessionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetChargingSessionState(
    request: DeepPartial<GetChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Observable<ChargingSessionStatus> {
    return this.rpc.invoke(
      ChargingSessionsServiceGetChargingSessionStateDesc,
      GetChargingSessionRequest.fromPartial(request),
      metadata,
    );
  }

  StopChargingSession(
    request: DeepPartial<StopChargingSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<EvResponseChargingSession> {
    return this.rpc.unary(
      ChargingSessionsServiceStopChargingSessionDesc,
      StopChargingSessionRequest.fromPartial(request),
      metadata,
    );
  }
}

export const ChargingSessionsServiceDesc = {
  serviceName: "EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionsService",
};

export const ChargingSessionsServiceCreateChargingSessionDesc: UnaryMethodDefinitionish = {
  methodName: "CreateChargingSession",
  service: ChargingSessionsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateChargingSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseChargingSession.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargingSessionsServiceGetChargingSessionDesc: UnaryMethodDefinitionish = {
  methodName: "GetChargingSession",
  service: ChargingSessionsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetChargingSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseChargingSession.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargingSessionsServiceGetChargingSessionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetChargingSessions",
  service: ChargingSessionsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetChargingSessionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseIListChargingSession.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargingSessionsServiceGetChargingSessionStateDesc: UnaryMethodDefinitionish = {
  methodName: "GetChargingSessionState",
  service: ChargingSessionsServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return GetChargingSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ChargingSessionStatus.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ChargingSessionsServiceStopChargingSessionDesc: UnaryMethodDefinitionish = {
  methodName: "StopChargingSession",
  service: ChargingSessionsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StopChargingSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...EvResponseChargingSession.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes || [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = (() => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      });
      upStream();
    }).pipe(share());
  }
}

type ProtoMetaMessageOptions = {
  options?: { [key: string]: any };
  fields?: { [key: string]: { [key: string]: any } };
  oneof?: { [key: string]: { [key: string]: any } };
  nested?: { [key: string]: ProtoMetaMessageOptions };
};

export interface ProtoMetadata {
  fileDescriptor: FileDescriptorProto;
  references: { [key: string]: any };
  dependencies?: ProtoMetadata[];
  options?: {
    options?: { [key: string]: any };
    services?: {
      [key: string]: { options?: { [key: string]: any }; methods?: { [key: string]: { [key: string]: any } } };
    };
    messages?: { [key: string]: ProtoMetaMessageOptions };
    enums?: { [key: string]: { options?: { [key: string]: any }; values?: { [key: string]: { [key: string]: any } } } };
  };
}

export const protoMetadata: ProtoMetadata = {
  fileDescriptor: FileDescriptorProto.fromPartial({
    "name": "evmng.mapi.IChargingSessionsService.proto",
    "package": "EvMng.Services.ManagementApi.Abstractions.ChargingSessions",
    "dependency": ["google/protobuf/timestamp.proto"],
    "publicDependency": [],
    "weakDependency": [],
    "messageType": [{
      "name": "ChargingSession",
      "field": [{
        "name": "Id",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Id",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Start",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Start",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "End",
        "number": 3,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "End",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Kwh",
        "number": 4,
        "label": 1,
        "type": 2,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Kwh",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ChargerId",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ChargerDisplayName",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerDisplayName",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceId",
        "number": 7,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SessionState",
        "number": 8,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SessionState",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "ChargingSessionState",
        "number": 9,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionState",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargingSessionState",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "NucleusSessionId",
        "number": 10,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "NucleusSessionId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "LicensePlateId",
        "number": 11,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LicensePlateId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "CreatedAt",
        "number": 12,
        "label": 1,
        "type": 11,
        "typeName": ".google.protobuf.Timestamp",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "CreatedAt",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ChargingSessionStatus",
      "field": [{
        "name": "Status",
        "number": 1,
        "label": 1,
        "type": 14,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.SessionState",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Status",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "CreateChargingSessionRequest",
      "field": [{
        "name": "ChargerId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "LicensePlateId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "LicensePlateId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_ChargingSession",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 1,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSession",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "EvResponse_IList_ChargingSession",
      "field": [{
        "name": "timestamp",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "timestamp",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "data",
        "number": 2,
        "label": 3,
        "type": 11,
        "typeName": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSession",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "data",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "status_message",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "statusMessage",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "is_success",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "isSuccess",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_code",
        "number": 5,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorCode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "error_message",
        "number": 6,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "errorMessage",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GetChargingSessionRequest",
      "field": [{
        "name": "ChargingSessionId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargingSessionId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "GetChargingSessionsRequest",
      "field": [{
        "name": "ChargerId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargerId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "SpaceId",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "SpaceId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Active",
        "number": 3,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Active",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "Self",
        "number": 4,
        "label": 1,
        "type": 8,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "Self",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "StopChargingSessionRequest",
      "field": [{
        "name": "ChargingSessionId",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "ChargingSessionId",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "enumType": [{
      "name": "ChargingSessionState",
      "value": [
        { "name": "PENDING", "number": 0, "options": undefined },
        { "name": "STARTED", "number": 1, "options": undefined },
        { "name": "REQUESTED", "number": 2, "options": undefined },
        { "name": "ERROR", "number": 3, "options": undefined },
        { "name": "FINISHED", "number": 4, "options": undefined },
        { "name": "PARKING", "number": 5, "options": undefined },
        { "name": "CHARGING", "number": 6, "options": undefined },
        { "name": "STARTING", "number": 7, "options": undefined },
        { "name": "STOPPING", "number": 8, "options": undefined },
        { "name": "CDR_AVAILABLE", "number": 9, "options": undefined },
        { "name": "PAID", "number": 10, "options": undefined },
        { "name": "UNKNOWN", "number": 11, "options": undefined },
        { "name": "ABORTED", "number": 12, "options": undefined },
        { "name": "PAYMENT_CANCELLED", "number": 13, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SessionState",
      "value": [
        { "name": "UnknownSessionState", "number": 0, "options": undefined },
        { "name": "Planned", "number": 1, "options": undefined },
        { "name": "Active", "number": 2, "options": undefined },
        { "name": "Ended", "number": 3, "options": undefined },
        { "name": "Billed", "number": 4, "options": undefined },
        { "name": "CanceledSessionState", "number": 1000, "options": undefined },
        { "name": "ErrorSessionState", "number": 1001, "options": undefined },
      ],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "service": [{
      "name": "ChargingSessionsService",
      "method": [{
        "name": "CreateChargingSession",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.CreateChargingSessionRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponse_ChargingSession",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetChargingSession",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.GetChargingSessionRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponse_ChargingSession",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetChargingSessions",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.GetChargingSessionsRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponse_IList_ChargingSession",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }, {
        "name": "GetChargingSessionState",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.GetChargingSessionRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionStatus",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": true,
      }, {
        "name": "StopChargingSession",
        "inputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.StopChargingSessionRequest",
        "outputType": ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponse_ChargingSession",
        "options": undefined,
        "clientStreaming": false,
        "serverStreaming": false,
      }],
      "options": undefined,
    }],
    "extension": [],
    "options": undefined,
    "sourceCodeInfo": { "location": [] },
    "syntax": "proto3",
  }),
  references: {
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionState": ChargingSessionState,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.SessionState": SessionState,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSession": ChargingSession,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionStatus": ChargingSessionStatus,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.CreateChargingSessionRequest":
      CreateChargingSessionRequest,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponseChargingSession": EvResponseChargingSession,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.EvResponseIListChargingSession":
      EvResponseIListChargingSession,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.GetChargingSessionRequest": GetChargingSessionRequest,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.GetChargingSessionsRequest":
      GetChargingSessionsRequest,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.StopChargingSessionRequest":
      StopChargingSessionRequest,
    ".EvMng.Services.ManagementApi.Abstractions.ChargingSessions.ChargingSessionsService":
      ChargingSessionsServiceClientImpl,
  },
  dependencies: [protoMetadata1],
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
